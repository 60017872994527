﻿.o-notifications {

    .c-banner-notification {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include breakpoint(medium) {
            flex-direction: row;
            &.u-active{
                padding: 0;
            }        
        }

        align-items: center;

        .c-banner-notification__content {
            flex: 1 1 auto;
        }

        .c-banner-notification__link {
            flex: 1 1 auto;

            @include breakpoint(medium) {
                flex: 0 0 300px;
                text-align: right;
            }
        }
    }
}
