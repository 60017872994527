﻿.o-layout-6col {

    .c-layout__column {
        @include xy-cell(12);

        @include breakpoint(large) {
            margin: 0;

            @include xy-cell(6, $gutter-type: 'padding');
            @include xy-cell-offset(3);            
        }
    }
}
