.o-2-column-carousel{

    .c-carousel-slides__slide {
        background-color: $color-light-grey;
        // Styling for copy based slides.
        .c-carousel-slides__content--copy {
            padding: $sizing-small;
            order: 2;

            @include breakpoint(medium) {
                padding: $sizing-regular;
                order: 0;
            }
        }
        // Using a JS snippet to provide images embedded into slide as a background image to deal with lack of imagefit support for IE.
        .c-carousel-slides__content--image.u-compat-object-fit {
            background-size: cover;
            background-position: center center;

            img, picture { // hide image if object fit is not supported - opacity to 0 for the link area
                opacity: 0;
            }
        }

        .c-carousel-slides__content {
            text-align: center;

            @extend %c-rich-text-block;

            h2 {
                @extend %c-heading--large;
            }

            overflow: hidden;
            position: relative;
            text-align: center;
            // JS solution negates need for object-fit here.
            img {
                width: 100%;
                height: 100%;
                max-width: 100%;
                object-fit: cover;
            }

            picture, a {
                width: 100%;
                height: 100%;
                display: block;
            }

            display: flex;
            align-items: center;
        }
    }

    ul.c-carousel-pager  {

        li {
            width: $sizing-small;
            border-bottom: 2px solid rgba($color-primary-blue, 0.5);
            margin: 0 $sizing-xxx-small;
            display: inline-block;

            &.slick-active {
                border-color: $color-primary-blue;
            }
        }

        margin-bottom: $sizing-small;

        @include breakpoint(medium) {
            margin-bottom: 0;
        }

        button {
            @include squish-text;
            height: $sizing-small;

            @include breakpoint(medium) {
                height: $sizing-small;
            }

            width: $sizing-small;
        }
    }

    ul.c-carousel-pager {
        opacity: 0;
        transition: all .75s ease;

        &.u-hover {
            opacity: 1;
            transition: all .75s ease;
        }
    }

    &.u-hover ul.c-carousel-pager  {
        opacity: 1;
        transition: all .75s ease;
    }

    .c-carousel-slides {

        button.slick-arrow {
            position: absolute;
            z-index: 2;
            top: 50%;
            height: $sizing-regular + 40px;
            width: $sizing-regular;
            display: none !important;
            margin-top: -$sizing-regular - 20px;

            @include squish-text;
            background-repeat: no-repeat;
            background-position: center center;
        }

        @include breakpoint(medium) {
            button.slick-arrow {
                display: block !important;
                opacity: 0;
                transition: all .75s ease;
            }

            &.u-hover button.slick-arrow {
                opacity: 1;
                margin-left: -$sizing-regular;
                transition: all 1.75s ease;
            }

            &.u-hover button.slick-prev {
                margin-left: -$sizing-regular;
                transition: margin-left .25s ease;
            }

            &.u-hover button.slick-next {
                margin-right: -$sizing-regular;
                transition: margin-right .25s ease;
            }
        }

        button.slick-prev {
            left: 0;
            background-image: $svg-arrow-left;
        }

        button.slick-next {
            right: 0;
            background-image: $svg-arrow-right;
        }
    }
}
