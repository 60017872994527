﻿.c-related-homes {
    @extend %u-section-separation--border;

    h2 {
        @extend %c-heading--large;
        text-align: center;
        font-weight: $text-weight-light;
        margin-bottom: 40px;

        @include breakpoint(large) {
            margin-bottom: 70px;
        }
    }

    .o-development-homes__card {
        width: 100%;
        margin: 0;
    }

    .o-development-homes__card__media {
        position: relative;
    }

    .image-label {
        position: static;
    }
}

.o-development-homes__card {
    position: relative;

    .o-development-homes__card__roundel {
        position: absolute;
        top: -10px;
        left: -10px;
        width: clamp(60px,13vw,150px);
        height: auto;

        @media screen and (min-width: 1024px) {
            top: -15px;
            left: -15px;
            width: clamp(40px, 5vw, 70px);
        }
    
        &--image {
            object-position: center;
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 100%;
        }
    }

    .o-development-homes__card__tooltip {
        display: flex;
        gap: 8px;
        max-width: calc(100% - clamp(60px,13vw,150px));
        min-width: 75%;
        width: auto;
        transform: translate3d(clamp(60px,13vw,150px), -50%, 0);
        padding: 14px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        background-color: #082d60;
        color: white;
        font-size: clamp(11px, 2vw, 14px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease-in-out;
        filter: drop-shadow(1px 1px 6px rgba(0,0,0,0.3));

        @media screen and (min-width: 1024px) {
            transform: translate3d(clamp(40px, 5vw, 70px), -50%, 0);
            max-width: 200%;
        }

        @media screen and (min-width: 1440px) {
            min-width: 80%;
        }

        * {
            background-color: #082d60 !important;
            color: white !important;
            text-align: left !important;
            margin: 0;
            font-size: inherit;
            line-height: 1.25;
        }

        &.show {
            opacity: 1;
            visibility: visible;
        }

        &:after {
            content: "";
            width: 0px;
            height: 0px;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 10px solid #082d60;
            position: absolute;
            left: -10px;
            top: calc(50% - 8px);
        }

        a {
            color: white;
            text-decoration: underline;
        }

        .icon {
            margin-top: 3px;

            svg {
                width: 18px;
                height: 19px
            }
        }
    }
}