.development-card-outer {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid #cdcdcd;
  padding-top: 24px;
  padding-bottom: 19px;

  @include breakpoint(large) {
    flex-direction: row;
  }

  &:last-child {
    border-bottom: 1px solid #cdcdcd;
  }

  .inner {
    @include breakpoint(large) {
      width: 100%;
      padding-left: 33px;
      display: flex;
      flex-direction: column;
    }
  
  }
  
  .title {
    color: #13346e;
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: bold;
  
    @include breakpoint(large) {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
  
  .location {
    color: #13346e;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 8px;
  
    @include breakpoint(large) {
      margin-bottom: 20px;
      font-size: 20px;
    }
  }
  
  .tagline {
    font-size: 14px;
    color: #525252;
    display: block;
  
    @include breakpoint(large) {
      font-size: 16px;
      margin-bottom: 0;
    }
  }
  
  .info-wrap {
    display: flex;
    padding-top: 16px;
    margin-top: auto;
    flex-direction: column;
  
    @include breakpoint(large) {
      flex-direction: row;
    }
  }
  
  .distance {
    color: #13346e;
    display: flex;
    align-items: center;
    flex: 1;
    font-size: 16px;
    margin-bottom: 20px;
  
    svg {
      margin-right: 10px;
    }
  
    @include breakpoint(large) {
      margin-bottom: 0;
    }
  }
  
  .icon {
    margin-right: 10px;
    max-width: 17px;
  }
  
  .cta {
    background-color: #0c2c64;
    color: #fff;
    padding: 10px;
    flex: 1;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 100%;
  
    @include breakpoint(large) {
      align-self: flex-start;
      margin: 15px 0;
      flex: none;
      width: auto;
    }
  
    &:focus {
      outline: none;
    }
  }
  
  .image-wrap {
    position: relative;
  }
  
  .image {
    height: 185px;
    width: 100%;
    object-fit: cover;
  
    @include breakpoint(large) {
      max-height: 400px;
      height: 100%;
    }
  
    @include breakpoint(large) {
      max-width: 500px;
      max-height: none;
    }
  }
  
  .title-link {
    text-decoration: none;
  
    &:visited {
      color: purple;
    }
  }
}
