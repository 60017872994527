﻿.c-header {
  background: $color-primary-blue;
  z-index: 10;
  position: fixed;
  left: 0;
  right: 0;
  transition: top 0.3s;
  transform: translate3d(0, 0, 0);

  &.mobile-location-search {
    display: none;

    @include breakpoint(lsmall) {
      display: block;
    }
  }

  @include breakpoint(small) {
    height: 60px;
    top: 0;
  }

  @include breakpoint(msmall) {
    height: 80px;
    top: 0;

    &.menu-open {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
    }
  }
}

.sticky-header-infomation-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50px;
    background-color: #a3c7cc;
    width: 100%;
    padding: 7px;

    .sticky-header-text-container {
        flex: 95%;
        text-align: center;
    }

    .sticky-header-infomation-bar-text {
        padding-left: 5%;
        color: #1b2e5c;
        font-size: 14px;
    }

    .hyperlink-text {
        font-weight: 700;
        text-decoration: underline;
        margin-left: 10px;
        color: #1b2e5c;
        cursor: pointer;
        font-size: 14px;
    }

    .close-info-bar {
        flex: 5%;
        font-size: 25px;
        color: #1b2e5c;
    }
}

.o-header__content-container {
    height: 100%;
    margin: 0 auto 0 3%;

    @include breakpoint(msmall) {
        margin-left: 5%;
    }

    @include breakpoint(medium down) {
        padding-right: 0;
        overflow-x: hidden;

        &.grid-container {
            margin-right: 0;
        }
    }
}

.c-header__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  @include breakpoint(xxlarge) {
    width: $global-width;
    margin: 0 auto;
  }
}

.o-header__content-wrapper {
  display: flex;
  align-items: center;
  z-index: 1;
  // position: relative;

  @include breakpoint(1350px) {
    flex: 0 0 auto !important;
  }

  @include breakpoint(small) {
    justify-content: flex-end;
    padding-left: 0;
    height: 60px;
    z-index: 2;
  }

  @include breakpoint(msmall) {
    height: 80px;
    z-index: initial;
  }

  @include breakpoint(medium) {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    //   position: static;
  }

  @include breakpoint(large) {
    justify-content: space-between;
    padding-left: 1.5%;
  }
}

.c-header__menu-button {
    z-index: 11;
    height: 100%;
    border-left: 1px solid rgba(255, 255, 255, 0.2);

    @include breakpoint(small) {
        width: 60px;
    }

    @include breakpoint(msmall) {
        width: 80px;
    }

    @include breakpoint(lsmall) {
        min-width: initial;
    }

    @include breakpoint(medium) {
        display: none;
    }

    .icon-container {
        width: 28px;
        margin: 0 auto;
    }

    .menu-text {
        text-align: center;
        color: #ffffff;
    }

    .close-text {
        visibility: hidden;
        display: none;
    }

    .line {
        height: 2px;
        width: 28px;
        margin: 7px 0;
        background-color: #ffffff;
        transform: translate3d(0, 0, 0);
        transform-origin: center center;
        position: relative;
        transition: all 0.2s;
        visibility: visible;
        opacity: 1;
    }

    &--active {

        .menu-text {
            visibility: hidden;
            
        }

        .close-text {
            visibility: visible;
            display: block;
            text-align: center;
            color: #ffffff;
        }

        .icon-container {
            width: 28px;
            margin: 0 auto;
            padding-top:10px;
        }

        .line {
            margin: 0;

            &:first-child {
                top: 2px;
                transform: rotate(45deg);
            }

            &:nth-child(2) {
                visibility: hidden;
                opacity: 0;
            }

            &:last-child {
                top: -2px;
                transform: rotate(-45deg);
            }
        }
    }

    .c-breadcrumb {
        background: $color-primary-blue;
        z-index: 10;
        position: fixed;
        left: 0;
        right: 0;
        //transition: top 0.3s;
        transform: translate3d(0, 0, 0);

        @include breakpoint(small) {
            height: 60px;
            top: 0;
        }

        @include breakpoint(msmall) {
            height: 80px;
            top: 0;
        }
    }
}
