.info-block {
  display: flex;
  flex-direction: row;
  background: #f0f0f0;
  height: 71px;
  width: 100%;
  padding: 0 14px;
  margin-bottom: 10px;

  &__icon {
    margin-right: 15px;
    margin-top: 15px;
    width: 35px;
    height: 35px;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    color: #082d60;
    margin: auto 0;

    &__title {
      font-size: 16px;
      font-weight: 600;
    }

    &__description {
      font-size: 18px;
      font-weight: 500;
    }
  }

  @include breakpoint(large) {
    height: 81px;
    width: 354px;
    margin-right: 10px;
    max-width: calc(33% - 20px);
    padding: 0 8px;

    &__icon {
      margin-right: 19px;
    }

    &__text__title {
      font-size: 16px;
      padding-bottom: 2px;
    }
  
    &__text__description {
      font-size: 20px;
    }

    &:only-child {
      max-width: 354px;
    }
  }

  @include breakpoint(xlarge) {
    &__text__description {
      font-size: 25px;
    }
  }
}