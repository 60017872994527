﻿.c-magazine {
    @extend %c-rich-text-block;
    text-align: center;
    background-color: $color-medium-grey;
    padding: {
        top: 60px;
        bottom: 60px;
    };
    
    h1 {
        @extend %c-heading--large,;
    }

    h2 {
        @extend %c-heading--large;
        font-weight: $text-weight-light;
    }

    h3 {
        @extend %c-heading--regular;
    }
    
    img {
        margin: {
            top: 30px;
            bottom: 30px;
        };
        filter: drop-shadow(0px 10px 12px rgba(0,0,0, 0.2));
    }

    .c-button {
        display: block;
    }

}