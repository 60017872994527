.social-media {
  background-color: $color-light-grey;
  padding: 60px 0;

  @include breakpoint(large) {
    padding: 80px 0;
  }

  .grid-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include breakpoint(large) {
      gap: 30px;
      max-width: 1200px !important;
    }
  }

  .social-media__title {
    color: $color-primary-blue;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .social-media__intro {
    color: $color-primary-dark-grey;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    a {
      border-bottom: 1px solid transparent;
      color: $color-primary-dark-grey;
      text-decoration: none;

      &:hover,
      &:focus,
      &:active {
        border-bottom-color: $color-primary-dark-grey;
      }
    }
  }

  .c-button {
    @extend %c-button--blue;

    font-size: 1rem;
    height: 45px;
    line-height: 45px;
    margin: 0 auto;
    display: block;
    width: 100%;
    max-width: 700px;

    &:after {
      height: 750%;
      top: -400% !important;
      transform: rotate(10deg);
    }

    @include breakpoint(medium) {
      display: none;
    }
  }
}

.social-media__grid {
  position: relative;

  .social-media__grid-item {
    position: absolute;
  }
}

.social-media__grid-item {
  position: relative;
  width: 50%;

  &.social-media__grid-item--video,
  &.social-media__grid-item--gallery {
    &::before {
      background-repeat: no-repeat;
      content: '';
      height: 20px;
      left: 10px;
      position: absolute;
      top: 10px;
      width: 20px;
      z-index: 3;
    }
  }

  &.social-media__grid-item--video {
    &::before {
      background-size: 11px 14px;
      background-image: $svg-sm-video;
    }
  }

  &.social-media__grid-item--gallery {
    &::before {
      background-size: 14px 14px;
      background-image: $svg-sm-gallery;
    }
  }

  &.social-media__grid-item--facebook {
    .sm-icon {
      background-image: $svg-sm-facebook;
    }
  }

  &.social-media__grid-item--instagram {
    .sm-icon {
      background-image: $svg-sm-instagram;
    }
  }

  img {
    display: block;
    width: 100%;
  }

  .social-media__grid-item-info {
    align-items: center;
    bottom: 0;
    display: flex;
    gap: 5px;
    left: 0;
    padding: 10px;
    position: absolute;
    width: 100%;
    z-index: 3;

    .sm-icon {
      display: block;
      flex-shrink: 0;
      height: 20px;
      width: 20px;

      &::after {
        background-repeat: no-repeat;
        background-size: 20px 20px;
        content: '';
      }
    }

    .social-media__grid-item-text {
      flex-shrink: 1;
      color: $color-white;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &::after {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    bottom: 0;
    content: '';
    height: 84px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  &:nth-child(n+5) {
    display: none;

    .loaded-mobile & {
      display: block;
    }

    @include breakpoint(medium) {
      display: block;
    }
  }
}

.social-media__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: none;

  &.open {
    display: block;
  }

  &.modal-type--facebook,
  &.modal-type--facebook-video,
  &.modal-type--instagram {
    .social-media__modal-close {
      right: -34px;
    }
  }

  &.modal-type--instagram {
    .social-media__modal-inner {
      width: 541px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  .social-media__modal-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.3);
    z-index: 999;
    cursor: pointer;
  }

  .social-media__modal-inner {
    position: absolute;
    z-index: 9999;
    max-width: calc(100% - 40px);
    max-height: calc(100% - 40px);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: initial;
  }
}

.instagram-embeds {
  display: none;
}
