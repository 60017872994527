.o-map-popup {
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5);
    display: table;
    margin-top: -14px;
    position: absolute;
    transform-origin: 50% 100%;
    transform: translate(-50%, -100%) scale(1);
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    width: 460px;
    z-index: 2;

    &--hidden {
        opacity: 0;
        transform: translate(-50%, -100%) scale(0.7);
        transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
    }

    &--loaded {
        .c-map-popup-details img {
            opacity: 1;
        }
    }

    &--available {
        .c-map-popup-details::before {
            display: none;
        }
    }

    &--not-released,
    &--not-released-yet, 
    &--not-yet-released {
        .c-map-popup-details::before {
            background: #082d60;
            color: #fff;
        }
    }

    &--coming-soon {
        .c-map-popup-details::before {
            background: $color-warm-yellow;
            color: #082d60;
        }
    }

    &--google {
        min-height: 164px;
        position: absolute;
        top: -20px;
        left: 0;
        transform: translate(-50%, -100%);

        .c-local-area-map > & {
            display: none;
        }
    }
}

.c-map-popup-tip-anchor {
    height: 0;
    position: absolute;
    width: 460px;
}

.c-map-popup-bubble-anchor {
    position: absolute;
    width: 100%;
    bottom: /* TIP_HEIGHT= */ 8px;
    left: 0;
}

.c-map-popup-close {
    @extend %cta-sheen;
    background: {
        color: #082d60;
        image: $svg-close-icon;
        position: center center;
        repeat: no-repeat;
        size: 16px 16px;
    }
    border: none;
    border-radius: 0;
    display: block;
    font-size: 0;
    height: 40px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
}

.c-map-popup-details {
    background-size: 70% auto;
    display: table-cell;
    overflow: hidden;
    transition: background 0s 0.25s;
    width: 48%;

    img {
        display: block;
        height: 100%;
        left: 50%;
        max-width: initial;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        width: 100%;
        z-index: 2;
    }

    &::before {
        content: attr(data-status);
        display: block;
        font-size: $text-size-xxx-small;
        font-weight: $text-weight-regular;
        line-height: $text-line-height-xxxx-small;
        padding: 10px;
        position: absolute;
        right: 0;
        text: {
            align: center;
            transform: capitalize;
        }
        top: 80px;
        transform-origin: 100% 100%;
        transform: rotate(45deg);
        width: 168px;
        z-index: 3;
    }
}

.c-map-popup-details,
.c-map-popup-info__description {
    position: relative;
    z-index: 2;
}

.c-map-popup-info {
    color: #082d60;
    display: table-cell;
    padding: 16px 20px;
    text-align: left;
    width: 100%;

    .c-map-popup-details + & {
        width: 52%;
    }

    h2 {
        margin: 0;
        padding: 0;
    }
}

.c-map-popup-info__title,
.c-map-popup-info__number {
    font: {
        size: $text-size-x-small;
        weight: $text-weight-regular;
    }
    line-height: $text-line-height-xx-small;
}

.c-map-popup-info__title {
    text-transform: uppercase;
}

.c-map-popup-info__number {
    opacity: 0.5;
}

.c-map-popup-info__link,
.c-map-popup-info__description {
    font-size: $text-size-xx-small;
    font-weight: $text-weight-light;
    line-height: $text-line-height-xxxx-small;
    margin: 0;
    padding: 0;
}

.c-map-popup-info__description {
    margin-top: 10px;
}

.c-map-popup-info__link {
    color: #842752;
    display: inline-block;
    margin-top: 20px;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.c-map-popup-triangle {
    height: 32px;
    left: 50%;
    margin-top: -1px; // Stop the shadow flicking in the half pixel gap while animating in.
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: 100%;
    transform: translateX(-50%) scale(0.7, 1);
    transform-origin: 50% 50%;
    width: 32px;
    z-index: 1;

    &:after {
        background: #fff;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .45);
        content: '';
        display: block;
        height: calc(100% - 4px);
        transform: translate(2px, -18px) rotate(-45deg);
        width: calc(100% - 4px);
    }
}