.development-banner {
  height: 55px;
  line-height: 55px;
  text-align: center;
  max-width: $global-width;
  z-index: 8;

  .development-banner__text {
    font-size: 16px;
    color: #FFF;
  }

  &.sold-out {
    background: #082d60;
  }

  &.coming-soon {
    background: #842752;
    display: none;

    @include breakpoint(large) {
      display: block;
      width: 67.5%;
      padding: 0px 0px 0px 0px;
      
    }

    @include breakpoint(xxlarge) {
      width: 75%;
      margin: 0px 0px 0px 0px;
    }
  }

  @include breakpoint(large) {
    margin: 0 5% -5px 5%;
  }

  @include breakpoint(xxlarge) {
    margin: auto;
    margin-top: -5px;
    width: $global-width;
  }

}

.development-nav-banner {
  height: 55px;
  line-height: 55px;
  text-align: center;
  max-width: $global-width;
  z-index: 8;
  background: #842752;

  @include breakpoint(large) {
    display: none;
  }
  
  .development-nav-banner__text {
    font-size: 16px;
    color: #FFF;
  }
}