.o-floorplans {
    color: $color-primary-blue;
    text-align: center;

    @include breakpoint(large) {
        text-align: left;
    }

    &__header {
        margin-bottom: 80px;

        h2 {
            @extend %c-heading--large;
            font-weight: $text-weight-light;
        }

        p {
            @extend %c-bodytext--regular;
            font-weight: $text-weight-light;
        }
    }

    &__divider {
        @extend %u-section-border;
        margin: 45px 0 50px;
    }

    &__floorplan {
        display: flex;
        flex-direction: column;
        padding: 50px 0;

        &:not(:nth-child(2)){
            @extend %u-section-border;
        }

        &:nth-child(2) {
            padding-top:0;
        }

        @include breakpoint(large) {
            flex-direction: row;
        }

        &__body {
            margin-top: 15px;

            @include breakpoint(large){
                margin-top: 8px;
            }

            @extend %c-rich-text-block;

            h3 {
                @extend %c-heading--large;
                font-weight: $text-weight-light;
            }

        }

        &__body-inner {
            margin-bottom: $sizing-x-small;

            h4 {
                font-weight: $text-weight-bold;
            }

            p {
                margin: 0;

                &:first-of-type {
                    font-size: $text-size-small;
                }
            }

            & + .c-button span {
                margin-top: $sizing-x-small;
            }
        }
    }
}