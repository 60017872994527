.o-article-copy-block {
  @extend %c-rich-text-block;
  @extend %c-article-block__content;


  .c-article-copy-block__info {
      @extend %c-heading--small;
      display: block;
      color: $color-blue-dust;
      font-weight: normal;
      margin: 0 0 10px 0;
      font-size: $text-size-xx-small;

      @include breakpoint(medium) {
          margin: 0 0 20px 0;
          font-size: $text-size-xx-small;
      }
  }

  .c-article-copy-block__header {
      @extend %c-heading--large;
      font-weight: $text-weight-light;
      line-height: normal;
      margin: 0 0 18px 0;

      @include breakpoint(medium) {
          line-height: $text-line-height-xxx-large;
          margin: 0 0 20px 0;
      }
  }

  .c-article-copy-block__subtitle {
      @extend %c-heading--big;
      font-weight: $text-weight-light;
      margin-top: 0px;

      @include breakpoint(medium) {
          margin-top: -3px;
      }
  }

  .c-article-copy-block__links {
      text-align: center;

      a {
          display: block;
          max-width: 200px;
          margin: 0 auto;
          margin-bottom: $sizing-x-small;
      }
  }

  header {
      margin-bottom: 20px;

      @include breakpoint(medium) {
          margin-bottom: 40px;
      }
  }


  footer {
      margin-top: 40px;

      @include breakpoint(medium) {
          margin-top: 50px;
      }
  }
}
