﻿.o-specification {
    @include clearfix;
    @extend %u-section-separation;
    @extend %u-section-separation--border;
    overflow: visible !important;

    .c-layout__column {
        @include breakpoint(large) {
            @include xy-cell(6);
            @include xy-cell-offset(3);
        }
    }
}