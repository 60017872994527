.form-layout {
  display: flex;
  flex-direction: column; 

  .development-form {
    text-align: center;
    color: #082d60;
    padding: 20px;

    .development-form__step {
      
      h2 {
        font-size: 18px;
        padding-top: 30px;
        padding-bottom: 10px;
      }

      p {
        font-size: 14px;

        &:first-of-type {
          width: 420px;
          margin: auto;
        }
      }

      &__section {
        border-bottom: 1px solid #cdcdcd;
        padding: 30px 0;

        h3 {
          font-size: 32px;
          font-weight: 600;
          padding-bottom: 10px;
        }

        &.bedroom-number p {
          padding-bottom: 12px;
        }
      }

      &__small-section {
        border-bottom: 1px solid #cdcdcd;

        &:not(:first-of-type) {
          padding: 30px 0;
        }

        p {
          padding-bottom: 10px;
        }
      }
    }

    .button-container {
      width: 100%;
      text-align: left;
      padding-top: 35px;
      display: flex;
      flex-direction: column-reverse;
    }

    .text-input-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .contact-preferences {
      text-align: left;

      div {
        padding-top: 10px;
        padding-right: 50px;
      }

      p {
        max-width: 500px;
        font-size: 16px;
      }

      .checkbox-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    .title-select--desktop {
      display: none;
    }

    .small-print {
      color: #525252;
      font-size: 14px;
      text-align: left;
      max-width: 500px;

      a {
        text-decoration: underline;
        color: #082d60;
      }
    }

    @include breakpoint(large) {
      padding: 55px 64px;

      .development-form__step {

        h2 {
          font-size: 25px;
          padding-top: 30px;
        }

        p {
          font-size: 16px;
        }

        &__section {
          padding: 60px 0;
        }
      }

      .text-input-container {
        div:nth-of-type(even) {
          padding-left: 17px;
        }

        div:last-of-type() {
          padding-left: 0;
        }
      }


      .title-select--desktop {
        display: flex;
      }

      .title-select--mobile {
        display: none;
      }

      .button-container {
        padding: 30px 20px 30px 0;
        display: flex;
        flex-direction: row;
      }

    }
  }

  @include breakpoint(large) {
    flex-direction: row;
    max-width: 90em;
    margin: auto;

    // IE11 fixes
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .development-info-block-container {
        width: 30%;

        .development-info-block__image {
          height: auto;
          min-height: 1px;
        }
      }

      .form-window {
        width: 70%;
      }
    }
  } 

}
