.grid-container {
    @extend .grid-container;
}

.tabbed-content {
    font-family: Lato;
    padding: 25px 0 60px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include breakpoint(medium) {
        padding: 25px 0 80px;
        gap: 30px;
    }

    &__title {
        color: #082d60;
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 32px;
        text-transform: uppercase;
    }

    &__tabs {
        display: flex;
        gap: 13px;
        list-style-type: none;
        margin: 0;
        padding: 15px 0;
        overflow-x: auto;

        @include breakpoint(medium) {
            gap: 30px;
        }
    }

    &__link {
        color: #082d60;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-decoration: none;
        border: none;
        padding: 0;
        background-color: transparent;
        border-bottom: 2px solid transparent;
        transition: border-bottom-color 0.2s ease;
        appearance: none;
        cursor: pointer;

        &:hover,
        &:focus {
            border-bottom: 2px solid transparent;
            border-bottom-color: #082d60;
        }

        &:focus {
            outline: 1px solid #082d60;
        }

        &.active {
            color: #842752;
            border-bottom-color: #842752;
        }
    }

    &__content {
        @extend %c-rich-text-block;

        &--content {
            opacity: 0;
            visibility: hidden;
            height: 0;

            > *:first-child {
                margin-top: 0;
            }
        }

        &--active {
            opacity: 1;
            visibility: visible;
            height: auto;
        }
    }

    button {
        &:focus {
            outline: 1px solid #082d60;
        }
    }
}
