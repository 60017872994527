﻿.c-vacancies-sidebar {
    &--collapsed {

        @include breakpoint(large) {
            top: 0;
            z-index: 9;

            .c-vacancies-sidebar__content {
                padding: 0 !important;
                background-color: transparent !important;
                height: 80px !important;
                width: 22%;

                .o-vacancies-listing-filters {
                    padding: 0 !important;
                    margin: 0 !important;

                    .o-vacancies-listing-filters__submit {
                        &.submit-top {
                            padding: 0 !important;
                            margin: 0 !important;
                            height: 80px !important;
                        }
                    }
                }
            }
        }
    }
}
