/*import partials*/
@import "partials/layout";
@import "partials/filters";
@import "partials/pop-up";

.o-site-plan {
    position: relative;
    display: flex;
    flex-direction: column;
    font-family: $primary-font;
    padding: 0;
    max-width: none;
    padding-bottom: 40px;
    z-index: 3;
    background-color: white;

    .modal-open & {
        z-index: 1000;
    }

    @include breakpoint(large) {
        flex-direction: row;
    }

    &__details-container {
        position: relative;
        padding: 60px 0 0;

        @include breakpoint(large) {
            margin-bottom: unset;
            padding: 100px 0;
        }

        @media (min-width: 1440px) {
            max-width: 600px;
        }

        .o-site-plan__header {
            @include body-fontsizes;
            color: $color-primary-blue;
            font-size: $text-size-large;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 30px;
            padding-bottom: 30px;

            @include breakpoint(large) {
                padding-bottom: 50px;
                flex-direction: row;
                gap: unset;
            }

            h2 {
                color: $color-primary-blue;
                font-size: $text-size-regular;
                line-height: $text-line-height-xxxx-small;
                font-weight: 600;

                @include breakpoint(large) {
                    color: $color-primary-blue;
                    font-size: $text-size-small;
                    line-height: 20px;
                }
            }
        }

        .o-site-plan__filter-buttons {
            color: $color-purple;
            font-size: $text-size-x-small;
            gap: 15px;
            display: flex;
            align-items: center;

            @include breakpoint(large) {
                gap: 20px;
            }

            .o-site-plan__filter-reset {
                color: $color-purple;
                font-size: $text-size-x-small;
            }

            .o-site-plan__filter-control,
            .o-site-plan__filter-reset {
                cursor: pointer;
                font-family: $primary-font;
                font-size: 1rem;
                font-weight: 600;

                &:focus {
                    outline: auto;
                }
            }

            button {
                padding: 0;
            }
        }

        .o-site-plan__filters {
            display: none;

            &.visible {
                display: flex;
            }

            @include breakpoint(large) {
                position: relative;
                text-align: center;
                width: 100%;
            }

            .c-filters-wrap {
                width: 100%;

                &:last-child {
                    margin-bottom: 20px;

                    @include breakpoint(large) {
                        margin-bottom: 50px;
                    }
                }
            }

            .o-site-plan__drop-down-filter {
                justify-content: space-between;
                width: 100%;
                padding: 0;
                margin: 0;
                display: flex;
                align-items: start;
                margin-top: 20px;
                padding-top: 15px;
                flex-direction: column;
                gap: 15px;
                border-top: 1px solid $color-mid-grey;

                @include breakpoint(large) {
                    padding-top: unset;
                    flex-direction: row;
                    align-items: center;
                    border: unset;
                }

                label {
                    color: $color-primary-blue;
                    font-size: $text-size-xx-small;
                    line-height: $text-line-height-x-small;
                    font-weight: 700;
                    min-width: 90px;
                    text-align: left;

                    @include breakpoint(large) {
                        font-size: $text-size-x-small;
                        font-weight: 400;
                    }
                }

                .o-site-plan__dropdowns {
                    display: flex;
                    max-width: 100%;
                    width: 100%;
                    gap: 20px;
                    width: 100%;

                    @include breakpoint(large) {
                        max-width: 428px;
                    }

                    .c-dropdown {
                        width: fit-content;
                        width: 100%;

                        @include breakpoint(large) {
                            max-width: 240px;
                        }

                        &::after {
                            width: 11px;
                            height: 6px;
                            background-size: cover;
                        }

                        select {
                            padding: 11px 17px 11px 11px;
                            width: 100%;
                            border: 1px solid #E8E8E8;
                            color: #082d60;
                            display: block;

                            font: {
                                size: $text-size-xx-small;
                                weight: $text-weight-light;
                            }

                            line-height: $text-line-height-xxxx-small;
                        }
                    }
                }
            }

            .o-site-plan__checkbox-filter {
                justify-content: space-between;
                width: 100%;
                padding: 0;
                margin: 0;
                display: flex;
                flex-direction: column;
                color: #082d60;

                @include breakpoint(large) {
                    flex-direction: row;
                    gap: 20px;
                }

                label {
                    color: #082d60;
                }

                .o-site-plan__house-style,
                label[for="house-style"] {
                    color: #082d60;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 24px;

                    @include breakpoint(medium down) {
                        margin-bottom: 15px;
                    }

                    @include breakpoint(large) {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        font-size: 16px;
                        white-space: nowrap;
                    }
                }

                .o-site-plan__rooms-min {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: $text-weight-bold;
                    line-height: 24px;
                    color: #082d60;
                    border-top: 1px solid $color-light-grey;
                    padding: 20px 0 15px;

                    @include breakpoint(large) {
                        font-size: $text-size-x-small;
                        border: unset;
                        padding: unset;
                    }
                }

                .o-site-plan__checkbox-wrapper {
                    position: relative;
                    width: 100%;
                    max-width: 100%;
                    border: 1px solid #E8E8E8;
                    overflow: hidden;
                    height: 50px;

                    @include breakpoint(xlarge) {
                        max-width: 428px;
                    }

                    &.open {
                        .o-site-plan__checkbox-title--icon {
                            transform: rotate(180deg);
                        }

                        .o-site-plan__checkbox-list {
                            visibility: visible;
                        }
                    }

                    .o-site-plan__checkbox-overflow {
                        overflow-y: auto;
                        height: calc(100% - 38px);
                        padding-bottom: 5px;

                        @include breakpoint(large) {
                            height: calc(100% - 50px);
                        }
                    }

                    .o-site-plan__checkbox-title {
                        text-align: left;
                        display: flex;
                        justify-content: space-between;
                        padding: 7px 11px;
                        font-size: $text-size-xx-small;
                        font-weight: $text-weight-regular;
                        line-height: 24px;
                        color: #082d60;
                        cursor: pointer;

                        @include breakpoint(large) {
                            font-size: $text-size-x-small;
                            padding: 13px 11px;
                        }

                        .o-site-plan__checkbox-title--icon {
                            transform-origin: center;
                            position: absolute;
                            width: 15px;
                            height: 9px;
                            top: 22px;
                            right: 10px;
                            background-image: $svg-down-chevron-small-icon;
                            background-repeat: no-repeat;
                            background-size: 13px 10px;
                            transition: transform 0.2s;
                        }
                    }

                    .o-site-plan__checkbox-list {
                        gap: 10px;
                        display: flex;
                        flex-direction: column;
                        padding: 4px 11px;
                        visibility: hidden;

                        input {
                            margin: 0 10px 0 0;
                        }

                        .o-site-plan__checkbox-item {
                            display: flex;
                            align-items: center;

                            label {
                                color: $color-primary-blue;
                                font-size: $text-size-x-small;
                                line-height: 24px;
                                font-weight: $text-weight-bold;
                                margin-left: 9px;

                                @include breakpoint(large) {
                                    margin-left: 0.5rem;
                                }
                            }

                            input[type='checkbox'] {
                                appearance: none;
                                background: transparent;
                                border: 2px solid $color-primary-blue;
                                width: 25px;
                                height: 25px;
                                cursor: pointer;
                                position: relative;

                                &:checked {
                                    background-color: $color-primary-blue;
                                    border: none;

                                    &:after {
                                        content: "";
                                        position: absolute;
                                        left: 9px;
                                        top: 3px;
                                        width: 7px;
                                        height: 14px;
                                        border: solid white;
                                        border-width: 0 2px 2px 0;
                                        transform: rotate(45deg);
                                    }
                                }

                                &:focus {
                                    outline: none; // Remove default focus outline
                                    box-shadow: 0 0 0 3px rgba($color-primary-blue, 0.5); // Add custom focus styling
                                }
                            }
                        }
                    }
                }
            }

            h3 {
                @extend %c-heading--regular;

                font: {
                    size: $text-size-small;
                    weight: $text-weight-regular;
                }

                text-transform: lowercase;
                line-height: $text-line-height-large;
                margin: 0 0 10px;

                @include breakpoint(xlarge) {
                    font-size: $text-size-large;
                }
            }

            p {
                line-height: 21px;
                margin: 0;
                font-size: $text-size-xx-small;

                @include breakpoint(xlarge) {
                    font-size: $text-size-x-small;
                }
            }

            .c-link {
                span {
                    padding: 0;
                    font-size: $text-size-xx-small;

                    @include breakpoint(xlarge) {
                        font-size: $text-size-x-small;
                    }
                }
            }

            .c-range {
                margin-top: 10px;

                label {
                    font: {
                        size: $text-size-xx-small;
                    }
                }

                @include breakpoint(xlarge) {
                    margin-top: 20px;

                    label {
                        font-size: $text-size-x-small;
                    }
                }
            }

            .c-dropdown {

                select {
                    font: {
                        size: $text-size-xx-small;
                    }

                    @include breakpoint(large) {
                        font-size: $text-size-x-small;
                    }
                }
            }
        }

        .o-site-plan__key {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 30px 0;
            border-top: 1px solid $color-light-grey;
            overflow-y: auto;
            color: $color-primary-dark-grey;
            line-height: $text-line-height-xx-small;

            font: {
                family: $primary-font;
                size: $text-size-xx-small;
                weight: $text-weight-regular;
            }

            @include breakpoint(large) {
                border-bottom: 1px solid $color-light-grey;
                overflow-y: unset;
                line-height: 20px;
            }

            @include breakpoint(large) {
                padding: 20px 0;
                margin-bottom: 50px;
            }

            ul {
                display: flex;
                flex-direction: row;
                gap: 20px;

                @include breakpoint(large) {
                    gap: 5px;
                }

                @include breakpoint(medium) {
                    gap: 10px;
                }

                @include breakpoint(xxlarge) {
                    gap: 20px;
                }
            }

            span {
                display: none;

                @include breakpoint(large) {
                    display: inline-block;
                }
            }
        }

        .o-site-plan__key-item {
            vertical-align: middle;
            white-space: nowrap;

            &::before {
                border-radius: 100%;
                content: '';
                display: inline-block;
                height: 8px;
                width: 8px;
                margin-right: 8px;
                position: relative;
                background-color: var(--status-color, #000000);

                @include breakpoint(large) {
                    height: 12px;
                    width: 12px;
                }
            }

            &:nth-child(3) {
                display: none;
            }
        }

        .o-site-plan__list-header {
            display: grid;
            grid-template-columns: 60px repeat(2, 1fr) 85px minmax(auto, 20px);
            width: 100%;
            color: $color-primary-blue;
            border-bottom: 1px solid $color-mid-grey;
            padding: 0 0 10px;
            column-gap: 10px;
            font-size: $text-size-xx-small;
            line-height: 24px;
            font-weight: $text-weight-bold;

            @include breakpoint(large) {
                grid-template-columns: 60px repeat(2, 1fr) 85px minmax(auto, 80px) minmax(auto, 20px);
                font-size: $text-size-x-small;
            }

            @include breakpoint(1440px) {
                grid-template-columns: 60px repeat(2, 1fr) 85px minmax(auto, 90px) minmax(auto, 20px);
            }

            .o-site-plan__header-item {
                font-weight: 700;

                &:nth-child(2) {
                    display: none;

                    @include breakpoint(large) {
                        display: inline-block;
                    }
                }
            }
        }

        .o-site-plan__list-container {
            display: grid;
            width: 100%;
            max-height: 347px;
            position: relative;

            font: {
                family: $primary-font;
                size: $text-size-xx-small;
                weight: $text-weight-regular;
            }

            line-height: $text-line-height-x-small;
            overflow-y: auto;

            @include breakpoint(large) {
                max-height: 334px;
                max-height: 400px;
                width: calc(100% + 20px);

                font: {
                    family: $primary-font;
                    size: $text-size-x-small;
                    weight: $text-weight-regular;
                }

                line-height: $text-line-height-x-small;
                margin-bottom: 30px;
            }

            &--info {
                font-family: $primary-font;
                font-size: $text-size-x-small;
                font-weight: $text-weight-regular;
                color: $color-primary-dark-grey;
                padding: 20px 0;
                border-bottom: 1px solid $color-light-grey;
                display: inline-block;

                .dot {
                    height: 10px;
                    width: 10px;
                    margin: 0 5px;
                    background-color: rgba(0, 0, 0, 0.342);
                    border-radius: 50%;
                    display: inline-block;
                    opacity: 0.2;
                    animation: fade 1.4s infinite ease-in-out both;

                    &:nth-child(1) {
                        animation-delay: -0.32s;
                    }

                    &:nth-child(2) {
                        animation-delay: -0.16s;
                    }
                }
            }

            .o-site-plan__list-row {
                display: grid;
                width: 100%;
                padding: 15px 0;
                column-gap: 10px;
                grid-template-columns: 60px repeat(2, 1fr) 85px minmax(auto, 20px);
                border-bottom: 1px solid $color-light-grey;

                @include breakpoint(large) {
                    grid-template-columns: 60px repeat(2, 1fr) 85px minmax(auto, 80px) minmax(auto, 20px);
                    padding: 20px 0;
                    width: calc(100% - 20px);
                }

                @include breakpoint(1440px) {
                    grid-template-columns: 60px repeat(2, 1fr) 85px minmax(auto, 90px) minmax(auto, 20px);
                }

                // Hide House Type column data
                .o-site-plan__list-item {
                    &:nth-child(2) {
                        display: none;

                        @include breakpoint(large) {
                            display: inline-block;
                        }
                    }
                }
            }

            .o-site-plan__list-item {
                display: flex;
                font-family: $primary-font;

                &.c-link {
                    background-image: $svg-eye-icon;
                    background-repeat: no-repeat;
                    justify-content: end;

                    span {
                        display: none;
                    }
                }

                a {
                    text-decoration: none;
                    color: inherit;
                }
            }
        }

        .o-site-plan__gradient-overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100px;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #FFF 100%);
            pointer-events: none;

            @include breakpoint(large) {
                bottom: 120px;
            }
        }

        .o-site-plan__header {
            @include body-fontsizes;
            color: $color-primary-blue;
        }

        .o-site-plan__filters {

            &-bedroom,
            &-price {
                border: $border-light;
                @include map-to-sizes(padding, (xxlarge: $sizing-regular, xlarge: $sizing-small));
            }
        }
    }

    &__linear-gradient {
        position: relative;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.04) 0%, rgba(255, 255, 255, 0) 100%, rgba(0, 0, 0, 0) 100%);
        width: 100%;
        height: 40px;

        @include breakpoint(large) {
            display: none;
        }
    }

    &__map-container {
        display: flex;
        position: relative;
        flex-direction: column;
        padding: 0 0 60px;
        justify-content: center;

        @include breakpoint(large) {
            padding: 100px 0;

            &:before {
                content: "";
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.04) 0%, rgba(255, 255, 255, 0) 100%);
                background-repeat: no-repeat;
                height: 100%;
                position: absolute;
                width: 40px;
                top: 0;
            }
        }

        @include breakpoint(xxlarge) {
            margin-left: 4%;
            margin-right: calc((100vw - 90rem)/ -2);
        }

        .o-site-plan__map {
            overflow: hidden;
            position: relative;
            width: 100%;
            height: 40vh;
            display: flex;
            align-items: center;
            justify-content: center;

            @include breakpoint(large) {
                max-height: 380px;
            }

            @include breakpoint(xlarge) {
                height: 50vh;
                max-height: 420px;
            }

            @include breakpoint(xxlarge) {
                height: 60vh;
                max-height: 550px;
            }

            canvas {
                cursor: pointer;
                transform-origin: 0 0;
                position: absolute;
                top: 50%;
                left: 50%;

                &.initial {
                    width: 100%;
                }
            }

            img {
                transition: transform 0.3s ease;
                height: 100%;
            }
        }

        .o-site-plan__map--loading {
            position: absolute;

            .dot {
                height: 10px;
                width: 10px;
                margin: 0 5px;
                background-color: $color-primary-blue;
                border-radius: 50%;
                display: inline-block;
                opacity: 0.2;
                animation: fade 1.4s infinite ease-in-out both;

                &:nth-child(1) {
                    animation-delay: -0.32s;
                }

                &:nth-child(2) {
                    animation-delay: -0.16s;
                }
            }
        }

        .o-site-plan__map-controls {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 50px;
            gap: 40px;

            @include breakpoint(large) {
                gap: 20px;
                padding: 0 20px;
            }

            .o-site-plan__map-controls--zoom {
                background-image: $svg-map-zoom-in-out-navy;
                width: 100%;
                height: 88px;
                max-width: 41px;
                cursor: pointer;
                background-size: 48px 80px;
                background-repeat: no-repeat;
                position: relative;
                top: 4px;
                order: 1;

                @include breakpoint(large) {
                    max-width: 60px;
                    background-size: contain;
                    top: unset;
                }

                &--in,
                &--out {
                    display: block;
                    width: 100%;
                    height: 50%;

                    &:focus {
                        outline: 1px solid red;
                    }
                }
            }

            .c-map-cta {
                height: fit-content;
                display: flex;
                width: 100%;
                padding: 5px 20px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                cursor: pointer;

                @include breakpoint(medium) {
                    padding: 5px 40px;
                }
                @include breakpoint(large) {
                    max-width: 327px;
                }

                &:focus {
                    outline: 1px solid red;
                }
            }
        }
    }

    .c-link {
        color: $color-purple;
        font-size: $text-size-x-small;
        line-height: $text-size-x-small;
        text-decoration: none;
    }

    &__download-cta {
        background-color: $color-primary-blue;
        color: $color-white;
    }

    &__zoom-controls {
        font-size: $text-size-x-small;
    }

    &__modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;

        .o-site-plan__modal-overlay {
            background-color: rgba(0, 0, 0, 0.7);
            width: 100%;
        }

        .o-site-plan__modal-wrapper {
            position: absolute;
            max-width: 813px;
            height: 100%;
            width: 100%;
            padding: 0;
            right: 0;
            background: #fff;
            right: -100%;
            transition: right 0.6s ease-in-out;
            overflow-y: auto;
            padding-bottom: 20px;

            @include breakpoint(large) {
                padding: 40px 50px;
            }

            &.active {
                right: 0;
            }
        }

        .o-site-plan__modal-btn-close {
            position: absolute;
            top: 10px;
            right: 15px;
            cursor: pointer;
            background-image: $svg-close-small-icon-white;
            height: 20px;
            width: 20px;
            top: 13px;
            right: 7px;
            background-repeat: no-repeat;
            cursor: pointer;
            z-index: 1;
            background-size: cover;

            @include breakpoint(large) {
                height: 26px;
                width: 25px;
                top: 15px;
                right: 23px;
                background-image: $svg-close-small-icon-black;
            }

            &:focus {
                outline: 1px solid red;
            }
        }

        .o-site-plan__modal-body {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;

            .image__carousel {
                position: relative;

                .main-carousel {
                    display: flex;
                    align-items: center;

                    .c-carousel-slides {
                        overflow: hidden;
                        position: relative;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-flow: row wrap;
                        margin-bottom: 10px;

                        .slick-list {
                            opacity: 1;
                            width: 100%;
                            height: 100%;
                            position: relative;
                            display: block;
                        }

                        &__content {
                            width: 100%;
                        }

                        img {
                            max-width: 100%;
                            max-height: 392px;
                            object-fit: cover;
                        }
                    }

                    .c-carousel-next,
                    .c-carousel-prev {
                        position: absolute;

                        &:focus {
                            outline: 1px solid red;
                        }
                    }

                    .c-carousel-next {
                        @extend %carousel-button--next;
                        display: flex !important;
                        border: 0;
                        right: 0;
                        background-image: $svg-chevron-right-white;

                        &:hover {
                            background-image: $svg-chevron-right-white;
                        }

                        &::before {
                            display: none;
                        }
                    }

                    .c-carousel-prev {
                        @extend %carousel-button--prev;
                        display: flex !important;
                        border: 0;
                        left: 0;
                        background-image: $svg-chevron-left-white;

                        &:hover {
                            background-image: $svg-chevron-left-white;
                        }

                        &::before {
                            display: none;
                        }
                    }

                    .c-carousel-next,
                    .c-carousel-prev {
                        height: 25px;
                        width: 25px;
                        background-size: 6px;
                        cursor: pointer;
                        z-index: 1;

                        @include breakpoint(large) {
                            height: 40px;
                            width: 40px;
                            background-size: 10px;
                        }

                        &.slick-disabled {
                            background-color: $color-primary-blue;
                            opacity: 0.5;
                            cursor: default;
                        }
                    }

                    .c-carousel-next {
                        background-position: 10px;

                        @include breakpoint(large) {
                            background-position: center;
                        }
                    }

                    .c-carousel-prev {
                        background-position: 7px;

                        @include breakpoint(large) {
                            background-position: center;
                        }
                    }

                    ul.c-carousel-pager {
                        position: absolute;
                        bottom: 8px;
                        display: block !important;
                        width: 100%;
                        text-align: center;
                        padding: 0;
                        z-index: 2;

                        @include breakpoint(msmall) {
                            bottom: 10px;
                        }

                        @include breakpoint(large) {
                            bottom: 20px;
                        }

                        &.u-hover {
                            opacity: 1;
                            transition: none;
                        }

                        li {
                            width: 20px;
                            border-bottom: 2px solid rgba(255, 255, 255, 0.5);
                            margin: 0 $sizing-xxx-small;
                            display: inline-block;

                            button {
                                height: 10px;

                                @include breakpoint(lsmall) {
                                    height: 30px;
                                }
                            }

                            &.slick-active {
                                border-color: $white;
                            }
                        }

                        button {
                            appearance: none;
                            background-color: transparent;
                            border: none;
                            cursor: pointer;
                            @include squish-text;
                            height: $sizing-small;
                            width: $sizing-small;

                            @include breakpoint(large) {
                                height: $sizing-small;
                            }

                            &:focus {
                                outline: 1px solid red;
                            }
                        }
                    }
                }

                .thumbnail-carousel {
                    padding: 0 0 0 14px;

                    .slick-arrow {
                        position: absolute;
                        top: 50%;
                        z-index: 5;
                        transform: translateY(-50%);
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: 10px 15px;
                        background-color: #082d60;
                        width: 25px;
                        height: 28px;
                        text-indent: -9999px;
                        overflow: hidden;
                    }

                    .slick-next {
                        right: 0;
                    }

                    .slick-prev {
                        left: 0;
                    }

                    .slick-prev {
                        display: flex !important;
                        border: 0;
                        left: 0;
                        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoLTE4MCA2LjUgMTEpIiBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxyZWN0IHRyYW5zZm9ybT0icm90YXRlKDQ1IDYuNSA3KSIgeD0iLTEiIHk9IjYiIHdpZHRoPSIxNSIgaGVpZ2h0PSIyIiByeD0iMSIvPjxyZWN0IHRyYW5zZm9ybT0icm90YXRlKC00NSA2LjUgMTYpIiB4PSItMSIgeT0iMTUiIHdpZHRoPSIxNSIgaGVpZ2h0PSIyIiByeD0iMSIvPjwvZz48L3N2Zz4=);
                    }

                    .slick-next {
                        display: flex !important;
                        border: 0;
                        right: 0;
                        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAtMSkiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHJlY3QgdHJhbnNmb3JtPSJyb3RhdGUoNDUgNi41IDcpIiB4PSItMSIgeT0iNiIgd2lkdGg9IjE1IiBoZWlnaHQ9IjIiIHJ4PSIxIi8+PHJlY3QgdHJhbnNmb3JtPSJyb3RhdGUoLTQ1IDYuNSAxNikiIHg9Ii0xIiB5PSIxNSIgd2lkdGg9IjE1IiBoZWlnaHQ9IjIiIHJ4PSIxIi8+PC9nPjwvc3ZnPg==);
                    }

                    @include breakpoint(large) {
                        padding: unset;
                    }

                    .slick-list {
                        padding: 0 !important;

                        .slick-track {
                            display: flex;
                            gap: 10px;
                            margin: 0;

                            &::before {
                                display: none;
                            }

                            .slick-slide {
                                max-width: 115px;
                                max-height: 65px;
                                cursor: pointer;
                            }

                            img {
                                width: 115px;
                                height: 65px;
                                width: 100%;
                                height: auto;
                                margin: 0 auto;
                            }
                        }
                    }
                }
            }

            .o-site-plan__property-info {
                display: grid;
                width: 100%;
                padding: 30px 14px;

                @include breakpoint(large) {
                    padding: unset;
                    margin-top: 30px;
                    padding: 20px 14px;
                }

                .o-site-plan__modal-property-info {
                    display: grid;
                    grid-template-columns: 1fr;
                    gap: 20px;

                    @include breakpoint(large) {
                        grid-template-columns: 1fr 1fr;
                        gap: 20px;
                    }

                    .o-site-plan__modal-property-title-address {
                        flex-direction: column;

                        .o-site-plan__modal-address-wrapper {
                            color: $color-primary-dark-grey;
                            font-size: $text-size-base;
                            line-height: 26px;
                            margin-bottom: 10px;

                            .o-site-plan__modal-address {
                                color: $color-primary-dark-grey;
                                line-height: 24px;
                            }

                            .o-site-plan__modal-development-price {
                                color: $color-primary-blue;
                                font-size: 26px;
                                font-weight: 500;
                                line-height: 32px;
                                margin-top: 10px;

                                @include breakpoint(large) {
                                    display: none;
                                }
                            }
                        }

                        .o-site-plan__modal-development-name {
                            color: $color-primary-blue;
                            font-size: $text-size-regular;
                            line-height: 26px;
                            font-weight: 600;
                            margin-bottom: 10px;
                            text-transform: uppercase;

                            @include breakpoint(large) {
                                line-height: 24px;
                            }
                        }

                        .o-site-plan__modal-development-address {
                            color: $color-primary-dark-grey;
                            font-size: $text-size-small;
                            line-height: $text-line-height-x-small;
                            font-weight: $text-weight-regular;
                        }

                        .o-site-plan__modal-plot-description {
                            color: $color-primary-dark-grey;
                            font-size: $text-size-xx-small;
                            line-height: $text-size-regular; // 20px
                            font-weight: $text-weight-regular; // 400

                            @include breakpoint(large) {
                                line-height: 24px;
                            }
                        }
                    }
                }

                .o-site-plan__price-cta {
                    display: inline-flex;
                    gap: 10px;
                    display: flex;
                    flex-direction: column;
                    text-align: left;

                    @include breakpoint(large) {
                        text-align: right;
                        justify-content: space-between;
                    }

                    .c-button {
                        height: fit-content;

                        &:focus {
                            outline: 1px solid red;
                        }
                    }

                    .o-site-plan__modal-development-price {
                        display: none;
                        color: $color-primary-blue;
                        font-size: $text-size-xxxx-large;
                        line-height: $text-line-height-xxxx-large;
                        font-weight: 500;

                        @include breakpoint(large) {
                            display: inline-block;
                        }
                    }

                    .o-site-plan__modal-ctas {
                        display: flex;
                        gap: 10px;
                        flex-direction: column;

                        @include breakpoint(medium) {
                            flex-direction: row;
                        }

                        .site-plan-button {
                            width: 100%;
                            line-height: 45px;
                            color: $color-primary-blue;
                            background-color: transparent;
                            text-align: center;
                            min-width: 200px;
                            border-color: $color-primary-blue;
                            border-style: solid;
                            border-width: 2px;
                            transition: color 0.2s, background-color 0.2s, border-color 0.2s;
                            text-decoration: none;
                            font-weight: 400;

                            &:hover,
                            &:focus {
                                color: $color-white;
                                background-color: $color-primary-blue;
                            }

                            &:focus {
                                outline: 1px solid red;
                            }

                            &.solid {
                                color: $color-white;
                                background-color: $color-primary-blue;
                                border-color: transparent;

                                &:hover,
                                &:focus {
                                    color: $color-primary-blue;
                                    background-color: transparent;
                                    font-family: $primary-font;
                                    border-color: $color-primary-blue;
                                }
                            }

                            @include breakpoint(large) {
                                min-height: 55px;
                            }

                            span {
                                min-height: 55px;
                                display: flex !important;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }
                }
            }
        }

        img {
            width: 100%;
            height: auto;
            max-width: 100%;
            object-fit: cover;
            display: inline-block;
            vertical-align: bottom;
        }

        &.hidden {
            display: none;
        }
    }

    &__new-features {
        background-color: $color-dim-grey;
    }
}

.o-site-plan__list,
.o-site-plan__header,
.o-site-plan__filters,
.o-site-plan__key {
    @include breakpoint(large) {
        min-width: 450px;
        width: 100%;
    }
}

.o-site-plan__no-results {
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
    p {
       color: $color-dim-grey;
       font-size: 14px;
    }
    p:first-child {
      color: $color-primary-blue;
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 18px;
    }

    .c-map-cta {
      padding: 10px 40px;
      width: auto;
      margin-top: 28px;
    }
  }
}

.o-site-plan__no-results-hidden {
  display: none;
}

.o-site-plan__cta-hidden {
    visibility: visible;
  @media screen and (min-width: 1024px) {
    visibility: hidden;
  }
}

.o-site-plan__map-controls--zoom__no-results {
    position: absolute;
    right: 0;
    bottom: 240px;
}

.plots-site-plan {
    .o-site-plan__map-container {
        margin-right: 0;
        overflow: hidden;

        @include breakpoint(large) {
            padding: 0;
        }

        &:before {
            display: none;
        }

        .o-site-plan__map {
            overflow: visible;

            canvas {
                cursor: pointer;

                &.initial {
                    width: 100%;
                }
            }
        }

        .o-site-plan__map-controls {
            position: absolute;
            bottom: 10%;
            justify-content: flex-end;
            width: 100%;
        }
    }
}

#marmoset-siteplan {
    outline: 0;
    overflow: auto;
    width: 100%;
    height: 100%;
    min-height: 300px;
    border: none;
    flex: 1;

    @include breakpoint(lsmall) {
        min-height: 400px;
    }

    @include breakpoint(medium) {
        min-height: 600px;
    }
}

@keyframes fade {

    0%,
    39%,
    100% {
        opacity: 0.2;
    }

    40% {
        opacity: 1;
    }
}