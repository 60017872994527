.o-vacancies-listing {
    position: relative;
    @include clearfix;
    @extend %u-section-separation--border;

    .o-vacancies-listing__cards {
        position: relative;
        margin-top: 35px;
        padding: 20px 0 0;
    }

    .c-layout__column {
        width: 100%;

        @include breakpoint(large) {
            @include xy-cell(9, $gutter-type: none);
        }
    }

    .o-vacancies-listing__content.no-results {
        display: none;
    }

    &__card {
        width: 100%;

        @include breakpoint(large) {
            @include xy-cell(4);
        }
    }

    &--hidden {
        .o-vacancies-listing__content {
            display: none;
        }

        .o-vacancies-listing__content.no-results {
            display: block;

            .o-vacancies-listing__red-copy {
                color: $color-red;
                margin-bottom: 50px;
            }

            .o-vacancies-listing__header strong {
                font-weight: 400;
            }

            .o-vacancies-listing__sub-header {
                margin-bottom: 30px;
            }

            .c-button span {
                @extend %c-button--blue;
                width: 250px;

                @include breakpoint(medium) {
                    width: auto;
                }
            }
        }
    }

    .o-vacancies-listing__loader {
        z-index: 1;
        text-align: center;
        width: 100%;
        height: 100%;
        background: white;
        position: relative;
        padding: 10%;

        &--hidden {
            display: none;

            .o-vacancies-listing__loader-icon {
                animation: none;
            }
        }

        &-icon {
            animation: spin 1s linear infinite;
            background-image: $svg-loader;
            background-repeat: no-repeat;
            background-position: center center;
            height: 70px;
            width: 70px;
            display: inline-block
        }

        &-text {
            margin-top: 10px;
            color: $color-primary-blue;
        }
    }

    .o-vacancies-listing__show-more {
        display: none;
        margin: auto;
        @extend %c-button;
        width: 202px;
        padding: {
            left: 0;
            right: 0;
        }
        
        &--visible {
            display: block;
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        50% {
            transform: rotate(180deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}
