﻿.u-experience-editor {
    .o-footer {

        .o-footer__upper-container {

            > .scEnabledChrome.scEmptyPlaceholder {
                @extend .c-logo;
                min-width: 300px;
                max-width: 400px;
                max-height: 70px;          
            }

            .c-promo-block {
                > .scEnabledChrome.scEmptyPlaceholder {              
                    min-width: 300px;                    
                    max-height: 70px;
                    float: right;
                }
    
            }

        }

        .c-footer-menu {

            > .scEnabledChrome.scEmptyPlaceholder {
                @extend .c-footer-menu__item;
                min-height: 200px;
            }
        }

        .c-footer-logo-links{
            > .scEnabledChrome.scEmptyPlaceholder {   
                min-height: 120px;
                width: 90%;
                position: absolute;
                bottom: 0;
            }
        }

        > .scEnabledChrome.scEmptyPlaceholder {            
           @extend .c-footer__lower;
           height: 80px;
        }
    }
}
