.o-hero-carousel,
.o-static-hero {
    position: relative;
    overflow: visible;

    &.grid-container {
        width: 100vw;
        left: calc(-50vw + 50%);
        position: relative;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .o-carousel-container,
    .o-static-hero-container {
        &.thin {
            height:322px;
            @include breakpoint(medium){
                &.thin {
                    height:205px;
                }
            }
        }

        // Black semi-transparent overlay
        &::before {
            position: absolute;
            z-index: 1;
            display: block;
            content: "";
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            pointer-events: none;
            background: rgba(0, 0, 0, 0.1);
            /*adjust brightness here */
        }

        img {
          width: 100%;
        }
    }

    @extend %u-section-separation;

    .image-link {
        display: block;
    }
}

.o-thin-hero-image {
    object-fit: cover;
    height: 322px;

    @include breakpoint(medium){
        height:205px;
    }
}