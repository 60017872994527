﻿.o-additional-information {
    text-align: center;
    color: $color-primary-blue; 

    @include breakpoint(large) {
        text-align: left;
    }

    &__header {

        h2 {
            @extend %c-heading--large;
            font-weight: $text-weight-light;
        }
    }
}
