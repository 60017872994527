.plots-site-plan {
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 16px;
    margin: 0 !important;

    @include breakpoint(medium) {
        grid-template-columns: minmax(0, 41%) minmax(0, 59%);
        grid-gap: 16px;
    }

    #HOUSES {
        path {
            fill: #d1d3d4;
        }
        .plot--available,
        .plot--available path {
            fill: #842752!important;
        }
    }

    &__left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;

        @include breakpoint(medium) {
            max-width: 491px;
        }

        .plots-site-plan__title {
            display: flex;
            flex-direction: column;
            gap: 10px;

            h3 {
                color: $color-primary-blue;
                font-family: $primary-font;
                font-size: $text-size-regular;
                font-weight: 600;
                margin: 0;
            }
        }

        p {
            font-family: $primary-font;
            margin: 0;
            padding-right: 16px;
            text-wrap: pretty;
        }

        .c-button {
            display: none !important;

            @include breakpoint(medium) {
                display: inline-block !important;
            }
        }
    }

    &__right {
        display: flex;
        flex-direction: column;
        gap: 30px;

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }

        .c-button {
            @include breakpoint(medium) {
                display: none !important;
            }
        }
    }

    .c-button {
        color: $color-primary-blue;
        background-color: transparent;
        font-family: $primary-font;
        font-size: $text-size-x-small;
        line-height: $text-line-height-xxxx-large;
        font-style: normal;
        padding: 2px 20px;
        display: inline-block;
        text-align: center;
        text-decoration: none;
        border-color: $color-primary-blue;
        border-style: solid;
        border-width: 2px;
        transition: color 0.2s, background-color 0.2s, border-color 0.2s;

        @include breakpoint(medium) {
            padding: 5px 35px;
        }

        &:hover,
        &:focus {
            color: $color-white;
            background-color: $color-primary-blue;
        }

        &.solid {
            color: $color-white;
            background-color: $color-primary-blue;
            border-color: transparent;

            &:hover,
            &:focus {
                color: $color-primary-blue;
                background-color: transparent;
                font-family: $primary-font;
                border-color: $color-primary-blue;
            }
        }
    }
}