﻿@import 'vars';

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

@mixin grid-container-custom {
  @extend .grid-container;
  margin-left: 3%;
  margin-right: 3%;

  @include breakpoint(msmall) {
    margin-left: 5%;
    margin-right: 5%;
  }

  @include breakpoint(medium) {
    margin-left: 5% !important;
    margin-right: 5% !important;
    max-width: none !important;
  }

  @include breakpoint(xxlarge) {
    margin-left: auto !important;
    margin-right: auto !important;
    width: $global-width;
  }
}

@mixin map-to-sizes($property, $map) {
  @include breakpoint(xxlarge only) {
    #{$property}: map-get($map, xxlarge);
  }

  @include breakpoint(xlarge only) {
    #{$property}: map-get($map, xlarge);
  }

  @include breakpoint(large only) {
    #{$property}: map-get($map, large);
  }

  @include breakpoint(medium only) {
    #{$property}: map-get($map, medium);
  }

  @include breakpoint(small only) {
    #{$property}: map-get($map, small);
  }
}

@mixin body-fontsizes {
  @include map-to-sizes(
    font-size,
    (
      xxlarge: $text-size-x-small !important,
      xlarge: $text-size-x-small !important,
      large: rem(15px) !important,
      medium: rem(15px) !important,
      small: $text-size-xx-small !important,
    )
  );
}

@mixin subnav-container {
  @include breakpoint(small) {
    position: static;
    background-color: $color-medium-grey;
  }

  @include breakpoint(msmall) {
    top: 80px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: left;
  }

  @include breakpoint(medium) {
    padding: 30px 0px;
    bottom: auto;
  }
}

@mixin search-icon {
  background-image: $svg-icon-search;
  background-repeat: no-repeat;
}

@mixin mobile-menuitem-arrow {
  background-image: $svg-icon-mobile-menu-arrow;
  background-position: right 3% center;
  background-repeat: no-repeat;

  @include breakpoint(msmall) {
    background-position: right 5% center;
  }
}

@mixin mobile-search-link {
}

@mixin search-link {
  color: $color-primary-blue;
  text-decoration: underline;
  flex-grow: 1;
  background-repeat: no-repeat;
  line-height: 35px;
  font-weight: 300;
  text-align: left;

  &:hover {
    color: $color-purple;
  }

  @include breakpoint(small) {
    height: 60px;
    background-position:
      left 3% center,
      right 3% center;
    padding: 0 calc(3% + 25px) 0 calc(3% + 35px);
    font-size: $text-size-xx-small !important;
    border-bottom: $mobile-menuitem-border;
    position: relative;
    width: 100%;

    span {
      position: absolute;
      top: 50%;
      left: calc(3% + 35px);
      right: calc(3% + 25px);
      transform: translateY(-50%);
    }
  }

  @include breakpoint(msmall) {
    height: 80px;
    font-size: $text-size-x-small !important;
    padding: 0 calc(5% + 25px) 0 calc(5% + 35px);
    background-position:
      left 5% center,
      right 5% center;

    span {
      left: calc(5% + 35px);
      right: calc(5% + 25px);
    }
  }

  @include breakpoint(500px) {
    font-size: 18px !important;
  }

  @include breakpoint(medium) {
    position: static;
    font-size: 17px !important;
    height: auto;
    border-bottom: none;
    width: auto;
    background-position: left center;
    height: 35px;
    padding-right: 0;

    span {
      position: static;
      transform: none;
    }
  }

  @include breakpoint(xlarge) {
    font-size: 18px !important;
  }
}

@mixin squish-text {
  font: 0/0 serif;
  text-shadow: none;
  color: transparent;
}

// Aspect Ratio
@mixin aspect-ratio($w, $h) {
  $slope: calc($h / $w);
  $height: $h - $w * $slope;
  padding-top: $slope * 100%;
  height: $height;
}

@mixin target-ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}