﻿.u-experience-editor {
    .c-vacancies-sidebar {
        position: absolute;
        z-index: 10;
        top: 417px;
        transform: none !important;
        opacity: 1;

        .c-vacancies-sidebar__content {
            display: block;
            padding: 100px 20px !important;
        }
    }
}
