﻿.c-locations-list {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 0 5%;

  .gm-style div[aria-label] > div > div {
    top: -25px; 
  }

  @media (min-width: medium) {
    gap: 50px;
  }

  &__container {
    position: relative;
    max-height: 544px;
    overflow: hidden;
  }

  &__map-information {
    background: white;
    position: absolute;
    justify-content: space-between;
    bottom: 10px;
    display: flex;
    left: 10px;
    right: 65px;
    border-radius: 4px;
    background: white;
    box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.10);
    padding: 20px;
    gap: 2px;

    .c-locations-list__title-address {
      display: flex;
      flex-direction: column;
      gap: 3px;
    }

    .c-locations-list__amenity-title {
      font-family: $primary-font;
      font-size: $text-size-x-small;
      line-height: $text-size-regular;
      font-weight: $text-weight-regular;
      color: $color-primary-blue;
    }

    .c-locations-list__amenity-address {
      font-family: $primary-font;
      font-size: $text-size-xx-small;
      line-height: $text-line-height-x-small;
      color: $color-darkest-grey;

      @include breakpoint(large) {
        font-size: $text-size-xx-small;
        line-height: $text-size-small;
      }
    }

    .c-locations-list__directions-link {
      display: flex;
    }

    .c-link {
      font-family: $primary-font;
      color: $color-purple;
      text-decoration: none;
      align-self: flex-end;
    }
  }

  &__amenity-buttons-container {
    display: flex;
    height: fit-content;
    gap: 12px;
    overflow: auto;
    margin-bottom: 20px;
    padding: 1px 0 6px;
    scrollbar-color: rgba($color-primary-blue, 0.5) #f1f1f1;
    -ms-overflow-style: none;

    @include breakpoint(large) {
      padding: 1px 0 6px;
      margin-bottom: 30px;
    }

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba($color-primary-blue, 0.5);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba($color-primary-blue, 0.75);
    }
  }

  &__map-wrapper {
    display: none;

    @include breakpoint(large) {
      display: flex;
    }

    .c-locations-list__inner,
    .c-locations-list__map {
      position: relative;
      max-height: 544px;
      aspect-ratio: 1 / 1;
      width: 100%;
      height: 100%;
  
      .gmnoprint, 
      .gm-style-cc {
        display: none;
      }
    }
  }

  .zoom-controls,
  .full-screen-controls {
    cursor: pointer;
    position: relative;
  }

  .c-local-area-copy__read-more {
    &--wrap {
      text-align: left;
      transition: max-height .4s ease-in-out;
      overflow: hidden;
      margin-bottom: 30px;
      position: relative;

      &::after {
        @include breakpoint(1024px down) {
          content: "";
          width: 100%;
          height: 95px;
          background: linear-gradient(180deg,transparent 0,rgba(255,255,255,1) 100%);
          display: block;
          position: absolute;
          bottom: -25px;
          z-index: 1;
        }
      }

      &.collapsed {
        max-height: 300px;

        @include breakpoint(large) {
          max-height: none;
        }
      }
    }
  }
  
  .c-local-area-copy--controls {
    margin: 0;
    text-align: left;
  }

  &__button {
    background: #AAC5CC33;
    border-radius: 50px;
    border: 0;
    color: #082d60;
    text-align: left;
    display: inline-flex;
    transition: background 0.3s ease;
    padding: 10px 16px;
    align-items: center;
    justify-content: center;

    &:focus {
      outline: 1px solid #082d60;
    }

    span {
      font-family: $primary-font;
      font-size: $text-size-xx-small;
      line-height: $text-line-height-xx-small;
      font-weight: normal;
      white-space: nowrap;

      @include breakpoint(large) {
        font-family: $primary-font;
        font-size: $text-size-x-small;
        line-height: $text-line-height-xx-small;
        font-weight: normal;
      }
    }

    &-icon {
      width: 25px;
      height: 25px;
      margin-right: 5px;
      background-repeat: no-repeat;
      background-size: contain;

      &.schools {
        background-image: $svg-education-blue;
      }

      &.transport {
        background-image: $svg-rail-blue;
      }

      &.shop, &.shops {
        background-image: $svg-shops-blue;
      }

      &.restaurants {
        background-image: $svg-restaurants-and-bar-blue;
      }

      &.leisure {
        background-image: $svg-leisure-blue;
      }

      &.healthcare {
        background-image: $svg-healthcare-blue;
      }

      &.local-info {
        background-image: $svg-info-blue;
      }
    }

    &.reveal {
      background: $color-primary-blue;
      color: $color-white;

      .c-locations-list__button-icon {
        &.schools {
          background-image: $svg-education-white;
        }
  
        &.transport {
          background-image: $svg-rail-white;
        }
  
        &.shop, &.shops {
          background-image: $svg-shops-white;
        }
  
        &.restaurants {
          background-image: $svg-restaurants-and-bar-white;
        }
  
        &.leisure {
          background-image: $svg-leisure-white;
        }
  
        &.healthcare {
          background-image: $svg-healthcare-white;
        }
  
        &.local-info {
          background-image: $svg-info-white;
        }
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  @include breakpoint(large) {
    .c-locations-list__button.reveal {
      margin-bottom: 0px;
    }
  }

  &__items {
    display: grid;
    gap: 20px 16px;
    grid-template-columns: 1fr;

    @media (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 30px 16px;
    }
  }

  &__amenity-tabs {
    position: relative;
    max-height: 544px;
    overflow: hidden;
  }

  &__map-wrapper-mobile {
    @include breakpoint(large) {
      display: none;
    }

    .c-locations-list__inner,
    .c-locations-list__map {
      position: relative;
      max-height: 440px;
      aspect-ratio: 1 / 1;
      width: 100%;
      height: 100%;
  
      .gmnoprint, 
      .gm-style-cc {
        display: none;
      }
    }
  }

  &__gradient {
    display: none;

    @include breakpoint(large) {
      display: block;
      width: 100%;
      height: 31px;
      position: absolute;
      bottom: 0;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 10%, #FFF 100%);
    }

  }

  &__amenity-list {
    width: 100%;
    position: absolute;
    bottom: 15px;

    @include breakpoint(large) {
      padding: 0;
      position: static;
    }

    &--inner {
      max-height: 420px;
      overflow-x: auto;
      width: 100%;
      display: flex;
      gap: 10px;
      padding: 0 15px;
      scrollbar-color: rgba($color-primary-blue, 0.5) #f1f1f1;
      -ms-overflow-style: none;

      @include breakpoint(large) {
        overflow-x: visible;
        overflow-y: auto;
        position: relative;
        flex-direction: column;
        gap: unset;
        padding: unset;
      }

      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba($color-primary-blue, 0.5);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: rgba($color-primary-blue, 0.75);
      }
    }

    .c-locations-list__amenity-info {
      display: flex;
      font-family: $primary-font;
      font-size: $text-size-xx-small;
      line-height: $text-line-height-xx-small;
      padding: 15px 5px;
      border-top: 1px solid #F0F0F0;
      cursor: pointer;
      background: white;
      min-width: 280px;
      flex-direction: column;
      border-radius: 4px;
      background: #FFF;
      box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.10);

      &:last-child {
        margin-right: 15px;

        @include breakpoint(large) {
          margin-right: unset;
        }
      }

      @include breakpoint(large) {
        flex-direction: row;
        align-items: center;
        font-size: $text-size-x-small;
        line-height: $text-line-height-xx-small;
        padding: 20px 5px;
        box-shadow: unset;
      }

      .c-locations-list__amenity-title {
        font-family: $primary-font;
        font-size: $text-size-xx-small;
        line-height: $text-line-height-x-small;
        font-weight: $text-weight-regular;
        color: $color-primary-blue;

        @include breakpoint(large) {
          font-size: $text-size-x-small;
          line-height: $text-line-height-small;
        }
      }

      .c-locations-list__amenity-address {
        font-family: $primary-font;
        font-size: $text-size-xx-small;
        line-height: $text-size-large;
        color: $color-darkest-grey;

        @include breakpoint(large) {
          font-size: $text-size-xx-small;
          line-height: $text-size-regular;
        }
      }

      .amenity-proximity {
        font-family: $primary-font;
        font-size: $text-size-xx-small;
        line-height: $text-line-height-x-small;
        color: $color-darkest-grey;

        @include breakpoint(large) {
          font-size: $text-size-xxx-small;
          line-height: $text-line-height-x-small;
        }
      }

      &.fade {
        @include breakpoint(large) {
          opacity: 0.2;
        }
      }

      &.active {
        .c-locations-list__pin-number {
          position: relative;
          background: $svg-map-pin-purple no-repeat;
          width: 25px;
          height: 30px;
          background-size: cover;
  
          @include breakpoint(large) {
            margin-left: unset;
            width: 32px;
            height: 37px;
        }

        span {
          position: relative;
          top: -2px;
        }
      }
    }

      .c-locations-list__amenity-details {
        width: 100%;

        .c-locations-list__title-address-wrapper {
          margin-left: 10px;

          @include breakpoint(large) {
            margin-left: 20px;
          }

          .c-locations-list__address-proximity {
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            @include breakpoint(large) {
              flex-direction: row;
            }
          }
        }
      }

      .c-locations-list__pin-number {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        position: relative;
        background: $svg-map-pin-navy no-repeat;
        margin-left: 10px;
        background-size: cover;
        width: 25px;
        height: 30px;
        margin-bottom: 5px;

        @include breakpoint(large) {
          margin-left: unset;
          width: 32px;
          height: 37px;
        }

        span {
          position: relative;
          top: -2px;
          font-family: $primary-font;
          font-size: $text-size-xxx-small;
          line-height: $text-size-xxx-small;
        }
      }

      .amenity-title {
        color: $color-primary-blue;
        flex-grow: 1;
        align-self: flex-start;
      }

      .amenity-address {
        color: $color-darkest-grey;
        font-size: $text-size-x-small;
        line-height: $text-line-height-xx-small;
      }

      .amenity-proximity {
        @include breakpoint(large) {
          align-self: flex-end;
        }
      }
    }
  }

  &__text {
    max-width: 1200px;

    .c-locations-list__text-content{
      font-family: $primary-font;
      font-size: $text-size-xx-small;
      line-height: $text-line-height-xx-small;
      color: $color-darkest-grey;
      font-weight: $text-weight-regular;
      transition: max-height 0.1s ease-in-out;
      overflow: hidden;
  
      @include breakpoint(large) {
        font-size: $text-size-x-small;
        line-height: $text-line-height-xx-small;
      }
    }

    .c-local-area-read-more--button {
      font-family: $primary-font;
      color: $color-purple;
      font-weight: $text-weight-regular;
      text-decoration: none;
      padding: 20px 0;
      cursor: pointer;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;

      @include breakpoint(large) {
        display: none !important;
      }

      .c-local-area-read-more-icon {
        background-image: $svg-chevron-up;
        width: 12px;
        height: 6px;
        margin: 0 0 1px 5px;
        background-repeat: no-repeat;
        display: inline-block;
      }
    }

    .expanded {
      .c-local-area-read-more--button {
        .c-local-area-read-more-icon {
          background-image: $svg-chevron-down;
        }
      }

      .c-local-area-copy__read-more--wrap {
        max-height: none;
        &::after {
          display: none;
        }
      }
    }
  }
}

.tabbed-content {
  .c-locations-list {
    margin: 0;
    .c-locations-list__amenity-buttons-container {
      padding: 1px 0;
    }

    .c-locations-list__map-wrapper-mobile {
      margin: 0;
    }

    .c-locations-list__amenity-list {
      padding: 0;
    }
  }
}

.custom-marker-label {
  font-size: 14px !important;
  font-weight: bold !important;
  color: #ea4335 !important;
  position: absolute;
  top: 50%;
  transform: translate(20px, -50%);
  width: 100px;
  text-wrap: balance;
  text-align: left;
}