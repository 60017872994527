.o-job-details-block {
  @extend %c-rich-text-block;
  @extend %c-article-block__content;

  .c-job-details-block__info {
      @extend %c-heading--small;
      display: block;
      color: $color-blue-dust;
      font-weight: normal;
      margin: 0 0 10px 0;
      font-size: $text-size-xx-small;

      @include breakpoint(medium) {
          margin: 0 0 20px 0;
          font-size: $text-size-xx-small;
      }
  }

  .c-job-details-block__intro {
      h4 {
          margin-bottom: 10px;
          font-size: $text-size-small;
      }

      h4 strong {
          font-weight: 400;
          font-size: $text-size-small;
      }
  }

  .c-job-details-block__header {
      @extend %c-heading--large;
      font-weight: $text-weight-light;
      line-height: normal;
      margin: 0 0 18px 0;

      @include breakpoint(medium) {
          line-height: $text-line-height-xxx-large;
          margin: 0 0 20px 0;
      }
  }

  .c-job-details-block__subtitle {
      @extend %c-heading--big;
      font-weight: $text-weight-light;
      margin-top: 0px;

      @include breakpoint(medium) {
          margin-top: -3px;
      }
  }

  .c-job-details-block__links {
      text-align: center;

      a {
          display: block;
          max-width: 200px;
          margin: 0 auto;
          margin-bottom: $sizing-x-small;
      }
  }

  .c-job-details-block__files {
      a, a:hover, a:visited, a p, a span {
          color: #082d60;
          text-decoration: none;

          p, span {
              color: #082d60;
              font-size: $text-size-small;
              line-height: $text-line-height-x-small;
              margin-bottom: $sizing-x-small;
              vertical-align: middle;
              display: inline-block;
              margin-top: 0;
          }

          p {
              margin-left: 10px;
          }
      }

      h3 {
          font-weight: 400;
          font-size: $text-size-small;
          line-height: $text-line-height-x-small;
          margin-bottom: 20px;
      }
  }

  header {
      margin-bottom: 20px;

      @include breakpoint(medium) {
          margin-bottom: 40px;
      }
  }

  footer {
      margin-top: 40px;

      @include breakpoint(medium) {
          margin-top: 50px;
      }
  }

  strong {
      font-weight: 400 !important;
  }
}
