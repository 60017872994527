﻿.c-home-of-the-week {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 3rem 0;
  color: #ffffff;
  background: #082d60;

  @media screen and (min-width: 1024px) {
      margin-right: calc(27.5% + 1.25rem);
  }

  @media screen and (min-width: 1440px) {
      margin-right: calc(27.5% - 1.125rem);
  }

  &__details,
  &__image {
      width: 100%;

      @media screen and (min-width: 768px) {
          width: 50%;
      }
  }

  &__image {
      img {
          display: block;
      }

      @media screen and (min-width: 768px) {
          img {
              object-fit: cover;
              object-position: center;
              height: 100%;
          }
      }
  }

  &__image {
      img {
          display: block;
      }
  }

  &__details {
      padding: 1.888rem 2.117rem 1.888rem 2.117rem;

      @media screen and (min-width: 601px) {
          padding: 2.889rem 2.117rem 2.889rem 2.117rem;
      }

      &--heading {
          text-transform: uppercase;
          font-family: "Lato";
          font-size: 26px;
          font-weight: 600;
          line-height: 30px;
          margin-bottom: 1rem;

          @media screen and (min-width: 768px) {
              font-size: 24px;
              line-height: 26px;
          }
      }

      &--plot-name {
          font-family: "Lato";
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 39px;
          letter-spacing: 0em;
      }

      &--description {
          font-family: Lato;
          font-size: 20px;
          font-weight: 400;
          line-height: 30px;
          letter-spacing: 0em;

          @media screen and (min-width: 768px) {
              font-size: 16px;
              line-height: 19px;
          }
      }

      &--offer-text {
          font-family: "Lato";
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
          letter-spacing: 0em;

          @media screen and (min-width: 768px) {
              font-size: 20px;
              line-height: 20px;
          }
      }

      &--call-to-action {
          color: #fff;
          border: 1px solid white;
          text-align: center;
          line-height: 1;
          font-size: 0.922rem;
          background-color: transparent;
          padding: 10px;
          border-radius: 4px;
          text-transform: capitalize;

          @media screen and (min-width: 768px) {
              font-size: 16px;
          }
      }
  }

  &--divider {
      height: 0px;
      border-bottom: 0.740678px solid #ffffff;
      margin-top: 1.8rem;
  }

  &--element-spacing {
      margin-bottom: 1.8rem;
  }

  a {
    color: inherit;
  }
}

.additional-section {

    section.o-layout-2col, section.o-layout-3col {
        &::before {
            margin-bottom: 0;
        }
    }

    h2 {
        color: $color-primary-blue;
        font-family: $primary-font;
        font-size: $text-size-small;
        font-weight: 600;
        margin-bottom: $sizing-small;
        margin-top: 0;
    }
}

.additional-section:has(.o-layout-2col) {
    @extend %three-quarter-content;
    @include breakpoint(xxlarge) {
        width: 69%;
    }

    h2.grid-container {
        margin-left: 5% !important;
        @include breakpoint(xxlarge) {
            margin-left: 0 !important;
        }
    }

    section.o-layout-2col {
        margin-left: 0 !important;
        margin-right: 0 !important;
        @include breakpoint(xxlarge) {
            width: 100% !important; 
            margin-right: 5% !important;
        }
    }

    .grid-container {
        margin-left: 0 !important;
        margin-right: 0 !important;

        @include breakpoint(xxlarge) {
            margin-left: 0 !important;
            margin-right: 5% !important;
            width: 100% !important; 
        }
    }
}

.additional-section:has(.o-media-block) {
    .o-media-block {
        margin: 0 0 3rem 0;
        color: #ffffff;
        background: #082d60;

         @media screen and (min-width: 1024px) {
            margin-right: calc(27.5% + 1.25rem);
        }

        @media screen and (min-width: 1440px) {
            margin-right: calc(27.5% - 1.125rem);
        }
    }
}
