﻿.c-sidebar--collapsed {
    .c-development-details {
        height: 100%;
        padding: 0;

        * {
            display: none !important;
        }
        
        .c-development-details__phone-no {
            display: none;
        }

        .primary-cta {
            margin: 0 !important;
            height: 100%;
            line-height: 80px;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

.hide-sidebar-mobile {
    .c-sidebar {
        display: none !important;

        @include breakpoint(large) {
            display: initial !important;
        }
    }
}
