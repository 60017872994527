﻿.c-plot-sticky-cta {
    color: $color-primary-blue;
    text-align: left;
    position: fixed;
    bottom: 0;
    z-index: 5;
    width: 100%;
    max-width: 100%;
    left: 0;
    right: 0;
    justify-content: center;

    @include breakpoint(large) {
        text-align: center;

        &__hidden {
            display: none;
        }
    }

    .c-plot-sticky-cta__content {
        padding-top: 12px;
        padding-bottom: 4px; // 12px - 8px
        justify-content: center;
        background-color: #ffffff;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        flex-shrink: 0;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.02), 1px -7px 15px 0px rgba(0, 0, 0, 0.02), 2px -27px 27px 0px rgba(0, 0, 0, 0.02), 5px -60px 36px 0px rgba(0, 0, 0, 0.01), 8px -107px 43px 0px rgba(0, 0, 0, 0.00), 13px -167px 47px 0px rgba(0, 0, 0, 0.00);
    
        @include breakpoint(large) {
            padding-top: 24px;
            padding-bottom: 24px;
            gap: 24px;
        }
    }

    .c-plot-sticky-cta__phone-no {
        display: block;
        font-size: $text-size-x-small;
        text-decoration: none;

        .c-phone-icon {
            background-image: $svg-phone-icon;
            background-repeat: no-repeat;
            background-position: center center;
            display: inline-block;
            vertical-align: middle;
            margin: 0 0 1px 0;
            height: 18px;
            width: 18px;
            background-size: 18px 18px;

            @include breakpoint(large) {
                margin-left: -7px;
                height: 14px;
                width: 14px;
                background-size: 14px 14px;
            }

            @include breakpoint(xlarge) {
                margin-left: -8px;
                height: 16px;
                width: 16px;
                background-size: 16px 16px;
            }

            @include breakpoint(xxlarge) {
                margin-left: -9px;
                height: 18px;
                width: 18px;
                background-size: 18px 18px;
            }
        }

        span:not(.phone-icon) {
            @extend %u-hyperlink;
        }

        @include breakpoint(large) {
            display: none;
            font-size: $text-size-xx-small;
            position: static;
            height: auto;
            line-height: normal;
        }

        @include breakpoint(xlarge) {
            font-size: $text-size-x-small;
        }

        @include breakpoint(xxlarge) {
            font-size: $text-size-small;
        }
    }

    .c-plot-sticky-cta__buttons {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 90rem;
        padding: 0px 5%;
        height: 65%;

        .c-button:not(:last-child) {
            margin-bottom: 8px;

            @include breakpoint(large) {
                margin-bottom: 0;
            }
        }

        .hide-on-mobile {
            display: none;

            @include breakpoint(lsmall) {
                display: flex;
            }
        }

        @include breakpoint(large) {
            display: flex;
            align-items: center;
            gap: 20px;
            height: auto;
        }

        @include breakpoint(xxlarge) {
            padding: 0px 0px;
        }
    }

    .c-button {
        @extend %primary-button;
        background-color: $color-primary-blue;
        text-decoration: none;
        height: 50px;
        color: #fff;
        line-height: 50px;
        padding: 0 5px;
        font-size: $text-size-x-small;
        text-align: center;
        display: flex;
        flex: 1 0 0;

        &.primary-cta {
            background-color: $color-purple !important;
        }

        &:after {
            transform: rotate(10deg);
            height: 750%;
            top: -400% !important;
        }

        &:hover:after {
            top: -400% !important;
            transition-duration: 1s, 1s, 0.25s !important;
        }

        @include breakpoint(large) {
            height: 35px;
            line-height: 35px;
            font-size: $text-size-xx-small;
        }

        @include breakpoint(xlarge) {
            height: 45px;
            line-height: 45px;
            font-size: $text-size-x-small;
        }
    }
}
