﻿.c-offers {
    font-size: 0;
    @extend %c-rich-text-block;
    @extend %u-section-separation--border;

    h2 {
        @extend %c-heading--large;
        text-align: center;
        font-weight: $text-weight-light;
        margin-bottom: 40px;

        @include breakpoint(large) {
            margin-bottom: 70px;
        }
    }

    h3 {
        @extend %c-heading--regular;
    }

    p {
        margin-bottom: 30px;
    }

    .o-layout-2col, .o-layout-3col {
        &::after, &::before {
            content: none;
        }
    }
}
