.features-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
    flex-wrap: nowrap;

    @media (min-width: 768px) {
        gap: 50px;
    }

    &__items {
        display: grid;
        gap: 20px 16px;
        grid-template-columns: 1fr;

        @media (min-width: 768px) {
            grid-template-columns: repeat(3, 1fr);
            gap: 30px 16px;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    &__icon {
        width: 30px;
        height: 30px;
        min-width: 30px;
        background-size: 100%;
        background-repeat: no-repeat;

        @media (min-width: 768px) {
            width: 50px;
            height: 50px;
            min-width: 50px;
        }

        &.transport {
            background-image: $svg-transport-blue;
        }

        &.position {
            background-image: $svg-position-blue;
        }

        &.kitchen {
            background-image: $svg-kitchen-blue;
        }

        &.fixtures {
            background-image: $svg-fixtures-blue;
        }

        &.globe {
            background-image: $svg-globe-blue;
        }

        &.energy-efficient {
            background-image: $svg-energy-efficient-blue;
        }

        &.two-year-warranty {
            background-image: $svg-two-year-customer-care-warranty-blue;
        }

        &.ten-year-warranty {
            background-image: $svg-ten-year-warranty-blue;
        }

        &.insurance {
            background-image: $svg-insurance-blue;
        }

        &.fibre-broadband {
            background-image: $svg-fibre-broadband-blue;
        }

        &.home {
            background-image: $svg-house-blue;
            background-size: 25px;
            background-position: center center;
            
            @media (min-width: 768px) {
              background-size: 40px;
            }
        }
    }

    &__label {
        align-self: center;

        h4 {
            color: $color-primary-blue;
            font-family: $primary-font;
            font-size: $text-size-x-small;
            line-height: $text-line-height-x-small;
            font-weight: 600;
            margin: 0;

            @media (min-width: 768px) {
                font-size: $text-size-small;
                line-height: $text-line-height-small;
            }
        }
    }

    &__cta {
        display: flex;

        @media (min-width: 768px) {
            display: block;
            font-size: $text-size-small;
            line-height: $text-line-height-small;
        }

        .c-button--blue {
            color: $color-white;
            font-family: $primary-font;
            font-size: $text-size-x-small;
            line-height: $text-line-height-xxxx-large;
            font-weight: 600;
            font-style: normal;
            padding: 0 40px;
            width: 100%;

            @media (min-width: 768px) {
                width: fit-content;
            }
        }
    }
}
