.c-dropdown {
    position: relative;
    text-align: left;

    select {
        appearance: none;
        background: #fff;
        border-radius: 0;
        border: none;
        color: rgba(#082d60, 0.5);
        cursor: pointer;
        display: block;
        font: {
            size: $text-size-x-small;
            weight: $text-weight-light;
        }
        line-height: $text-line-height-xx-small;
        padding: 13px 10px;
        width: 100%;
    }

    &::after {
        background: {
            image: $svg-select;
            repeat: no-repeat;
            position: center center;
        }
        content: '';
        display: block;
        height: 10px;
        pointer-events: none;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        z-index: 2;

        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
           display: none;
        }
    }
}
