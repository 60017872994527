﻿.c-sub-nav {
    border-top: 1px solid #CED5DF;
    margin-left: 0 !important;
    margin-right: 0 !important;
    background-color: #fff;
    z-index: 15;
    transition: top 0.3s;

    @include breakpoint(large) {
        height: 80px;
        border-top: none;
        border-bottom: 1px solid #CED5DF;
        margin-left: 5% !important;
        margin-right: 5% !important;
        max-width: none !important;
        z-index: 8;
    }

    @include breakpoint(xxlarge) {
        margin-left: auto !important;
        margin-right: auto !important;
        width: $global-width;
    }

    .c-sub-nav__menu-button-container {
        border-bottom: 1px solid #CED5DF;
        padding: 0 3%;

        @include breakpoint(msmall) {
            padding: 0 5%;
        }

        @include breakpoint(large) {
            display: none;
        }
    }


    .c-sub-nav__menu-button {
        height: 60px;
        width: 100%;
        font-size: $text-size-x-small;
        color: $color-primary-blue;
        text-align: left;
        font-family: $primary-font;
        padding: 0;
        line-height: 60px;

        &:after {
            content: '';
            background-image: $svg-arrow-down-blue;
            background-repeat: no-repeat;
            background-position: right center;
            margin-right: 15px;
            height: 100%;
            float: right;
            width: 18px;
            transition: transform 0.2s;
        }
    }

    .c-sub-nav__links-container {
        max-height: 0px;
        overflow: hidden;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 2;
        transition: max-height 0.3s ease-in;
        text-align: center;

        @include breakpoint(large) {
            position: static;
            max-height: none;
        }
    }

    .c-sub-nav__sub-link {
        @extend %u-hyperlink;
        color: $color-primary-blue;
        text-decoration: none;
        display: none;
        padding-left: 3%;
        padding-right: 3%;
        background-color: $color-medium-grey;
        border-bottom: $mobile-menuitem-border;
        height: 60px;
        line-height: 60px;

        @include breakpoint(msmall) {
            padding-left: 5%;
            padding-right: 5%;
        }

        @include breakpoint(large) {
            display: inline-block;
            padding: 0 10px;
            background-color: transparent;
            border: none;
            height: 80px;
            line-height: 80px;
            font-size: $text-size-xx-small;

            &:nth-of-type(2) {
                padding-left: 8px;
            }
        }

        @include breakpoint(xlarge) {
            font-size: $text-size-x-small;
            padding: 0 12px;
        }

        &--active {
            display: none !important;

            @include breakpoint(large) {
                display: inline-block !important;
                color: $color-purple;
                text-decoration: underline;
            }
        }
    }

    &--open {

        .c-sub-nav__links-container {
            max-height: calc(100vh - 60px);
            overflow: auto;
            text-align: left;
        }

        .c-sub-nav__sub-link {
            display: block;
            font-weight: $text-weight-light;
        }

        .c-sub-nav__menu-button {
            &:after {
                transform: rotate(180deg);
            }
        }
    }

    &--fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 30;
    }
}


.o-sub-nav-placeholder {
    height: 60px;
    background-color: #fff;
    border-bottom: 1px solid #fff;
    opacity: 0;

    @include breakpoint(large){
        height: 80px;
    }

    &--hidden {
        display: none;
    }

}