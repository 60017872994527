
  .text-input {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    padding: 14px 0;
    position: relative;

    @include breakpoint(large) { 
      width: 50%;
      padding: 20px 0;

      &.text-input--full-width {
        width: 100%;
      }
    }
  }

  .text-input,
  .validated-input {

    label {
      color: #082d60;
      font-size: 14px;
      padding-bottom: 7px;
    }

    input {
      height: 37px;
      line-height: 37px;
      font-size: 14px;

      // IE11 fixes
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        line-height: 9px;
      }
    }

    .form__error-msg {
      height: 0;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      bottom: 0;
      margin: 0;
      transition: opacity 0.2s ease;
    }

    .error ~ .form__error-msg {
      height: auto;
      opacity: 1;
    }

    .form__input-icon {
      top: unset;
      bottom: 13px;
    }

    .c-dropdown select {
      font-size: 14px;
      border: 1px solid #cccccc;
      padding: 0 13px;
      height: 37px;
    }

    @include breakpoint(large) { 

      label {
        font-size: 16px;
      }

      &--area {
        width: 100%;
      }
    }
  }

  .text-input--area {
    width: 100%;

    textarea {
      height: 110px;
      line-height: unset;
      border: 1px solid #cccccc;
      outline: 0;
      padding: 10px;
    }
  }

  .step-one {
    .form__error-msg {
      margin-bottom: 0;
    }
  }