﻿.c-vacancies-sidebar {
    @include xy-grid;
    background-color: $color-medium-grey;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 3%;
    padding-right: 3%;
    opacity: 1;

    @include breakpoint(msmall) {
        padding-left: 5%;
        padding-right: 5%;
    }

    @include breakpoint(large) {
        background-color: transparent;
        width: 100%;
        position: fixed;
        top: 80px;
        padding-left: 0;
        padding-right: 0;
        max-width: none !important;
        z-index: 2;
        opacity: 0;
        transition: opacity 0.3s;
    }

    .c-vacancies-sidebar__content {
        width: 100%;
        padding: 30px 0;

        &--no-padding {
            padding: 0;
        }

        @include breakpoint(large) {
            width: 25%;
            background-color: $color-medium-grey;
            right: 0;
            padding: 0 0 0 0 !important;
            position: absolute;
            overflow: auto;
            height: calc(100vh - 90px);
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            align-content: flex-start;
            overflow: hidden;
        }

        @include breakpoint(xxlarge) {
            width: $sidebar-width;
        }
    }

    &--visible {

        @include breakpoint(large) {
            opacity: 1;
            transition: opacity 0.4s ease-out, transform 0.4s ease-out;
            right: 5%;
        }

        @include breakpoint(xxlarge) {
            right: auto;
            left: auto;
            width: $global-width;
        }
    }
}
