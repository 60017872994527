﻿.o-footer {
    .c-footer__main {
        // Utility class is applied by JS.
        .o-footer-menu-main.u-mobile-menu {

            .c-footer-menu {
                ol {
                    transition: height 0.75s ease;
                    overflow: hidden;

                    li {
                        opacity: 0;
                        transition: opacity 1.25s ease;

                        a {
                        }
                    }

                    li:first-child {
                        display: block;
                        opacity: 1;
                    }
                }

                & .c-footer-menu__item.u-active {

                    ol {
                        transition: height 0.75s ease;

                        li {
                            opacity: 1;
                            transition: opacity 1.25s ease;

                            a {
                            }
                        }

                        li:first-child {
                            display: block;
                            opacity: 1;

                            a {
                                background-image: $svg-arrow-up;
                            }
                        }
                    }
                }
            }
        }
    }
}
