﻿.c-development-details {
    color: $color-primary-blue;
    text-align: left;
    position: relative;
    @extend %sidebar-item;

    @include breakpoint(large) {
        text-align: center;
    }

    .buttons {
        display: none;

        @include breakpoint(large) {
            display: block;
        }
    }

    h3 {
        font-size: $text-size-large;
        text-transform: uppercase;
        margin-bottom: 8px;
        display: block;
        font-weight: $text-weight-light;

        @include breakpoint(large) {
            margin-bottom: 5px;
            font-size: $text-size-xx-small;
            font-weight: $text-weight-regular;
        }

        @include breakpoint(xlarge) {
            margin-bottom: 7px;
            font-size: $text-size-x-small;
        }

        @include breakpoint(xxlarge) {
            margin-bottom: 15px;
            font-size: $text-size-small;
        }
    }



    .c-development-details__tagline {
        font-weight: $text-weight-light;
        margin-bottom: 20px;
        font-size: $text-size-small;
        display: block;

        @include breakpoint(large) {
            margin-bottom: 10px;
            font-size: $text-size-xx-small;
        }

        @include breakpoint(xlarge) {
            margin-bottom: 12px;
            font-size: $text-size-x-small;
        }

        @include breakpoint(xxlarge) {
            margin-bottom: 22px;
        }

        &.coming-soon {
            padding-bottom: 5px;
        }
    }

    .c-development-details__address {
        @extend .c-development-details__tagline;
        margin-bottom: 5px;
        font-size: $text-size-x-small;

        @include breakpoint(large) {
            font-size: $text-size-xx-small;
        }

        @include breakpoint(xlarge) {
            font-size: $text-size-x-small;
        }

        br {
            display: none;
            @include breakpoint(large) {
                display: block;
            }
        }
    }

    .c-development-details__price {
        font-size: $text-size-large;
        margin-bottom: 15px;
        margin-top: -5px;

        @include breakpoint(large) {
            margin-bottom: 8px;
            margin-top: 0;
            font-size: $text-size-small;
        }

        @include breakpoint(xlarge) {
            margin-bottom: 15px;
            font-size: $text-size-regular;
        }

        @include breakpoint(xxlarge) {
            margin-bottom: 20px;
            font-size: $text-size-large;
        }
        
    }

    .c-development-details__opening-times-section {
        &.coming-soon {
            padding-top: 30px;
            padding-bottom: 60px;
        }
    }

    .c-development-details__opening-times {
        font-size: $text-size-x-small;
        text-transform: uppercase;
        margin-bottom: 10px;
        display: block;

        @include breakpoint(large) {
            margin-bottom: 0;
            font-size: $text-size-xx-small;
        }

        @include breakpoint(xlarge) {
            margin-bottom: 2px;
            font-size: $text-size-x-small;
        }

        @include breakpoint(xxlarge) {
            margin-bottom: 7px;
        }
    }

    .c-development-details__opening-info {
        font-weight: $text-weight-light;
        margin-bottom: 20px;
        font-size: $text-size-x-small;
        display: block;

        @include breakpoint(large) {
            margin-bottom: 10px;
            font-size: $text-size-xx-small;
        }

        @include breakpoint(xlarge) {
            margin-bottom: 11px;
            font-size: $text-size-x-small;
        }

        @include breakpoint(xxlarge) {
            margin-bottom: 20px;
        }
    }

    .c-development-details__what3words {
        text-decoration: inherit;
        font-weight: $text-weight-light;
        margin-bottom: 18px;
        font-size: $text-size-x-small;
        display: block;
        color: $color-primary-blue;

        @include breakpoint(large) {
            margin-bottom: 10px;
            font-size: $text-size-xx-small;
        }

        @include breakpoint(xlarge) {
            margin-bottom: 12px;
            font-size: $text-size-x-small;
        }

        @include breakpoint(xxlarge) {
            margin-bottom: 15px;
        }

        &:hover
        {
            text-decoration: none;
        }

        .c-development-details__what3words-link
        {
            text-decoration: underline;
            color: inherit;
        }

        .c-development-details__what3words-help
        {
            text-decoration: none;
            color: inherit;
        }
        
        .c-development-details__what3words-info
        {
            width: 15px;
            transform: translate(2px, 2px);
        }
    }

    .c-development-details__location-details
    {
        &.coming-soon
        {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    .c-development-details__directions,
    .c-development-details__mortgage-calculator {
        @extend %u-hyperlink;
        text-decoration: underline;
        font-weight: $text-weight-light;
        margin-bottom: 18px;
        font-size: $text-size-x-small;
        display: block;

        @include breakpoint(large) {
            margin-bottom: 10px;
            font-size: $text-size-xx-small;
        }

        @include breakpoint(xlarge) {
            margin-bottom: 12px;
            font-size: $text-size-x-small;
        }

        @include breakpoint(xxlarge) {
            margin-bottom: 15px;
        }
    }

    .c-development-details__mortgage-calculator {
        margin-bottom: 28px;
        margin-top: -14px;
        @include breakpoint(large) {
            margin-bottom: 20px;
            margin-top: -10px;
            font-size: $text-size-xx-small;
        }

        @include breakpoint(xlarge) {
            margin-bottom: 22px;
            margin-top: -12px;
            font-size: $text-size-x-small;
        }

        @include breakpoint(xxlarge) {
            margin-bottom: 25px;
            margin-top: -12px;
        }
    }

    .c-development-details__phone-no {
        margin-bottom: 30px;
        display: block;
        font-size: $text-size-x-small;
        text-decoration: none;
        
        .c-phone-icon {
            background-image: $svg-phone-icon;
            background-repeat: no-repeat;
            background-position: center center;            
            display: inline-block;
            vertical-align: middle;
            margin: 0 0 1px 0;
            height: 18px;
            width: 18px;
            background-size: 18px 18px;

            @include breakpoint(large) {
                margin-left: -7px;
                height: 14px;
                width: 14px;
                background-size: 14px 14px;
            }

            @include breakpoint(xlarge) {
                margin-left: -8px;
                height: 16px;
                width: 16px;
                background-size: 16px 16px;
            }

            @include breakpoint(xxlarge) {
                margin-left: -9px;
                height: 18px;
                width: 18px;
                background-size: 18px 18px;
            }

        }

        span:not(.phone-icon) {
            @extend %u-hyperlink;
        }

        @include breakpoint(large) {
            display: block;
            margin-bottom: 16px;
            font-size: $text-size-xx-small;
            position: static;
            height: auto;
            line-height: normal;
        }

        @include breakpoint(xlarge) {
            margin-bottom: 18px;
            font-size: $text-size-x-small;
        }

        @include breakpoint(xxlarge) {
            margin-bottom: 30px;
            font-size: $text-size-small;
        }

        &.coming-soon {
            padding: 5px;
        }
    }

    .c-button {
        @extend %primary-button;
        background-color:$color-primary-blue;
        text-decoration: none;
        height: 50px;
        color: #fff;
        line-height: 50px;
        padding: 0 5px;
        font-size: $text-size-x-small;
        text-align: center;
        display: block;

        &.primary-cta {
            background-color: $color-purple;
        }

        &.coming-soon-cta {
            
        }

        &:not(:last-of-type) {
            margin-bottom: 20px;
        }

        &:after {
            transform: rotate(10deg);
            height: 750%;
            top: -400% !important;
        }

        &:hover:after {
            top: -400% !important;
            transition-duration: 1s, 1s, 0.25s !important;
        }

        @include breakpoint(large) {
            height: 35px;
            line-height: 35px;
            font-size:  $text-size-xx-small;

            &:not(:last-of-type) {
                margin-bottom: 10px;
            }
        }

        @include breakpoint(xlarge) {
            height: 45px;
            line-height: 45px;
            font-size: $text-size-x-small;

            &:not(:last-of-type) {
                margin-bottom: 12px;
            }
        }

        @include breakpoint(xxlarge) {
            &:not(:last-of-type) {
                margin-bottom: 20px;
            }
        }
    }
}
