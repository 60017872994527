﻿.o-notifications {
    background-color: $color-dark-blue;
    color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    font-size: $text-size-xxx-small;
    font-weight: $text-weight-regular;
    transform: translateY(0%);
    transition: transform 0.3s;
    transition-delay: 0.5s;

    &--hidden {
        transform: translateY(100%);
        transition: transform 0.3s;
        transition-delay: 0s;
    }

    .c-banner-notification {
        position: relative;
        text-align: center;
        visibility: hidden;
        max-height: 0;
        overflow: hidden;
        padding: 0;        

        &.u-active {
            visibility: visible;
            max-height: 500px;
            padding: 10px 0 15px 0;
        }

        @include breakpoint(medium) {
            text-align: left;
        }
        
     
        &.u-hidden {
            max-height: 0;
            opacity: 0;
        }

        .c-banner-notification__content {

            // Set a min height, in-case text isn't wrapped in a paragraph element.
            //min-height: 65px;

            margin: 10px 0 20px;

            @include breakpoint(medium){
                padding: 15px 0;
                margin: 0;
            }
           
            @extend %c-rich-text-block;

            p {
                color: white;
                font-size: $text-size-xxx-small;
                margin: 0;
                line-height: $text-line-height-xxxx-small;
            }

            a {
                color: white;
                cursor: pointer;

                &:hover {
                    color: white;
                }
            }
        }

        .c-banner-notification__link {

            @include breakpoint(medium) {
                padding: 15px 0;
            }

            a.c-button {
                span {
                    display: inline-block;
                    height: auto;
                    line-height: 45px;
                    color: white;
                    text-align: center;
                    border: 2px solid white;
                    padding: 0 20px;
                    min-width: 200px;
                    transition: background-color 0.2s ease;
                    text-decoration: none;
                    font-size: $text-size-x-small;

                    @include breakpoint(medium only) {
                        padding: 0 10px;
                    }
                }

                &:hover span {
                    color: $color-dark-blue;
                    background-color: white;
                    cursor: pointer;
                }
            }
        }
    }
}
