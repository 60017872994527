﻿.u-experience-editor {
     .c-header {
         position: relative;

         .c-nav {
            >.scEnabledChrome.scEmptyPlaceholder {
                @extend .c-header-search;
                min-width: 200px;
                background: transparent url('/sitecore/shell/themes/standard/images/pageeditor/EmptyPlaceholderBg.png') repeat;
            }
         }

         .c-nav__nav-items-container {         

             >.scEnabledChrome.scEmptyPlaceholder {
                @extend .c-nav__nav-item;
                min-width: 100px;
                background: transparent url('/sitecore/shell/themes/standard/images/pageeditor/EmptyPlaceholderBg.png') repeat;
            }
          }
          
          .c-header__content {
           > .scEnabledChrome.scEmptyPlaceholder {
                @extend .c-logo;
                min-width: 100px;
                background: transparent url('/sitecore/shell/themes/standard/images/pageeditor/EmptyPlaceholderBg.png') repeat;
            }
          }
          
      }
  }
  