﻿.u-experience-editor {
    .o-layout-3col, .o-layout-2col  {
       .c-layout__column {
            
            > .scEnabledChrome.scEmptyPlaceholder {
                min-height: 300px;
                width: 100%;
            }
       }
    }

}
