// Define the specific slide colors
$color-slide-1: #E1AFB1;
$color-slide-2: #ACC096;
$color-slide-3: #A3C7CC;
// Create an SCSS map to store the slide colors
$slide-colors: ( 1: $color-slide-1, 2: $color-slide-2, 3: $color-slide-3 );

.c-offers-carousel {
    position: relative;
    width: 100%;
    padding: 0 0 40px;

    &__heading-container {
        display: flex;
        justify-content: space-between;

        h2 {
            color: $color-primary-blue;
            font-family: $primary-font;
            font-size: $text-size-small;
            font-weight: 600;
            margin-bottom: $sizing-small;
            margin-top: 0;
        }

        .c-link {
            font-family: $primary-font;
            color: $color-purple;
            font-weight: 600;
            text-decoration: none;

            &:focus {
                outline: 1px solid red;
            }
        }
    }

    &__view-all-cta {
        position: absolute;
        top: 0;
        right: 0;
        color: $color-primary-blue;
        font-family: $primary-font;
        font-size: $text-size-xxx-small;
    }

    .grid-container {
        @extend .grid-container;
        margin-left: 5%;
        margin-right: 5%;
    }

    .c-carousel-slides {
        display: flex;
        overflow: hidden;
        align-items: stretch;
        max-width: 90rem;
        margin: 0 auto;
        margin-bottom: 30px !important;

        @include breakpoint(medium) {
            overflow: visible;
        }

        &.grid-container {
            @extend .grid-container;
            width: unset;
            margin-left: 5% !important;

            @include breakpoint(msmall) {
                margin-right: 0;
                max-width: none !important;
            }

            @include breakpoint(xxlarge) {
                margin-left: calc((100% - 90rem) / 2) !important;
            }

            @include breakpoint(medium) {
                margin-right: 0 !important;
            }

            @include breakpoint(xxlarge) {
                margin-right: 0 !important;
            }
        }
        // Loop through the slides and set the background color
        @for $i from 1 through 10 {
            $color-index: $i % 3;
            $color-index: if($color-index == 0, 3, $color-index); // Convert 0 to 3
            $bg-color: map-get($slide-colors, $color-index);

            .slick-slide:nth-child(#{$i}) .c-offers-carousel__item {
                background-color: $bg-color;
            }
        }

        .slick-slide {
            margin-right: 16px;
            height: inherit;

            > div:first-child {
                height: 100%;
            }
        }

        .slick-track {
            display: flex;
        }

        .c-offers-carousel__item {
            display: flex !important;
            flex: 0 0 auto;
            width: 491px;
            padding: 20px;
            box-sizing: border-box;
            width: 307px !important;
            height: inherit;
            justify-content: space-between;
            flex-direction: column;

            @include breakpoint(medium) {
                width: 491px !important;
                padding: 30px;
            }

            h3 {
                color: $color-primary-blue;
                font-family: $primary-font;
                font-size: $text-size-regular;
                font-weight: 600;
                margin-bottom: $sizing-xxx-small;
                text-transform: uppercase;
                line-height: 1.125rem;
                margin: 0 0 15px 0;
            }

            p {
                color: $color-primary-blue;
                font-family: $primary-font;
                font-size: $text-size-x-small;
                line-height: $text-line-height-xx-small;
                font-weight: $text-weight-regular;
                margin-bottom: $sizing-x-small;
                max-width: 431px;
                margin-bottom: 20px;

                @include breakpoint(medium) {
                    margin-bottom: 30px;
                }
            }

            .c-button--blue {
                color: $color-white;
                font-weight: 600;
                font-family: $primary-font;
                font-size: $text-size-x-small;
                line-height: $text-line-height-xxxx-large;
                font-style: normal;
                padding: 5px 50px;
                width: fit-content;
                margin: unset;

                &:focus {
                    outline: 1px solid red;
                }
            }
        }
    }

    .c-carousel__controls {
        @include breakpoint(medium) {
            display: flex;
            justify-content: flex-end;
            margin: 0 5px;
            margin-top: 20px;
        }

        .carousel-button {
            display: none !important;

            @include breakpoint(medium) {
                display: block !important;
            }

            &:focus {
                outline: 1px solid red;
            }
        }
    }

    .c-carousel__next-button {
        @extend %carousel-button--next;
        display: flex !important;
        border: 0;
        background-image: $svg-chevron-right-white;
    }

    .c-carousel__back-button {
        @extend %carousel-button--prev;
        display: flex !important;
        border: 0;
        background-image: $svg-chevron-left-white;
    }

    .c-carousel__next-button,
    .c-carousel__back-button {
        height: 40px;
        width: 40px;
        background-size: 10px;
        cursor: pointer;

        &.slick-disabled {
            background-color: #082D604D;
        }

        &:focus {
            outline: 1px solid red;
        }
    }

    .carousel-button--previous {
        @extend %carousel-button--prev;
        display: flex !important;
        border: 0;
        margin: 0 5px;

        &:focus {
            outline: 1px solid red;
        }
    }

    .c-carousel-indicators {
        position: relative;
        bottom: 25px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .slick-dots {
            display: flex;
            flex-direction: row;
            pointer-events: none;
        }

        li {
            margin-right: 10px;
            margin: 0 7px 0 0 !important;
            width: 15px !important;
            height: 2px !important;

            @include breakpoint(medium) {
                width: 25px !important;
                height: 3px !important;
            }

            button {
                font-size: 0;
                width: 15px !important;
                height: 2px !important;
                border: none;
                border-radius: 0;
                outline: none;
                cursor: pointer;
                padding: 0 !important;
                background-color: #082d603a;

                @include breakpoint(medium) {
                    width: 25px !important;
                    height: 3px !important;
                }

                &:before {
                    content: "";
                    width: 15px !important;
                    height: 2px !important;
                    font-size: 0 !important;
                    background-color: $color-primary-blue;
                    color: $color-primary-blue;

                    @include breakpoint(medium) {
                        width: 25px !important;
                        height: 3px !important;
                    }
                }
            }

            &.slick-active button {
                background-color: $color-primary-blue;
            }
        }
    }
}
