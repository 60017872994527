.c-column-layout {
    ul {
        list-style: none;
    }

    .c-column-layout__group-title {
        @extend %c-heading--medium;
        color: $color-blue-dust;
        margin-top: 30px;
        margin-bottom: 10px;        
    }

    .c-column-layout__column:first-child .c-column-layout__group-title:first-child {
        margin-top: 0;
    }

    .c-column-layout__column {


        &:last-child {
            margin-bottom: 60px;
        }

        @include breakpoint(medium) {
            &:last-child {
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
    }

    .c-column-layout__group-title:first-child {
            margin-bottom: 30px;
    }
    

    @include breakpoint(lsmall) {
        .c-column-layout__group-title:first-child {
            margin-top: 0;
        }
    }

    .c-column-layout__group-item {
        a {
            @extend %u-hyperlink;
            line-height: $text-line-height-small;
            text-decoration: none;
        }

        span {
            font-weight: $text-weight-bold;
        }
    }
}

.c-column-layout__title {
    @extend %c-heading--regular;
    margin-top: 30px;
    line-height: 1.3;
    color: $color-blue-dust;

    strong {
        color: $color-primary-blue;
    }
    
    .highlight {
        color: $color-purple;
    }
}

.o-development-homes {
    min-height: calc(100vh - 672px);

    &__results-header {
        border-bottom: 1px solid $color-blue-dust;
        margin-bottom: 0;

        @include breakpoint(medium) {
            margin-bottom: 20px;
        }

        .o-development-listings-refine__region {
            margin-top: 0;
            width: 50%;
            max-width: 200px;
            display: flex;
            align-items: center;

            @include breakpoint(medium) {
                margin-top: 20px;
                width: auto;
            }

            label {
                display: none;
            }

            .c-dropdown {
                width: 100%;
                border-right: 1px solid $color-blue-dust;
                margin-left: auto;

                @include breakpoint(medium) {
                    width: 180px;
                }

                select {
                    @extend %c-heading--regular;
                    font-family: "Lato", serif;
                    color: $color-primary-blue;
                    text-transform: none;
                    line-height: 1.5rem;
                    margin-bottom: 0;

                    @include breakpoint(medium) {
                        margin: inherit;
                    }
                }
            }
        }
    }

    &__results-options {
        display: flex;
        margin-bottom: 10px;

        @include breakpoint(large) {
            justify-content: flex-end;
            margin-bottom: 0;
        }
    }
}

.o-development-listings-views {
    margin-top: 0;
    padding-left: 20px;
    width: 50%;

    @include breakpoint(medium) {
        margin-top: 20px;
        width: auto;
    }

    &__button {
        @extend %c-heading--regular;
        font-family: "Lato", serif;
        color: $color-primary-blue;
        text-transform: none;
        line-height: 1.5rem;
        padding: 13px 10px 13px 30px;
        margin-bottom: 0;
        background-repeat: no-repeat;
        background-position: left center;

        &--grid {
            background-image: $svg-gridView
        }

        &--list {
            background-image: $svg-listView;
        }

        &--active {
            color: $color-purple;

            &.o-development-listings-views__button--grid {
                background-image: $svg-gridViewActive;
            }

            &.o-development-listings-views__button--list {
                background-image: $svg-listViewActive;
            }
        }
    }
}

.o-development-homes.grid-container#bh-homes {
    display: none;
}

.show-homes {
    padding-bottom: 80px;

    .show-homes__heading {
        font-family: Lato;
        font-size: 26px;
        line-height: 32px;
        font-weight: bold;
        text-align: center;
        margin: 60px 0 16px;
        color: #082D60;
        
        @include breakpoint(medium) {
            font-size: 36px;
            line-height: 46px;
            margin: 60px 0 20px;
        }
    }

    .show-homes__description {
        font-family: Lato;
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
        text-align: center;
        margin-bottom: 60px;
        color: #082D60;
        
        @include breakpoint(medium) {
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 32px;
        }
        p {
            font-family: Lato;
            font-size: 16px;
            line-height: 24px;
            font-weight: 300;
            text-align: center;
            margin-bottom: 60px;
            color: #082D60;
            
            @include breakpoint(medium) {
                font-size: 16px;
                line-height: 26px;
                margin-bottom: 32px;
            }
        }
    }
}