.lb-outerContainer,
.lb-dataContainer {
    width: 100%;
    @include breakpoint(medium) {
        @include xy-cell(10);
        @include xy-cell-offset(1);
        width: calc(83.33333% - 1.25rem)!important;
        border-radius: 0;
    }
}


.lb-dataContainer {
    height: 50px;
    padding: 0;
    margin-top: -50px;
}

.lb-container {
    padding: 15px 0;

    @include breakpoint(medium) {
        padding: 50px 0;
    }

    @include breakpoint(large) {
        padding: 80px 0;
    }

}

html.lb-disable-scrolling {
    position: initial;
    height: initial;
    width: initial;

    body {
        overflow: hidden;
    }
}

.lightboxOverlay {
    width: 100%!important;
}

.lightbox {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    height: 100vh;
    overflow: hidden;
}

.lightbox .lb-image {
    margin: 0 auto;
}

.lb-data {
    .lb-close {
        position: relative;
        background-image: $svg-close-icon;
        margin-bottom: 30px;
    }
}

.lb-nav {
    a.lb-prev {  
        position: absolute;
        width: 50%;
        background-image: $svg-chevron-left-white;
        background-position: center left;
        margin-left: -30px;
        opacity: 1;
        display: none !important;
        @include breakpoint(large) {
            display: block !important;
        }
    }

    a.lb-next {
        position: absolute;
        width: 50%;
        background-image: $svg-chevron-right-white;
        background-position: center right;
        margin-right: -30px;
        opacity: 1;
        display: none !important;
        @include breakpoint(large) {
            display: block !important;
        }
    }
}