﻿.news-article {
    &__hero {
        width: 100%;
        max-height: 40vh;
        overflow: hidden;

        @include breakpoint(medium) {
            &--center {
                padding-top: 15%;
            }
        }
    }

    &__hero-img {
        img {
            width: 100%;
            height: auto;
        }

        @include breakpoint(medium) {
            &--center {
                img {
                    transform: translateY(-50%);
                }
            }
        }
    }

    .news-article__content {
        @include breakpoint(medium) {
            padding-top: 60px;
        }     
    }
}
