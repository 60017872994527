.o-testimonial-carousel {
    .c-carousel-slides__slide {
        .c-carousel-slides__content--media.u-compat-object-fit {
            background-size: cover;
            background-position: center center;
        }
    }

    .c-testimonial-block {
        .c-carousel-slides__content--copy {
            @extend %c-rich-text-block;
        }

        .c-carousel-slides__content--video {
            @extend %carousel-iframe;

            .c-carousel-slides__iframe-container {
                position: static;
            }

            .c-carousel-slides__iframe-content {
                z-index: -1;
            }
        }

        .c-carousel-slides__content {
            .c-carousel-slides__content-inner {
                .c-testimonial-block--quote {
                    max-height: 30px;
                    width: 100%;

                    img {
                        object-fit: contain;
                        display: inline;
                        height: auto;
                        width: auto;
                    }
                }

                .c-testimonial-block--content {
                    p {
                        margin: 20px 0px;
                    }

                    b {
                        font-weight: $text-weight-bold;
                    }
                }
            }
        }
    }
}
