﻿.c-text-input {
    position: relative;

    input {
        padding: 14px 10px;
        width: 100%;
        color: rgba($color-primary-blue, 0.7);
        border: none;
        font: {
            size: $text-size-x-small;
            weight: $text-weight-light;
        }

        @include placeholder {
            color: rgba($color-primary-blue, 0.5);
        }

    }

    &--invalid {
        &::before {
            content: '';
            left: -5px;
            right: -5px;
            position: absolute;
            top: -5px;
            bottom: -5px;
            border: 1px solid lighten($color-purple, 20%);
        }
        @include breakpoint(large) {
            border: 1px solid $color-purple;
            &::before {
                content: none;
            }
        }
   
    }

    &--search {
        &::after {
            content: '';
            pointer-events: none;
            height: 100%;
            width: 30px;
            position: absolute;
            top: 2px;
            right: 5px;
            background: {
                image: $svg-icon-search-grey;
                repeat: no-repeat;
                position: right center;
            }
        }
    }

}