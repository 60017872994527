﻿.o-zoom-controls {
    display: none;

    @include breakpoint(768px) {
        display: block;
        margin-top: -10px;
        position: relative;
        @include clearfix;
    }

    @include breakpoint(medium) {
        margin-top: -50px;
    }

    &--map {
        @include breakpoint(768px) {
            bottom: 14px;
            margin-top: 0;
            position: absolute;
            right: 0;
            z-index: 1;
        }

        @include breakpoint(medium) {
            margin-top: 0;
        }
    }

    &--left-map {
        @include breakpoint(768px) {
            bottom: 40px;
            margin-top: 0;
            position: absolute;
            left: 30px;
            z-index: 1;
        }

        @include breakpoint(medium) {
            margin-top: 0;
        }

        .c-zoom-control {
            margin-right: 20px;
        }
    }
}

.c-zoom-control {
    &:not(&--disabled) {
        @extend %cta-sheen; // Can't extend inside a mixin so left outside.
    }
}

@include breakpoint(768px) {
    .c-zoom-control {
        border: none;
        border-radius: 0;
        display: block;
        float: left;
        font-size: 0;
        height: 45px;
        margin: 0;
        padding: 0;
        position: relative;
        width: 45px;

        &--in {
            background: #082d60;
        }

        &--out {
            background: #28528d;
        }

        @include breakpoint(large) {
            height: 60px;
            width: 60px;
        }
    }

    .c-zoom-control__icon {
        display: block;
        height: 100%;
        width: 100%;

        &::after,
        &::before {
            background: #fff;
            content: '';
            height: 2px;
            left: 50%;
            position: absolute;
            top: 50%;
            width: 50%;
        }

        &::after {
            display: block;
            transform: translate(-50%, -50%);
        }

        &::before {
            .c-zoom-control--in & {
                display: block;
                transform: translate(-50%, -50%) rotate(90deg);
            }

            .c-zoom-control--out & {
                display: none;
            }
        }
    }
}


@include breakpoint(medium) {
    .c-zoom-control--disabled {
        opacity: 0.2;
        cursor: default;
    }
}