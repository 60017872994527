.c-read-more {
    margin-bottom: 30px;
    transition: max-height 0.4s ease-in-out;

    &--active {
        overflow: hidden;
        transition: max-height 0.4s ease-in-out;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 40px;
            background: linear-gradient(0deg, rgba(255,255,255, 1), rgba(255,255,255, 0));
        }
    }

    &--expanded {
        &::after {
            content: none;
            display: none;
        }
    }

}

.c-read-more__wrap {
    @extend %c-rich-text-block;
    text-align: left;

    h1 {
        line-height: $text-line-height-xxxx-large;
        font-size: $text-size-xxxx-large;
    }

    h2 {
        line-height: $text-line-height-small;
        font-size: $text-size-small;
    }

    h3 {
        line-height: $text-line-height-small;
        font-size: $text-size-regular;
    }

    p {
        line-height: $text-line-height-x-small;
        font-size: $text-size-x-small;
    }

    &__title {
      display: none;
      padding-top: 25px;
      font-size: 32px;
      font-weight: 600;

      @include breakpoint(large) {
        display: block;
      }
    }
}

.c-button--read-more {
    display: none;
}
