﻿.o-footer {
  z-index: 103;
  background-color: $color-primary-blue;
  display: block !important;

  .c-footer__main {
      border-color: rgba($color-blue-dust, 0.3);
      border-width: 1px 0;
      border-style: solid;
  }
  // Styling for main footer menu
  .o-footer-menu-main {
      .c-footer-menu {
          .c-footer-menu__item {

              ol {

                  li {
                      font-weight: $text-weight-light;
                      border-bottom: rgba($color-blue-dust, 0.3) 1px solid;

                      @include breakpoint(medium) {
                          padding: 0;
                          border: none;
                      }

                      margin: 0;
                      font-size: $text-size-x-small;
                      line-height: $text-line-height-xx-small;

                      @include breakpoint(medium) {
                          margin: $sizing-xx-small 0;
                          font-size: $text-size-xx-small;
                          line-height: $text-line-height-xx-small;
                      }

                      &:first-child {
                          font-weight: $text-weight-bold;

                          a {
                              cursor: default;
                          }

                          a[href]:hover {
                              text-decoration: underline;
                              cursor: pointer;
                          }
                      }

                      &:not(:first-child):hover {
                          a {
                              text-decoration: underline;
                          }
                      }

                      a {
                          text-decoration: none;
                          color: #ffffff;
                          padding: $sizing-x-small;
                          display: inline-block;
                          width: 100%;
                          height: 100%;
                          padding: 15px 3%;
                          background-position-x: 97% !important;

                          @include breakpoint(msmall) {
                              padding: 15px 5%;
                              background-position-x: 95% !important;
                          }


                          @include breakpoint(medium) {
                              padding: 0;
                              width: auto;
                              height: auto;
                          }
                      }
                  }
              }
          }
      }
  }

  .c-footer__upper {
      position: relative;

      .c-promo-block {
          text-align: center;
          color: #ffffff;
          height: 100%;
          padding-left: 3%;
          padding-right: 3%;
          margin-top: 30px;
          margin-bottom: 30px;
          width: 100%;

          @include breakpoint(msmall) {
              padding-left: 5%;
              padding-right: 5%;
          }

          @include breakpoint(medium) {
              @include xy-cell(auto);
              padding-left: 0;
              padding-right: 0;
              text-align: right;
          }
      }

      .c-button--back-to-top {
          position: absolute;
          top: 0;
          height: 60px;
          width: 60px;
          background-image: $svg-back-to-top;
          margin-top: -60px;
          left: 50%;
          margin-left: -30px;
          cursor: pointer;

          @include squish-text();

          @include breakpoint(medium) {
              right: 0;
              left: auto;
              margin-left: 0;
          }
      }
  }
   
  .c-footer-logo-links ol {
      margin: 0 auto;
      border: none;

      li {
          a {
              text-align: left;
          }

          margin: 20px 0;
      }

      @include breakpoint(medium) {
          text-align: left;
          margin: 0;
          float: left;
      }
  }
  // Styling for copyright text block.
  .c-copyright {
      p {
          font-weight: $text-weight-light;
          color: $color-blue-dust;
          margin: $sizing-xx-small 0;
          font-size: $text-size-xx-small;
          line-height: 1;

          @include breakpoint(medium) {
              font-size: $text-size-xxx-small;
              line-height: $text-line-height-xx-small;
          }
      }
  }
  // Styling for footer sub menu
  .c-footer__lower {
      .o-footer-sub-menu {
          // Ensure that the first '|' divider is cropped, even when the menu wraps onto a new line.
          // By moving the divider out of the bounding box, it's cropped using overflow: hidden.
          @include breakpoint(medium) {
              overflow: hidden;
              margin-left: 15px;
          }

          ol {

              @include breakpoint(medium) {
                  margin: $sizing-xx-small 0;
              }

              li {
                  font-weight: $text-weight-light;

                  a {
                      text-decoration: none;
                      color: $color-blue-dust;
                      margin-right: 0.25em;
                  }
                  // Add divider character for desktop.
                  @include breakpoint(medium) {

                      a::before {
                          content: '| ';
                          color: $color-blue-dust;
                          display: inline;
                          padding: $sizing-xxx-small;
                      }


                      &:first-child a::before {
                          content: none;
                      }
                  }

                  margin: $sizing-xx-small 0;
                  font-size: $text-size-xx-small;
                  line-height: 1;

                  @include breakpoint(medium) {
                      margin: 0;
                      font-size: $text-size-xxx-small;
                      line-height: $text-line-height-xx-small;
                  }
              }
          }
      }
  }
}
