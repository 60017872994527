.o-hero-carousel,
.o-static-hero {

    @extend %hero-carousel;
    // Styling for scroll prompt
    .c-scroll-prompt {
        position: absolute;
        left: 50%;
        margin-left: -30px;
        display: inline-block;
        z-index: 2;

        @include squish-text;
        background-image: $svg-scroll-prompt;
        background-repeat: no-repeat;
        background-position: center top;
        height: 12px;
        width: $sizing-regular;

        @include breakpoint(small) {
            display: none;
        }

        @include breakpoint(medium) {
            display: block;
            bottom: 80px;
            animation-name: bounce;
            animation-duration: 2s;
            animation-iteration-count: infinite
        }
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-17px);
    }

    60% {
        transform: translateY(-10px);
    }
}
