.c-local-area-map-wrapper {
    margin: 0 !important;
}

.c-local-area-map {
    background: #ddd;
    //position: relative;
    //@include aspect-ratio(585, 626);
    max-height: 608px;

    .gm-style-iw {
        h3 {
            @extend %c-heading--regular;
            color: #082d60;
            font: {
                size: $text-size-x-small;
                weight: $text-weight-regular;
            }
            line-height: $text-line-height-xx-small;
            margin: 5px 0 0;
        }

        p {
            @extend %c-bodytext--regular;
            color: #082d60;
            margin: 0;
            opacity: 0.5;
            font: {
                size: $text-size-xx-small;
                weight: $text-weight-regular;
            }
            line-height: $text-line-height-xxxx-small;
        }
    }

    > p {
        @extend %c-bodytext--regular;
        background: #ddd;
        display: block;
        font-weight: $text-weight-light;
        height: 100%;
        left: 0;
        margin: 0;
        padding: 20px;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 3;
    }

    @include breakpoint(large) {
        display: block;
    }
}

.c-local-area-map__content {
    display: block;
    height: 100%;
    min-height: 532px;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 1;
}