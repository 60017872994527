.slide-container {
  width: 100%;
  display: flex;
  flex-direction: column;


  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .currency-symbol {
    position: absolute;
    left: 18px;
    top: 6px;
    font-size: 20px;
    font-weight: 600;
  }
}

.slider-value {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border: 1px solid #cccccc;
  color: #0c2c64;
  font-size: 20px;
  font-weight: 600;
  padding: 0 20px 0 30px;
}

.slide-container__scale {
  display: flex;
  justify-content: space-between;

  &__incr {
    color: #8a8a8a;
    display: none;
    
    &.active {
      color: #082d60;
      font-weight: 600;
    }

    &:last-of-type,
    &:first-of-type {
      display: block;
    }
  }
}

.slider {
  width: 100%;
  height: 23px;
  margin: 20px 0;
  background: #d8d8d8;
  outline: none;
  opacity: 0.7;
  border-radius: 11.5px;
  transition: opacity .2s;
  -webkit-transition: .2s;
  -webkit-appearance: none;

  &:hover {
    opacity: 1;
  }
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 36px;
  height: 36px;
  background: #fff;
  border: 1px solid #979797;
  border-radius: 18px;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 36px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #979797;
  border-radius: 18px;
  cursor: pointer;
}

.slider::-ms-track {
  width: 100%;
  height: 23px;
  background: transparent;
  border-color: transparent;
  border-width: 10px 0;
  color: transparent;
}

.slider::-ms-fill-lower,
.slider::-ms-fill-upper {
  border-radius: 10px;
  background: #d8d8d8;
}

.slider::-ms-thumb {
  width: 36px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #979797;
  border-radius: 18px;
  cursor: pointer;
}

.slider:focus::-ms-fill-lower,
.slider:focus::-ms-fill-upper {
  background: #d8d8d8;
}

.slider::-ms-tooltip {
  display: none;
}

@include breakpoint(large) {
  .slider {
    height: 11px;
    border-radius: 5.5px;
  }

  .slider::-webkit-slider-thumb {
    width: 28px;
    height: 28px;
    border-radius: 14px;
  }
  
  .slider::-moz-range-thumb {
    width: 28px;
    height: 28px;
    border-radius: 14px;
  }

  .slider::-ms-thumb {
    width: 28px;
    height: 28px;
    border-radius: 14px;
  }

  .slider::-ms-track {
    height: 11px;
  }

  .slider-value {
    width: 164px;
    height: 50px;
    line-height: 50px;
  }

  .slide-container .currency-symbol {
    top: 11px;
  }

  .slide-container__scale {
    display: flex;
    justify-content: space-between;
  
    &__incr {
      display: block;

      &:not(:last-of-type) {
        margin-left: 15px;
      }
    }
  }
}


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .slider {
    height: auto;
    width: auto;
    background: transparent;
    margin: -8px 0;
  }

  .slider-value {
    line-height: normal;
  }
}