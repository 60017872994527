﻿.o-layout-1col {
    position: relative;
    overflow: hidden;
    &:not(.o-full-width-content--no-bg) {
        @extend %u-section-separation;
    }

    &.o-full-width-content {
        &:not(.o-full-width-content--no-bg) {
            margin: 0 0 $sizing-small 0 !important;
        }
    }

    .c-promotion-block__content.c-promotion-block__content--overlay.u-compat-object-fit {
        /*padding: 80px 20px;

        @include breakpoint(medium) {
            padding: 80px 100px;
        }*/
    }

    .c-promotion-block__content--copy {
        max-width: 900px;
        margin: 0 auto;
        padding: 40px 30px;
        
        @include breakpoint(medium) {
            a {
                position: static !important;
            }
        }
    }

    .content--extra-padding {        
        padding-top: 80px;
    }
    
    .content--no-v-padding {        
        padding: {
            top: 0px;
            bottom: 0px;
        }
    }

    .c-layout__column {
        position: relative;

        .o-promotion-block .c-promotion-block__content {
            img, 
            picture {
                position: absolute;
                left: 0;

                &.img-in-rich-text {
                    opacity: 1;
                    position: static;
                    object-fit: contain;
                }
            }

            p {
                img {
                    opacity: 1;
                    position: static;
                    object-fit: contain;
                }
            }
        // make images in p tags appear in the dom
        }
    }

    &--bordered {
        @extend %u-section-separation--border;
    }

    &--no-separation {
        &::after {
            content: none;
        }
    }

    &--no-before {
        &::before {
            content: none;
        }
    }

}
