﻿.feature-block {
    padding: 0 0 40px;
    position: relative;

    h2 {
        color: $color-primary-blue;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin: 0 0 30px;

        @include breakpoint(medium) {
            margin: 0 0 40px;
        }
    }

    &__container {
        width: 100%;
        position: relative;
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));

        @include breakpoint(large) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    .feature-block__carousel {
        position: relative;

        .main-carousel {
            position: relative;

            .c-carousel-slides {
                overflow: hidden;
                position: relative;
                width: 100%;
                display: flex;
                flex-flow: row wrap;
                margin-bottom: 10px;

                &__slide,
                .slick-list,
                &__content {
                    width: 100%;
                }

                img {
                    max-width: 800px;
                    max-height: 600px;
                    width: 100%;
                    min-width: 100%
                }
            }

            .c-carousel-next {
                @extend %carousel-button--next;
                display: flex !important;
                border: 0;
                right: 0;
                background-image: $svg-chevron-right-white;

                &:hover {
                    background-image: $svg-chevron-right-white;
                }

                &::before {
                    display: none;
                }
            }

            .c-carousel-prev {
                @extend %carousel-button--prev;
                display: flex !important;
                border: 0;
                left: 0;
                background-image: $svg-chevron-left-white;

                &:hover {
                    background-image: $svg-chevron-left-white;
                }

                &::before {
                    display: none;
                }
            }

            .c-carousel-next,
            .c-carousel-prev {
                height: 40px;
                width: 40px;
                background-size: 10px;
                cursor: pointer;
                z-index: 1;
                position: absolute;
                top: 50%;

                &:focus {
                    outline: 1px solid red;
                }

                &.slick-disabled {
                    background-color: $color-primary-blue;
                    opacity: 0.5;
                    cursor: default;
                }
            }

            ul.c-carousel-pager {
                position: absolute;
                bottom: 8px;
                display: block !important;
                width: 100%;
                text-align: center;
                padding: 0;
                z-index: 1;

                &.u-hover {
                    opacity: 1;
                    transition: none;
                }

                li {
                    width: 30px;
                    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
                    margin: 0 $sizing-xxx-small;
                    display: inline-block;

                    button {
                        height: 10px;
                    }

                    &.slick-active {
                        border-color: $white;
                    }
                }

                button {
                    @include squish-text;
                    height: 30px;
                    width: 30px;
                }

                @include breakpoint(msmall) {
                    bottom: 15px;
                }

                @include breakpoint(lsmall) {
                    li {
                        button {
                            height: 30px;
                        }
                    }
                }

                @include breakpoint(medium) {
                    bottom: 25px;
                }

                button {
                    appearance: none;
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                    @include squish-text;
                    height: $sizing-small;

                    @include breakpoint(large) {
                        height: $sizing-small;
                    }

                    width: $sizing-small;
                }
            }
        }

        .thumbnail-carousel {
            width: 100%;
            .slide {
                cursor: pointer;
            }

            .slick-list {
                overflow-x: auto;
                width: 100%;

                .slick-track {
                    width: 100%;
                    transform: none !important;
                    display: flex;
                    gap: 10px;
                    margin: 0;

                    &::before {
                        display: none;
                    }

                    img {
                        max-width: 130px;
                        max-height: 85px;
                        width: 100%;
                        height: auto;
                        margin: 0 auto;
                    }
                }
            }
        }
    }

    .text-block {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding-top: 25px;
        padding: 25px 5% 30px 0;
        width: 100%;

        @include breakpoint(large) {
            padding: 0 0 30px;
            gap: 60px;
            width: 75%;
        }

        @include breakpoint(large) {
            width: 60%;
        }

        @include breakpoint(xlarge) {
            width: 60%;
        }

        .info {
            display: flex;
            flex-direction: column;
            gap: 26px;

            .group {
                display: flex;
                flex-direction: column;
                gap: 10px;
            }

            h3,
            h4,
            h5,
            h6 {
                color: $color-primary-blue;
                font-family: Lato;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                margin: 0;
            }

            h4 {
                font-size: 18px;
                line-height: 20px;
                margin: 10px 0;
            }

            p {
                color: #525252;
                font-family: Lato;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                margin: 0;

                b,
                strong {
                    color: $color-primary-blue;
                    font-weight: bold;
                }

                &.opening-times {
                    color: $color-primary-blue;
                }
            }

            .buttons {
                display: flex;
                gap: 10px;
                flex-wrap: wrap;
                margin: 15px 0;

                @include breakpoint(medium) {
                    margin: 20px 0;
                }

                .c-button {
                    color: $color-primary-blue;
                    background-color: transparent;
                    font-family: $primary-font;
                    font-size: $text-size-x-small;
                    line-height: $text-line-height-xxxx-large;
                    font-style: normal;
                    padding: 2px 20px;
                    display: inline-block;
                    text-decoration: none;
                    border-color: $color-primary-blue;
                    border-style: solid;
                    border-width: 2px;
                    transition: color 0.2s, background-color 0.2s, border-color 0.2s;

                    @include breakpoint(medium) {
                        padding: 5px 35px;
                    }

                    &:hover,
                    &:focus {
                        color: $color-white;
                        background-color: $color-primary-blue;
                    }

                    &:focus {
                        outline: 1px solid red;
                    }

                    &.solid {
                        color: $color-white;
                        background-color: $color-primary-blue;
                        border-color: transparent;

                        &:hover,
                        &:focus {
                            color: $color-primary-blue;
                            background-color: transparent;
                            font-family: $primary-font;
                            border-color: $color-primary-blue;
                        }
                    }
                }
            }
        }

        .offer {
            display: inline-flex;
            padding: 20px 20px 10px;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            background: rgba(172, 192, 150, 0.3);

            @include breakpoint(medium) {
                padding: 30px 30px 20px;
            }

            h3,
            h4 {
                color: $color-primary-blue;
                font-family: Lato;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                margin: 0;
            }

            p,
            a {
                color: $color-primary-blue;
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                margin: 0;
            }

            a {
                color: #842752;
                text-decoration: none;
                font-size: 16px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}