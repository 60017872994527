.location-list {
  display: flex;
  padding: 0 20px;

  @media screen and (min-width: 768px) {
    padding: 0 70px;
  }

  @media screen and (min-width: 1366px) {
    margin-top: 70px;
  }

  &__wrapper {
    flex: 2;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: space-between;
    padding: 0;
    margin: -47px 40px 40px 0;

    @media screen and (min-width: 768px) {
      margin: -47px 60px 60px 0;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin-top: 24px;

    @media screen and (min-width: 768px) {
      margin-top: 28px;
    }
  }

  &__list-item {
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;

    &--location:not(:first-of-type) {
      margin-top: 18px;
    }

    @media screen and (min-width: 768px) {
      width: calc(100% / 3);
      margin: 47px 20px 0 20px;
    }

    @media screen and (min-width: 992px) {
      width: 25%;
    }

    @media screen and (min-width: 1200px) {
      width: calc(100% / 6);
    }
  }

  &__title {
    display: flex;
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    font-family: "Roboto Slab", sans-serif;
    color: #153372;
  }

  &__icon {
    align-self: center;
    margin-left: 5px;
  }

  &__location {
    font-size: 16px;
    color: #898989;

    @media screen and (min-width: 768px) {
      font-size: 14px;
    }

    &:hover,
    &:active {
      color: darken($color: #898989, $amount: 20%);
    }
  }
}

#feature-locations-map {
  flex: 1;
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
  }
}
