﻿.u-experience-editor {
  
    .o-hero-carousel {
        &:before {
            display: none;
        }
        .c-carousel-slides__slide {
            display: none;
            &:first-child  {
                display: block;
            } 
        }        
    }
}
