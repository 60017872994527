﻿.c-secondary-nav {
    //  @include grid-container-custom;
    margin-left: 0 !important;
    margin-right: 0 !important;
    background-color: #fff;
    z-index: 15;
    transition: top 0.3s;

    @include breakpoint(large) {
        height: 80px;
        border-top: none;
        border-bottom: 1px solid #CED5DF;
        margin-left: 5% !important;
        margin-right: 5% !important;
        max-width: none !important;
        z-index: 8;
    }

    @include breakpoint(xxlarge) {
        margin-left: auto !important;
        margin-right: auto !important;
        width: $global-width;
    }

    .c-secondary-nav__menu-button-container {
        border-bottom: 1px solid #CED5DF;
        padding: 0 3%;
        display: flex;

        @include breakpoint(msmall) {
            padding: 0 5%;
        }

        @include breakpoint(large) {
            display: none;
        }
    }


    .c-secondary-nav__menu-button {
        height: 60px;
        @include xy-cell(6, $gutter-type: none);
        font-size: $text-size-x-small;
        color: $color-primary-blue;
        text-align: left;
        font-family: $primary-font;
        padding: 0;
        line-height: 60px;

        @include breakpoint(medium) {
            @include xy-cell(5, $gutter-type: none);
        }

        &:after {
            content: '';
            background-image: $svg-arrow-down-blue;
            background-repeat: no-repeat;
            background-position: right center;
            margin-right: 15px;
            height: 100%;
            float: right;
            width: 18px;
            transition: transform 0.2s;
        }
    }

    .c-secondary-nav__links-container {
        max-height: 0px;
        overflow: hidden;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 2;
        transition: max-height 0.3s ease-in;

        @include breakpoint(large) {
            position: static;
            max-height: none;
            display: flex;
            opacity: 0;
            animation: fadeIn 0.3s 0.3s forwards ease-in;
        }
    }

    .c-secondary-nav__back-arrow {
        display: none;

        @include breakpoint(large) {
            background-image: $svg-arrow-down-blue;
            background-repeat: no-repeat;
            background-position: right center;
            height: 14px;
            width: 18px;
            display: inline-block;
            transform: rotate(90deg);
            margin: auto 0;
        }
    }

    .c-secondary-nav__anchor-link {
        @extend %u-hyperlink;
        color: $color-primary-blue;
        text-decoration: none;
        display: none;
        padding-left: 3%;
        padding-right: 3%;
        background-color: $color-medium-grey;
        border-bottom: $mobile-menuitem-border;
        height: 60px;
        line-height: 60px;

        @include breakpoint(msmall) {
            padding-left: 5%;
            padding-right: 5%;
        }

        @include breakpoint(large) {
            display: inline-block;
            padding: 0 10px;
            background-color: transparent;
            border: none;
            height: 80px;
            line-height: 80px;
            font-size: $text-size-xx-small;

            &:nth-of-type(2) {
                padding-left: 8px;
            }
        }

        @include breakpoint(xlarge) {
            font-size: $text-size-x-small;
            padding: 0 12px;
        }

        &--active {
            display: none !important;

            @include breakpoint(large) {
                display: inline-block !important;
                color: $color-purple;
                text-decoration: underline;
            }
        }
    }


   .development-status {
      color: $color-purple;
      font-size: 14px;
      padding: 0 30px;
      text-transform: uppercase;

      &.mobile {
        padding: 0;
        margin: auto;
      }
   }
  
    .c-secondary-nav__bottom-button {
      position: fixed;
      width: 100%;
      text-align: center;
      z-index: 10;
      bottom: -40px;
      opacity: 0;
      transition: all 0.3s ease-in;
      
      a {
        margin: auto;
        width: 90vw;
        background-color: $color-purple;
        height: 50px;
        color: #fff;
        line-height: 50px;
        padding: 0 5px;
        text-align: center;
        font-size: 1rem;
        display: block;
      }

      &--visible {
        bottom: 20px;
        opacity: 1;
      }

      @include breakpoint(large) {
        display: none;
      }
    }

    .c-secondary-nav__coming-soon {
      margin-left: auto;
      height: 80px;
      line-height: 80px;
      padding-right: 50px;
      display: none;

      .c-button {
        background-color: #082d60;
        height: 40px;
        color: #fff;
        line-height: 40px;
        padding: 0 5px;
        text-align: center;
        display: inline-block;
        min-width: 154px;
        font-size: 12px;

        &.coming-soon-cta {
            background-color: $color-purple;
        }
      }

      @include breakpoint(large) {
        display: inline-block;
      }

      @include breakpoint(xlarge) {
        padding-right: 80px;

        .development-status {
          font-size: 16px;
          padding: 0 46px;
        }

        .c-button {
          font-size: 14px;
          line-height: 50px;
          height: 50px;
          min-width: 174px;
        }
      }
  }

    &--open {

        .c-secondary-nav__links-container {
            max-height: calc(100vh - 60px);
            overflow: auto;
        }

        .c-secondary-nav__anchor-link {
            display: block;
            font-weight: $text-weight-light;
        }

        .c-secondary-nav__menu-button {
            &:after {
                transform: rotate(180deg);
            }
        }
    }

    &--fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }
}


.o-secondary-nav-placeholder {
    height: 60px;
    background-color: #fff;
    border-bottom: 1px solid #fff;
    opacity: 0;

    @include breakpoint(large){
        height: 80px;
    }

    &--hidden {
        display: none;
    }

}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}