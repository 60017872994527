﻿.o-additional-information {
    @include clearfix;
    @extend %u-section-separation;
    @extend %u-section-separation--border;

    &__body {

        @include breakpoint(large) {
            @include xy-cell(10);
            @include xy-cell-offset(1);
            margin-left: 0;
        }

        .o-layout-3col {

            .c-layout__column {

                justify-content: space-evenly;

                @include breakpoint(large) {
                    justify-content: initial
                }

            }
        }
    }
}
