.intro {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: #082d60;
  margin: 24px 0 42px;

  @media screen and (min-width: 768px) {
    margin: 32px 0 50px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    @media screen and (min-width: 768px) {
      max-width: 60vw;
      margin: 0 auto;
    }
  }

  &__title {
    margin: 0;
    font-size: 24px;
    font-weight: bold;

    @media screen and (min-width: 768px) {
      font-size: 32px;
    }
  }

  &__title,
  &__copy {
    width: 100%;
  }

  &__copy {
    margin: 30px 0 0;
    font-size: 14px;
    font-weight: 300;

    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
  }

  &__button {
    margin: 30px 0 0;
    cursor: pointer;

    &:hover,
    &:active {
      color: #842752;
      text-decoration: underline;
    }

    @media screen and (min-width: 768px) {
      display: none;
    }

    &__text {
      display: none;

      &--alt {
        display: none;
      }
    }
  }
}
