.o-departments-listing {
    &__border {
        @include breakpoint(xlarge) {
            width: calc(100% - 1.25rem);
            position: absolute;
            top: 0;
            left: 0.625rem;
            border-top: 1px solid rgba(8, 45, 96, 0.2);
        }
    }

    &__header {
        @extend %c-heading--large;
        width: calc(100% - 60px); // Filter button on small - medium screens
        font-weight: $text-weight-light;
    }


    &__sub-header, .o-departments-listing__none-found {
        @extend %c-heading--medium;

        span {
            color: $color-purple;
        }

        margin-bottom: 40px;

        &--hidden {
            display: none;
        }
    }

    .o-departments-listing__none-found {
        display: none;
        padding-bottom: 200px;

        &--visible {
            display: block;
        }
    }

    &__card {
        @extend %c-rich-text-block;
        text-align: center;
        margin-bottom: $sizing-regular;

        &--disabled {
            opacity: 0.5;
            cursor: default;
            pointer-events: none;
        }

        &__media {
            width: 100%;
            overflow: hidden;
            padding-bottom: 83%;
            position: relative;

            img {
                position: absolute;
                display: block;
                top: 0;
                left: 0;
                width: auto;
                min-width: 100%;
                min-height: 100%;
                -o-object-fit: cover;
                object-fit: cover;
            }
            // Using a JS snippet to provide images embedded into department card as a background image to deal with lack of imagefit support for IE.
            &.u-compat-object-fit {
                background-size: cover;
                background-position: center center;

                img, picture { // hide image if object fit is not supported - opacity to 0 for the link area
                    opacity: 0;
                }
            }
        }

        &__header {
            font-size: $text-size-x-small;
            font-weight: normal;
            text-transform: uppercase;
            margin-top: 20px;
            line-height: 1.3;
        }

        &__divider {
            @extend %u-section-border;
            width: 250px;
            margin: 20px auto;

            @include breakpoint(medium) {
                width: auto;
                margin: 20px $sizing-small;
            }
        }

        &__distance {
            &::before {
                content: $svg-icon-location-blue;
                display: inline-block;
                vertical-align: middle;
                padding-right: 8px;
            }
        }

        .c-button span {
            width: 250px;

            @include breakpoint(medium) {
                width: auto;
            }
        }

        &__body p {
            font-size: $text-size-x-small !important;
            font-weight: 300 !important;
            line-height: 20px !important;
            margin-bottom: 35px;
            margin-top: 25px;
        }
    }
}
