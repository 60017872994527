﻿.plot-header {
  width: 99vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-height: 1000px;
  overflow: hidden;

  .plot-header__container {
    width: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;

    @include breakpoint(large) {
      flex-direction: row;
    }
  }

  .plot-header__carousel {
    position: relative;
    width: 100%;

    @include breakpoint(large) {
      width: 67%;
      max-width: 1200px;
    }

    .main-carousel {
      position: relative;

      .c-carousel-slides {
        overflow: hidden;
        position: relative;
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 10px;

        &__slide {
          width: 100%;
        }

        &__content {
          width: 100%;
        }

        img {
          width: 100%;
          min-width: 100%;
        }
      }

      .c-carousel-next {
        @extend %carousel-button--next;
        display: flex !important;
        border: 0;
        right: 0;
        background-image: $svg-chevron-right-white;

        &:hover {
          background-image: $svg-chevron-right-white;
        }

        &::before {
          display: none;
        }
      }

      .c-carousel-prev {
        @extend %carousel-button--prev;
        display: flex !important;
        border: 0;
        left: 0;
        background-image: $svg-chevron-left-white;

        &:hover {
          background-image: $svg-chevron-left-white;
        }

        &::before {
          display: none;
        }
      }

      .c-carousel-next,
      .c-carousel-prev {
        height: 40px;
        width: 40px;
        background-size: 10px;
        cursor: pointer;
        z-index: 1;
        position: absolute;
        top: 50%;

        &:focus {
          outline: 1px solid red;
        }

        &.slick-disabled {
          background-color: $color-primary-blue;
          opacity: 0.5;
          cursor: default;
        }
      }

      ul.c-carousel-pager {
        position: absolute;
        bottom: 8px;
        display: block !important;
        width: 100%;
        text-align: center;
        padding: 0;
        z-index: 2;

        &.u-hover {
          opacity: 1;
          transition: none;
        }

        li {
          width: 30px;
          border-bottom: 2px solid rgba(255, 255, 255, 0.5);
          margin: 0 $sizing-xxx-small;
          display: inline-block;

          button {
            height: 10px;
          }

          &.slick-active {
            border-color: $white;
          }
        }

        button {
          @include squish-text;
          height: 30px;
          width: 30px;
        }

        @include breakpoint(msmall) {
          bottom: 15px;
        }

        @include breakpoint(lsmall) {
          li {
            button {
              height: 30px;
            }
          }
        }

        @include breakpoint(medium) {
          bottom: 25px;
        }

        button {
          appearance: none;
          background-color: transparent;
          border: none;
          cursor: pointer;
          @include squish-text;
          height: $sizing-small;

          @include breakpoint(xlarge) {
            height: $sizing-small;
          }

          width: $sizing-small;
        }
      }
    }

    .thumbnail-carousel {
      margin: 0 0 0 14px;

      @include breakpoint(large) {
        margin: 0;
      }

      .slick-arrow {
        position: absolute;
        top: 50%;
        z-index: 5;
        transform: translateY(-50%);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 10px 15px;
        background-color: #082d60;
        width: 25px;
        height: 28px;
        text-indent: -9999px;
        overflow: hidden;
      }

      .slick-next {
          right: 0;
      }

      .slick-prev {
          left: 0;
      }

      .slick-prev {
          display: flex !important;
          border: 0;
          left: 0;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoLTE4MCA2LjUgMTEpIiBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxyZWN0IHRyYW5zZm9ybT0icm90YXRlKDQ1IDYuNSA3KSIgeD0iLTEiIHk9IjYiIHdpZHRoPSIxNSIgaGVpZ2h0PSIyIiByeD0iMSIvPjxyZWN0IHRyYW5zZm9ybT0icm90YXRlKC00NSA2LjUgMTYpIiB4PSItMSIgeT0iMTUiIHdpZHRoPSIxNSIgaGVpZ2h0PSIyIiByeD0iMSIvPjwvZz48L3N2Zz4=);
      }

      .slick-next {
          display: flex !important;
          border: 0;
          right: 0;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAtMSkiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHJlY3QgdHJhbnNmb3JtPSJyb3RhdGUoNDUgNi41IDcpIiB4PSItMSIgeT0iNiIgd2lkdGg9IjE1IiBoZWlnaHQ9IjIiIHJ4PSIxIi8+PHJlY3QgdHJhbnNmb3JtPSJyb3RhdGUoLTQ1IDYuNSAxNikiIHg9Ii0xIiB5PSIxNSIgd2lkdGg9IjE1IiBoZWlnaHQ9IjIiIHJ4PSIxIi8+PC9nPjwvc3ZnPg==);
      }

      .slick-list {
        .slick-track {
          display: flex;
          gap: 10px;
          margin: 0;

          &::before {
            display: none;
          }

          .slick-slide {
            max-width: 130px;
            max-height: 85px;
            cursor: pointer;

            img {
                width: 130px;
                height: 85px;
                width: 100%;
                height: auto;
                margin: 0 auto;
            }
          }
        }
      }
    }
  }

  .text-block {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-top: 25px;
    padding: 25px 5% 30px;
    gap: 30px;
    width: 100%;

    @include breakpoint(large) {
      width: 30%;
      padding: 50px 20px 30px;
      gap: 60px;
    }

    @include breakpoint(xxlarge) {
      padding: 80px 20px 30px;
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 16px;

      h3,
      h4,
      h5,
      h6 {
        color: $color-primary-blue;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin: 0;
      }

      p {
        color: #525252;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 0;

        &.price {
          font-family: Lato;
          color: $color-primary-blue;
          font-size: 26px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px;
          margin-top: 4px;

          @include breakpoint(medium) {
            font-size: 36px;
            margin-top: 6px;
          }
        }

        &.price-disclaimer {
          color: $color-primary-blue;
          font-size: 12px;
          line-height: 0px;
          margin-top: -5px;
          margin-bottom: -15px;

          @include breakpoint(large) {
            margin-top: 0px;
            margin-bottom: -10px;
          }
        }

        &.phone {
          color: $color-primary-blue;
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><mask id="mask0_350_3116" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16"><rect width="16" height="16" fill="%23D9D9D9"/></mask><g mask="url(%23mask0_350_3116)"><path d="M13.3 14C11.9111 14 10.5389 13.6972 9.18333 13.0917C7.82778 12.4861 6.59444 11.6278 5.48333 10.5167C4.37222 9.40556 3.51389 8.17222 2.90833 6.81667C2.30278 5.46111 2 4.08889 2 2.7C2 2.5 2.06667 2.33333 2.2 2.2C2.33333 2.06667 2.5 2 2.7 2H5.4C5.55556 2 5.69444 2.05278 5.81667 2.15833C5.93889 2.26389 6.01111 2.38889 6.03333 2.53333L6.46667 4.86667C6.48889 5.04444 6.48333 5.19444 6.45 5.31667C6.41667 5.43889 6.35556 5.54444 6.26667 5.63333L4.65 7.26667C4.87222 7.67778 5.13611 8.075 5.44167 8.45833C5.74722 8.84167 6.08333 9.21111 6.45 9.56667C6.79444 9.91111 7.15556 10.2306 7.53333 10.525C7.91111 10.8194 8.31111 11.0889 8.73333 11.3333L10.3 9.76667C10.4 9.66667 10.5306 9.59167 10.6917 9.54167C10.8528 9.49167 11.0111 9.47778 11.1667 9.5L13.4667 9.96667C13.6222 10.0111 13.75 10.0917 13.85 10.2083C13.95 10.325 14 10.4556 14 10.6V13.3C14 13.5 13.9333 13.6667 13.8 13.8C13.6667 13.9333 13.5 14 13.3 14ZM4.01667 6L5.11667 4.9L4.83333 3.33333H3.35C3.40556 3.78889 3.48333 4.23889 3.58333 4.68333C3.68333 5.12778 3.82778 5.56667 4.01667 6ZM9.98333 11.9667C10.4167 12.1556 10.8583 12.3056 11.3083 12.4167C11.7583 12.5278 12.2111 12.6 12.6667 12.6333V11.1667L11.1 10.85L9.98333 11.9667Z" fill="%23082D60"/></g></svg>');
          background-repeat: no-repeat;
          background-position: 0px 4px;
          padding-left: 20px;

          a {
            color: $color-primary-blue;
            text-decoration: none;
            font-weight: bold;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .buttons {
        display: flex;
        gap: 10px;
        margin: 15px 0;
        flex-wrap: wrap;

        @include breakpoint(medium) {
          margin: 20px 0;
        }

        .c-button {
          width: 100%;
          text-align: center;
          color: $color-primary-blue;
          background-color: transparent;
          font-family: $primary-font;
          font-size: $text-size-x-small;
          line-height: 25px;
          font-style: normal;
          padding: 10px 20px;
          display: flex;
          text-decoration: none;
          border-color: $color-primary-blue;
          border-style: solid;
          border-width: 2px;
          transition: color 0.2s, background-color 0.2s, border-color 0.2s;
          align-items: center;
          justify-content: center;
          flex: none;
          white-space: nowrap;

          @include breakpoint(500px) {
            flex: 1;
          }

          @include breakpoint(720px) {
            flex: 1;
          }

          @include breakpoint(large) {
            padding: 10px 30px;
          }

          &:first-child {
            width: 100%;
            flex: 1;

            @include breakpoint(500px) {
              flex: none;
            }

            @include breakpoint(720px) {
              flex: 1;
            }

            @include breakpoint(large) {
              flex: none;
            }
          }

          &:hover,
          &:focus {
            color: $color-white;
            background-color: $color-primary-blue;
          }

          &:focus {
            outline: 1px solid red;
          }

          &.solid {
            color: $color-white;
            background-color: $color-primary-blue;
            border-color: transparent;
            width: 100%;
            max-width: unset;
            white-space: nowrap;

            &:hover,
            &:focus {
              color: $color-primary-blue;
              background-color: transparent;
              font-family: $primary-font;
              border-color: $color-primary-blue;
            }
          }
        }
      }
    }

    .offer {
      display: inline-flex;
      padding: 20px 20px 15px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      background: rgba(172, 192, 150, 0.3);

      @include breakpoint(medium) {
        padding: 30px 30px 25px;
      }

      h3,
      h4 {
        color: $color-primary-blue;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin: 0;
      }

      a {
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin: 0;
        color: #842752;
        text-decoration: none;
        font-size: inherit;

        &:hover {
          text-decoration: underline;
        }
      }

      p {
        color: $color-primary-blue;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 0;

        @include breakpoint(medium) {
          line-height: 20px;
        }
      }
    }
  }
}