﻿.c-development-sticky-cta {
    color: $color-primary-blue;
    text-align: left;
    position: fixed;
    bottom: 0;
    z-index: 102;
    width: 100%;
    max-width: 100%;
    left: 0;
    right: 0;
    justify-content: center;

    @include breakpoint(large) {
        text-align: center;

        &__hidden {
            display: none;
        }
    }

    &.hide-on-desktop {
        background-color: #fff;

        @include breakpoint(large) {
            display: none !important;
        }
    }

    .c-development-sticky-cta__nav {
        padding: 10px 18px;
    }

    .c-development-sticky-cta__content {
        justify-content: center;
        display: flex;
        width: 100%;
        align-items: center;
        flex-shrink: 0;
    
        @include breakpoint(large) {
            background-color: #ffffff;
            padding-top: 24px;
            padding-bottom: 24px;
            gap: 24px;
            flex-direction: column;
            box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.02), 1px -7px 15px 0px rgba(0, 0, 0, 0.02), 2px -27px 27px 0px rgba(0, 0, 0, 0.02), 5px -60px 36px 0px rgba(0, 0, 0, 0.01), 8px -107px 43px 0px rgba(0, 0, 0, 0.00), 13px -167px 47px 0px rgba(0, 0, 0, 0.00);
        }
    }

    .c-development-sticky-cta__phone-no {
        display: none;
        font-size: $text-size-x-small;
        text-decoration: none;

        .c-phone-icon {
            @include breakpoint(large) {
                margin-left: -9px;
                height: 18px;
                width: 18px;
                background-size: 18px 18px;
            }
        }

        a {
            display: flex;
            align-items: center;
            gap: 4px;
            margin: 0 auto;
            color: $color-primary-blue;
            text-decoration: none;

            span:not(.c-phone-icon) {
                color: $color-primary-blue;
                border-bottom: 1px solid transparent;
            }
    
            &:hover {
                span:not(.c-phone-icon) {
                    border-bottom: 1px solid $color-primary-blue;
                }
            }
        }

        @include breakpoint(large) {
            display: flex;
            justify-content: center;
            width: 100%;
            font-size: $text-size-x-small;
            position: static;
            height: auto;
            line-height: normal;
        }

        @include breakpoint(xlarge) {
            font-size: $text-size-small;
        }
    }

    .c-development-sticky-cta__buttons {
        display: flex;
        background-color: $color-light-grey;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        @include breakpoint(large) {
            background-color: transparent;
            max-width: 90rem;
            padding: 0px 5%;
            height: 65%;
            flex-wrap: wrap;
            align-items: center;
            gap: 16px 20px;
            height: auto;
        }

        @include breakpoint(xxlarge) {
            padding: 0px 0px;
        }
    }

    .c-button {
        display: flex;
        gap: 5px;
        margin: 0;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        font-size: $text-size-xxx-small;
        text-align: center;
        flex: 1 0 0;
        background-color: transparent;
        padding: 10px 0;
        border-right: 1px solid #C3C3C3;

        &:after {
            display: none;
        }

        &:first-of-type {
            border-top-left-radius: 10px;

            @include breakpoint(large) {
                border-top-left-radius: 0;
            }
        }

        &:last-of-type {
            border-top-right-radius: 10px;
            border-right: 0;

            @include breakpoint(large) {
                border-top-right-radius: 0;
            }
        }

        span {
            color: $color-primary-blue;

            @include breakpoint(large) {
                color: $color-white;
                border-bottom: 1px solid transparent;
                display: inline-block;
                line-height: 20px;
            }
        }

        &:hover,
        &:focus,
        &:active {
            background-color: $color-purple;
            border-right: 1px solid $color-purple;

            &:last-of-type {
                border-right: 0;
            }

            @include breakpoint(large) {
                background-color: $color-primary-blue;
                border-right: 0;
            }

            span {
                color: $color-white;

                @include breakpoint(large) {
                    border-bottom: 1px solid $color-white;
                }
            }

            .c-brochure-icon {
                background-image: $svg-google-brochure-white;
            }

            .c-calendar-icon {
                background-image: $svg-google-calendar-white;
            }

            .c-info-icon {
                background-image: $svg-google-info-white;
            }

            .c-phone-icon {
                background-image: $svg-google-phone-white;
            }
        }

        &.primary-cta {
            &:hover,
            &:focus,
            &:active {
                @include breakpoint(large) {
                    background-color: $color-purple;
                }
            }
        }

        @include breakpoint(large) {
            padding: 0 5px;
            justify-content: center;
            color: #fff;
            align-items: center;
            gap: 10px;
            background: var(--primary-bloor-blue, #082D60);
            height: 45px;
            line-height: 45px;
            font-size: $text-size-x-small;
            border-right: 0;

            &.primary-cta {
                background-color: $color-purple;
            }
        }

        @include breakpoint(xlarge) {
            font-size: $text-size-small;
        }

        @include breakpoint(xlarge) {
            height: 55px;
            line-height: 55px;
        }

        .hide-on-mobile {
            display: none;
    
            @include breakpoint(large) {
                display: initial;
            }
        }

        &.hide-on-desktop {
            background-color: $color-purple;

            .c-phone-icon {
                background-image: $svg-google-phone-white;
            }

            span {
                color: $color-white;
            }

            &:hover,
            &:focus,
            &:active {
                background-color: transparent;

                .c-phone-icon {
                    background-image: $svg-google-phone;
                }

                span {
                    color: $color-primary-blue;
                }
            }
        }
    }

    .plot-sticky-nav__left {
        justify-content: flex-start;
    }
}
    
.hide-on-desktop {
    @include breakpoint(large) {
        display: none !important;
    }
}

.c-sticky-nav-icon {
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 1px 0;
    height: 24px;
    width: 24px;
    background-size: 24px 24px;
}

.c-phone-icon {
    background-image: $svg-google-phone;
}

.c-calendar-icon {
    background-image: $svg-google-calendar;
}

.c-info-icon {
    background-image: $svg-google-info;
}

.c-brochure-icon {
    background-image: $svg-google-brochure;
}
