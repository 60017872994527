.o-2-col-media-copy-block {
    padding: 0;

    @include breakpoint(large) {
        padding: 50px 0;
    }

    .o-layout-2col.grid-container {
        margin-left: 0;
        margin-right: 0;

        @include breakpoint(large) {
            margin-left: 5%;
            margin-right: 5%;
        }

        .c-layout__column {
            width: 100%;
            margin: 0;
            display: block;

            @include breakpoint(large) {
                width: 50%;
                margin-right: 0;
                margin-left: 0;
                display: flex;
            }
        }
    }

    .grid-x {
        flex-direction: row;
    }
}
.c-copy-block {
    padding-left: 5%;
    padding-right: 5%;

    @include breakpoint(large) {
        padding-left: 90px;
        padding-right: 0;
    }
}
.o-2-col-media-copy-block.media-on-right {
    .grid-x {
        flex-direction: row-reverse;
    }

    .c-copy-block {
        padding-left: 5%;
        padding-right: 5%;

        @include breakpoint(large) {
            padding-right: 90px;
            padding-left: 0;
        }
    }
}

