.select-box {
  display: flex;
  position: relative;

  .select-box__container,
  .select-box__container--small,
  .select-box__container--medium {
    margin: 6px;
    text-align: center;

    input[type="checkbox"],
    input[type="radio"] {
      opacity: 0;
      position: absolute;
      width: 0;
    }

    label {
      display: flex;
      flex-direction: row;
      justify-content: center;
      background: #f0f0f0;
      padding: 0 10px;
      font-size: 14px;
      transition: all 0.3s ease;
      height: 100%;
      width: 100%;

      .subtitle {
        font-weight: normal;
        color: #525252;
        font-size: 14px;
        padding-top: 3px;
      }
    }

    label:hover {
      transform: scale(1.03);
      background: #082D60;
      color: #fff;
      cursor: pointer;

      .subtitle {
        color: #fff;
      }
    }

    input[type="radio"]:checked ~ label,
    input[type="checkbox"]:checked ~ label {
        background: #082D60;
        color: #fff;

        .subtitle {
          color: #fff;
        }

        .activeIcon {
          display: block;
        }

        .inactiveIcon {
          display: none;
        }
    }

  }

  &--single {
    flex-direction: column;
    flex-wrap: wrap;

    .select-box__container,
    .select-box__container--small,
    .select-box__container--medium {
      width: auto;
      height: 66px;
      line-height: 66px;

      label {
        justify-content: center;
        padding: 0 10px;
      }

      img {
        width: 40px;
        min-height: 40px;
        margin: auto 20px auto 10px;
      }

      .activeIcon {
        display: none;
      }

      &:hover {
        .activeIcon {
          display: block;
        }
        .inactiveIcon {
          display: none;
        }
      }
    }

    .select-box__container--medium {
      height: 91px;
      width: calc(50% - 12px);

      label {
        flex-direction: column;
        line-height: initial;
        justify-content: center;
      }
    }
  }

  &--multi {
    flex-wrap: wrap;
    flex-direction: row;
  
    .select-box__container {
      width: calc(33% - 12px);
      height: 25vw;
      line-height: 25vw;

      label {
        font-size: 24px;
        font-weight: 600;
      }
    }
    
  }

  @include breakpoint(large) {

    &--single {
      flex-direction: row;
      justify-content: center;
      flex-wrap: nowrap;

      .select-box__container,
      .select-box__container--small,
      .select-box__container--medium {
        line-height: normal;

        img {
          width: 50px;
          min-height: 50px;
          margin: 0 auto 7px auto;
        }

        label {
          flex-direction: column;
          justify-content: center;
          padding: 0 10px;
          font-size: 14px;
        }
      }

      .select-box__container {
        width: 150px;
        height: 150px;
      }

      .select-box__container--small {
        width: 120px;
        height: 56px;
      }

      .select-box__container--medium {
        width: 120px;
        height: 91px;

        .subtitle {
          padding-top: 10px;
        }
      }
    }

    &--multi {
      flex-wrap: nowrap;
      justify-content: center;

      .select-box__container {
        width: 120px;
        height: 120px;
        line-height: 120px;

        label {
          font-size: 32px;
        }
      }
    }

  }
}

.labelled {
  text-align: left;
  position: relative;

  .select-box {
    padding: 6px 0 10px 0;
    flex-direction: row;
    justify-content: start;
  }

  @include breakpoint(large) {
    padding: 20px 0;
  }
}