﻿.o-accordion-copy-block {
    @include clearfix;
    @extend %u-section-separation;

    .c-layout__column {
        @include breakpoint(large) {
            @include xy-cell(6);
            @include xy-cell-offset(3);
        }
    }
}
