﻿.c-sidebar {

    &--collapsed {
        pointer-events: none;

        @include breakpoint(large) {
            top: 0;
            z-index: 9;

            .c-sidebar__content {
                padding: 0 !important;
                background-color: transparent !important;
                height: 80px !important;
                width: 22%;
            }
        }
    }
}
