﻿.c-tour-carousel {
    @extend %ten-column-carousel;
    @extend %carousel-iframe;
    z-index: 0;

    .c-carousel-slides__iframe-button{

        span {
            display: none;
        }

        @include breakpoint(large) {
            background-image: none;
            height: auto;
            width: 40%;
            color: #fff;
            font-size: $text-size-small;
            font-weight: $text-weight-light;
            
            span:first-child {
                display: block;
                width: 100%;
                margin-bottom: 15px;
                transform: translate3d(0, 0, 0);
            }
    
            span:last-child{         
                display: block;
                position: relative;
                width: 100%;  
                height: 40px;
                background-image: $svg-mouse-icon;
                background-repeat: no-repeat;
                background-position: center center;
                transform: translate3d(0, 0, 0);
                
                &::before, &::after {
                    content: '';
                    background-repeat: no-repeat;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    display: block;
                    position: absolute;
                }
                    
                &::before {
                    background-image: $svg-arrow-left-white;
                    background-position: calc(50% - 35px) center;
                }
    
                &::after {
                    background-image: $svg-arrow-right-white;
                    background-position: calc(50% + 35px) center;
                }
    
            }
        }  
    }

    .c-carousel-slides__iframe-content { 
        z-index: -1;

        &--visible {
            z-index: 0;
        }
        
    }

    .u-hidden {
        display: none;
    }
    
}