﻿.c-hero-video {
    width: 100vw;
    left: calc(-50vw + 50%);
    position: relative;
    font-size: 0;
    //max-height: 90vh;

    .o-video-container {
        max-height: 70vh;
        width: 100%;
        overflow: hidden;

        @include breakpoint(medium){
            max-height: 85vh;
        }

    }

    &::after {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.01);
    }

    video {
        width: 100%;

        &::-webkit-media-controls-panel {
            display: none!important;
            -webkit-appearance: none;
        }      
        
        &::-webkit-media-controls-play-button {
            display: none!important;
            -webkit-appearance: none;
        }
        
        &::-webkit-media-controls-start-playback-button {
            display: none!important;
            -webkit-appearance: none;
        }
    }
    // Styling for scroll prompt
    .c-scroll-prompt {
        position: absolute;
        left: 50%;
        margin-left: -30px;
        display: inline-block;

        @include squish-text;
        background-image: $svg-scroll-prompt;
        background-repeat: no-repeat;
        background-position: center top;
        height: 12px;
        width: $sizing-regular;

        @include breakpoint(small) {
            display: none;
        }

        @include breakpoint(medium) {
            display: block;
            bottom: 80px;
            animation-name: bounce;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            z-index: 1;
        }
    }
}