﻿///experience editor
.u-experience-editor {
    .c-offers {
    
        .c-offer-item--fullwidth {

            .grid-x.grid-margin-x > img.scEnabledChrome {
                margin: 0;
                @include xy-cell(4, $gutter-type: none);
                @include xy-cell-offset(1);
                height: 100%;
            }
        }
    }
       
}
