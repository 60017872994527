.development-card-block {
  margin: auto;
  padding: 0 20px 30px;
  max-width: 700px;
  
  @include breakpoint(large) {
    padding: 0 0 30px;
    margin: 0 5%;
    max-width: none;
  }

  @include breakpoint(xxlarge) {
    width: 90rem;
    margin: 0 auto;
  }

  &__title {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    color: #082d60;
    padding: 40px 0;
  }
}