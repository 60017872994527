
.c-form-item {
    label {
        color: #082d60;
        display: block;
        font: {
            size: $text-size-x-small;
            weight: $text-weight-light;
        }
        line-height: $text-line-height-xxxx-small;
        margin-bottom: 5px;    
        text-align: left;
    }
}