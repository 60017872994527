﻿.u-experience-editor {
    padding: 0;
}

.u-experience-editor-warning {
    padding: 1em;
    background-color: lightyellow;
    border: 3px dotted orange;

    h2 {
        line-height: 42px;
        font-size: 1rem;
        font-weight: 600;
        margin-top: 0;
    }

    p {
        margin-bottom: 1em;
    }

    em {
        font-style: italic;
    }
}
