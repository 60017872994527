﻿.form {
    margin-bottom: 50px;
}

.form__copy-wrap {
  padding-bottom: 20px;
}

.form__copy {
  margin-bottom: 15px;
  display: block;
}

.download-doc {
  margin: 7% 0 7% 0;
}

.form__select-wrap {
  position: relative;
  text-align: left;

  select {
    appearance: none;
    background: #fff;
    color: rgba(#082d60, 0.5);
    cursor: pointer;
    display: block;
    font: {
      size: $text-size-x-small;
      weight: $text-weight-light;
    }
    line-height: $text-line-height-xx-small;
    padding: 13px 10px;
    width: 100%;
  }

  &:after {
    background: {
      image: $svg-select;
      repeat: no-repeat;
      position: center center;
    }
    content: '';
    display: block;
    height: 10px;
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 25px;
    transform: translateY(-50%);
    width: 16px;
    z-index: 2;

        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
      display: none;
    }
  }
}

.form__select, .form__input {
  display: block;
  width: 100%;
  height: 50px;
  padding-top: 13px;
  padding-right: 10px;
  padding-bottom: 13px;
  padding-left: 10px;
  // color: $color-primary-blue;
  font-size: $text-size-xx-small;
  border: 1px solid $color-dark-grey;
  font-family: $primary-font;

  &:focus {
    outline: 0;
    border: 1px solid rgba($color-primary-blue, 0.5);
  }
}

.form__select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: $color-blue-dust;

  &:focus {
    color: $color-primary-blue;
  }

  &--chosen {
    color: $color-primary-blue;
  }
}

.form__input {
  -moz-appearance: textfield;

  @include placeholder {
    color: $color-blue-dust;
    font-weight: $text-weight-light;
  }

  &:focus {
    @include placeholder {
      color: $color-primary-blue;
      font-weight: $text-weight-regular;
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.valid ~ .form__input-icon {
    background-image: $svg-form-tick;
  }

  &.error ~ .form__input-icon {
    background-image: $svg-form-q-mark;
  }
}

.form__textarea {
  resize: vertical;
  height: 100px;
  text-align: left;
  padding: {
    top: 12px;
    bottom: 12px;
  }
}

.form__input-wrap {
  position: relative;
}

.form__input-icon {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  width: 50px;
  background-repeat: no-repeat;
  background-position: right 15px center;
}

.form__error-msg { 
  display: block;
  margin-bottom: 30px;
  color: $color-red;
  font-size: $text-size-xxxx-small;
  margin-top: 4px;
}

.form__gdpr-text {
  font-size: $text-size-xxx-small;
  color: $color-primary-blue;
  display: block;
  margin-bottom: 25px;
  font-weight: $text-weight-light;

  strong {
    font-weight: $text-weight-bold;
  }
}

.form__submit {
  display: block;
  height: 50px;
  width: 100%;
  margin-top: 20px;
  font-family: $primary-font;
  position: relative;

  &[disabled] {
    pointer-events: none;
    cursor: auto;
    }

    &:after {
        transform: rotate(10deg);
        height: 750%;
        top: -400% !important;
    }

    &:hover:after {
        top: -400% !important;
        transition-duration: 1s, 1s, 0.25s !important;
  }

  &--loading {
    &:before {
      content: '';
      position: absolute;
      top: 10px;
      right: 15px;
      height: 30px;
      width: 30px;
      background-image: $svg-loader;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 26px;
      animation: spin 1s linear infinite;
    }
  }
}

.form__skip {
    width: 100%;
    display: block;
}

.form__checkbox-wrap {
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px;
}

.form__checkbox {
  height: 16px;
  width: 16px;
  accent-color: $color-light-blue;
}

.form__label {
  padding-left: 13px;
  margin-right: 30px;
  color: $color-primary-blue;
  font-size: $text-size-xx-small;
  font-weight: $text-weight-light;
}

.form__policy-info {
  font-size: $text-size-xxx-small;
  color: $color-primary-blue;
  margin-top: 20px;
  font-weight: $text-weight-light;

  a {
    color: $color-purple;
    &:hover {
      color: lighten($color-purple, 10%);
    }
  }

  strong {
    font-weight: $text-weight-bold;
  }
}

.confirmation__button {
  span {
    margin-top: 40px;
  }
}

.confirmation__return {
  display: inline-block;
  font-weight: $text-weight-regular;
  margin-top: 50px;
  background-image: $svg-arrow-left;
  background-repeat: no-repeat;
  background-size: auto 16px;
  background-position: left center;
  padding-left: 20px;
  color: $color-primary-blue !important;
  &:hover {
    color: $color-purple !important;
  }
}

.form__subheading {
  text-transform: uppercase;
}

.ohhonpot {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.ohhonpot2 {
  position: absolute;
  top: -5000px;
  left: -5000px;
}
