﻿.c-offer-item {
    width: 100%;

    .c-offer-item__copy {
        background-color: $color-medium-grey;
        text-align: center;
        padding: 50px 40px;

        .c-offer-item__text-wrap {
            display: flex;
            flex-direction: column;

            p {
                flex-grow: 1;
            }
        }

        .c-button--purple {
            @extend %primary-button;
            height: 45px;
            line-height: 45px;
            display: block;
            max-width: 200px;
            margin: 0 auto;

            @include target-ie {
                width: 200px;
            }

            &:after {
                transform: rotate(7deg)
            }

            &:hover {
                &:after {
                    top: -20%;
                }
            }
        }
    }

    &:not(&--fullwidth) {
        display: flex;
        flex-direction: column;

        .c-offer-item__copy {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }

        .c-offer-item__text-wrap {
            flex-grow: 1;
        }

        .c-button {
            display: block;
            padding: 0 60px;
        }
    }

    @include target-ie {
        .c-offer-item__img {
            height: 100%;
        }
    }
}
