﻿/*import partials*/
@import 'developmentListings/development-listings';
@import 'developmentDetails/development-details';
@import 'developmentRibbonMenu/secondary-nav';
@import 'developmentHomes/development-homes';
@import 'amenities/local-area';
@import 'sitePlan/site-plan';
@import 'specification/accordion';
@import 'detailCarousel/detail-carousel';
@import 'community/community';
@import 'developmentForm/development-form';
@import 'stickyCTA/development-sticky-cta.scss';

body.using-mouse :focus {
    outline: none !important;
}

[data-show-direct-url="true"]:not(.visible-item) {
  visibility: hidden;
  height: 0px;
}