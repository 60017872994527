.o-image-text-block {
    background: $color-light-grey;

    &__item {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .c-image-text-block__content--copy {
        @extend %c-rich-text-block;
        padding: $sizing-small;
        flex-grow: 1;

        h3 {
            @extend %c-heading--regular;
        }
    }

    .c-image-text-block__content {
        text-align: center;
        overflow: hidden;
        position: relative;
        text-align: center;
    }

    .c-image-text-block__content--image {
        img {
            width: 100%;
            height: 100%;
            max-width: 100%;
            object-fit: cover;
        }
    }

    .c-image-text-block__content--image.u-compat-object-fit {
        background-size: cover;
        background-position: center center;

        img, picture {
            opacity: 0;
        }
    }

    .c-image-text-block__links {
        position:relative;

        @include breakpoint(medium) {
            bottom: 40px;
            position: absolute;
            left: 0;
            width: 100%;
        }
    }
}
