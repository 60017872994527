.cta-image-block {
    &-item {
        margin: 10px auto;
        width: 90%;

        @include breakpoint(xlarge) {
            margin: 20px auto;
            width: 75%;
        }
    }

    &-wrapper {
        display: flex;
        min-height: 300px;
        width: 100%;
        flex-direction: column;
        flex: 0 0 auto;
        min-height: 0;
        min-width: 0;
        height: 100%;

        @include breakpoint(large) {
            flex-direction: row;
            height: 302px;

            div {
                position: relative;
            }
        }

        &--open {
            height: max-content;
        }
    }

    &-item__image, &-item__copy {
        overflow: hidden;
        position: relative;
        display: flex;

        div {
            width: 100%;
        }

        &--open {
            overflow: auto;
        }
    }

    &-item__image {
        padding-top: 30%;

        @include breakpoint(large) {
            padding: 0;
            flex: 1 0 49%;
            max-width: 49%;
        }

        &--open {
            max-height: 302px;
        }

        img {
            @include breakpoint(large) {
                display: block;
                width: 100%;
                height: 100%;
                max-width: 100%;
                object-fit: cover;
            }
        }
    }

    &-item__image.u-compat-object-fit {
        background-size: cover;
        background-position: center center;

        img, picture {
            opacity: 0;
        }
    }

    &-item__copy {
        background-color: $color-light-grey;
        padding: 25px 20px;
        order: 1;

        @extend %c-rich-text-block;
        @extend %c-article-block__content;

        @include breakpoint(large) {
            order: 0;
            padding: 40px;
            flex: 1 0 51%;
            max-width: 51%;
        }

        div {
            .c-button {
                @extend %secondary-button;
                display: none;
                margin-top: 30px;

                &--visible {
                    display: block;
                }
            }

            .cta-image-block-item__copy--overflown {
                overflow: hidden;

                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 40px;
                    background: linear-gradient(0deg, rgba(240,240,240, 1), rgba(240,240,240, 0));
                }
            }
        }

        .cta-image-block-item__header {
            @extend %c-heading--large;
            text-align: center;
            line-height: normal;
            font-size: $text-size-x-small;
            margin-top: 11px;

            @include breakpoint(large) {
                text-align: left;
                margin-top: 0;
                font-size: $text-size-x-large;
            }
        }

        .cta-image-block-item__content {
            text-align: center;

            @include breakpoint(large) {
                text-align: left;
                height: calc(100% - 120px);
            }
        }

        .cta-image-block-item__links {
            text-align: center;
            position: relative;
            margin-top: 20px;

            @include breakpoint(large) {
                margin-top: 0px;
                text-align: left;
                position: absolute;
                bottom: 0;
            }

            a {
                display: block;
                margin-bottom: 30px;
                width: 250px;

                @include breakpoint(large) {
                    margin-right: 20px;
                    display: inline-block;
                    max-width: 200px;
                    width: 45%;
                    margin-bottom: 0;
                }

                &:last-child {
                    margin-bottom: 0;

                    @include breakpoint(large) {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
