﻿.c-nav {
    .c-subnav-promo {

        .c-social-block__copy {
            display: inline-block;
            vertical-align: middle;
            margin-right: 35px;
            margin-bottom: 20px;
            @include body-fontsizes;
        }

        .c-social-block__icon-wrapper {
            display: inline-block;
            font-size: 0 !important;
        }

        .c-social__icon {
            height: 50px;
            width: 50px;
            display: inline-block;
            background-color: $color-primary-blue;
            vertical-align: middle;
            margin-right: 20px;
            margin-bottom: 20px;
            font-size: 0 !important;
            background-position: center;
            background-repeat: no-repeat;
            border: 2px solid $color-primary-blue;

            &:last-of-type {
                margin-right: 0px;
            }

            &--facebook {
                background-image: $svg-icon-facebook;
            }

            &--youtube {
                background-image: $svg-icon-youtube;
            }

            &--google {
                background-image: $svg-icon-googleplus;
            }

            &--linkedin {
                background-image: $svg-icon-linkedin;
            }

            &--instagram {
                background-image: $svg-icon-instagram;
            }
        }
    }
}
