.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #cdcdcd;
  text-align: center;
  padding: 24px 0;
  margin: 0 20px;

  .step {

    &__link {
      font-size: 14px;
      color: #8a8a8a;
      padding: 5px 12px;
      transition: all 0.5s ease;
      cursor: default;
    }

    &__link--active {
      color: #082d60;
    }

    &__link--clickable:hover {
      cursor: pointer;
      color: #082d60;
    }

    &__separator {
      width: 1px;
      height: 14px;
      border-left: 1px solid #cdcdcd;
      margin: 5px auto;
    }
  }

  @include breakpoint(large) {
    flex-direction: row;
    line-height: 76px;
    height: 76px;
    padding: 0;
    border-top: 1px solid #cdcdcd;
    width: 100%;
    margin: 0;

    .step {
      display: inline-flex;

      &__link {
        padding: 0px 12px;
        font-size: 16px;

        &--active {
          font-weight: 600;
        }
      }

      &__separator {
        width: 58px;
        height: 4px;
        border-bottom: 1px solid #cdcdcd;
        border-left: none;
        margin: auto;
      }
    }
  }
}

.progress-step {
  height: 0;
  opacity: 0;
  overflow: hidden;

   &--active {
     height: auto;
     opacity: 1;
    transition: opacity 0.2s ease;
   }
}