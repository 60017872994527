﻿.o-layout-8col {

    &--bordered {
        @extend %u-section-separation--border;
    }

    .c-layout__column {
        @include xy-cell(12);

        @include breakpoint(large) {
            margin: 0;

            @include xy-cell(8,  $gutter-type: 'padding');
            float: left;
        }

        &--center {
            @include breakpoint(large) {
                @include xy-cell-offset(2);
            }
        }
    }

}
