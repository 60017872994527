.o-floorplans {
    @include xy-cell(10);
    @include xy-cell-offset(1);

    @include breakpoint(large) {
        @include xy-cell(9);
    }

    &__header {
        width: 100%;
    }

    &__divider {
        @include xy-cell(10);
        @include xy-cell-offset(1);

        @include breakpoint(large) {
            @include xy-cell(9);
        }
    } 

    &__floorplan {
        &__media,
        &__body {
          margin-left: calc(8.33333% + .3125rem);
          margin-right: .625rem;
          width: calc(83.33333% - 1.25rem);

            @include breakpoint(large) {
              width: 33.33333%;
                
            }
        }   
    }
}