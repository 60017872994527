﻿.o-accordion-copy-block {
    @extend %c-rich-text-block;
    @extend %c-promotion-block__content;

    h1.c-accordion-block__header,
    h2.c-accordion-block__header {
        @extend %c-heading--large;
        font-weight: $text-weight-light;
    }

    h3.c-accordion-block__header {
        @extend %c-heading--medium;
        font-weight: $text-weight-regular;
    }

    h4.c-accordion-block__header,
    h5.c-accordion-block__header,
    h6.c-accordion-block__header {
        @extend %c-heading--small;
        font-weight: $text-weight-light;
    }

    p {
        @extend %c-bodytext--regular;
        margin-bottom: 3rem;

        @include breakpoint(large) {
            margin-top: 1.5rem;
            margin-bottom: 2rem;
        }
    }

    h3 {
        @extend %c-heading--regular;
        font-weight: $text-weight-light;

        @include breakpoint(large) {
            font-weight: $text-weight-regular;
        }
    }

    .o-accordions__header {
        @extend %c-heading--large;
        font-weight: $text-weight-light;
        margin-top: 50px;

        @include breakpoint(large) {
            font-weight: $text-weight-light;
            margin-top: 70px;
        }
    }

    .o-accordions__accordion {
        position: relative;
        width: 100%;
        overflow: hidden;
        text-align: left;
        border-top: 1px solid rgba(8, 45, 96, 0.2); // $color-primary-blue 0.2 opacity
        background-color: white;
        -webkit-transition: background-color .4s ease-in-out;
        -o-transition: background-color .4s ease-in-out;
        transition: background-color .4s ease-in-out;

        &:first-of-type {
            margin-top: 2.25rem;
        }

        &:last-of-type {
            border-bottom: 1px solid rgba(8, 45, 96, 0.2); // $color-primary-blue 0.2 opacity
        }

        .o-accordions__accordion-content {
            max-height: 0;
            overflow: hidden;
            padding: 0 1.125rem;
            -webkit-transition: max-height .4s ease-in-out;
            -o-transition: max-height .4s ease-in-out;
            transition: max-height .4s ease-in-out;

            @include breakpoint(large) {
                padding-right: 3rem;
            }

            div {
                text-align: left;
                margin-top: 0.75rem;
                margin-bottom: 2rem;

                @include breakpoint(large) {
                    margin-top: 1.5rem;
                }
            }
        }

        .o-accordions__accordion-title {
            position: relative;
            display: block;
            cursor: pointer;
            @extend %c-heading--regular;
            padding: 1.25rem 3rem 1.25rem 1rem;
            margin-bottom: 0;
            font-weight: $text-weight-light;

            @include breakpoint(large) {
                font-weight: $text-weight-regular;
            }

            &:hover {
                @include breakpoint(large) {
                    background-color: $color-medium-grey;
                }
            }

            &::after {
                content: ' ';
                background: $svg-arrow-down-blue no-repeat center;
                position: absolute;
                right: 0;
                top: 0;
                display: block;
                width: 3em;
                height: 3em;
                line-height: 3;
                text-align: center;
                -webkit-transition: all .4s ease-in-out;
                -o-transition: all .4s ease-in-out;
                transition: all .4s ease-in-out;
            }
        }

        &--open {


            .o-accordions__accordion-content {
                max-height: 40em;
            }

            .o-accordions__accordion-title {
                background-color: $color-medium-grey;

                &::after {
                    transform: rotate(180deg);
                }
            }
        }
    }
}
