.c-local-area-key {
    background: #fff;
    margin-bottom: 40px; // correct this value
}

.c-local-area-key__list {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    font-size: 0;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.c-local-area-key__item {
    display: block;
    margin: 0 auto;
    min-width: 33.3333%;
    padding: 5px;
    padding-bottom: 5px;
    width: 100%;

    .c-local-area-key__category-button {
      background: #f0f0f0;
      width: 100%;
      min-width:260px;
      height: 66px;
      line-height: 66px;
      color: #082d60;
      text-align: left;
      font-family: inherit;
      font-size: 16px;
      display: inline-flex;
      transition: background 0.3s ease;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 70px);
      }

      &__icon {
        width: 22px;
        height: 22px;
        margin: auto 21px auto 14px;
        background-repeat: no-repeat;
        background-size: contain;
      }

      &.reveal {
        background: #082d60;
        color: #fff;
        margin-bottom: 18px;
      }

      &:hover {
        cursor: pointer;
      }

      @include breakpoint(large) {

        &.reveal {
          margin-bottom: 0px;

          span {
            max-width: calc(100% - 98px);
          }
        }
      }
    }
}

.c-local-area-key__button {
    background: transparent;
    border: none;
    color: #322e2a;
    font: {
        size: $text-size-xx-small;
        weight: $text-weight-light;
    }
    line-height: $text-line-height-xxxx-small;
    margin: 0;
    padding: 0;

    &::before {
        background: #006;
        content: '';
        display: inline-block;
        height: 40px;
        margin-right: 10px;
        vertical-align: middle;
        width: 40px;
        background:  {
            position: center center;
            repeat: no-repeat;
            size: cover;
        }        
    }
}

.c-local-area-key__category-button__icon {
  &.localinfo {
    background-image: $svg-info-blue;
  }

  &.healthcare {
    background-image: $svg-healthcare-blue;
  }

  &.education {
    background-image: $svg-education-blue;
  }

  &.leisure {
    background-image: $svg-leisure-blue;
  }

  &.rail {
    background-image: $svg-rail-blue;
  }

  &.restaurantsandbars {
    background-image: $svg-restaurants-and-bar-blue;
  }

  &.entertainment {
    background-image: $svg-entertainment-blue;
  }

  &.shops {
    background-image: $svg-shops-blue;
  }

  &.airport {
    background-image: $svg-airport-blue;
  }
}

.reveal .c-local-area-key__category-button__icon { 
  &.localinfo {
    background-image: $svg-info-white;
  }

  &.healthcare {
    background-image: $svg-healthcare-white;
  }

  &.education {
    background-image: $svg-education-white;
  }

  &.leisure {
    background-image: $svg-leisure-white;
  }

  &.rail {
    background-image: $svg-rail-white;
  }

  &.restaurantsandbars {
    background-image: $svg-restaurants-and-bar-white;
  }

  &.entertainment {
    background-image: $svg-entertainment-white;
  }

  &.shops {
    background-image: $svg-shops-white;
  }


  &.airport {
    background-image: $svg-airport-white;
  }
}
