.c-range {
    margin-top: 20px;
    @include clearfix;

    label {
        color: #082d60;
        display: block;
        font: {
            size: $text-size-xx-small;
            weight: $text-weight-light;
        }
        line-height: $text-line-height-xxxx-small;
        margin-bottom: 5px;
        text-align: left;
    }

    .c-dropdown {
        float: left;
        position: relative;
        text-align: left;
        width: calc(50% - 10px);

        &:last-child {
            float: right;
        }
    }
}
