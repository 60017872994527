.o-development-listings-filters.o-filters {
    @extend .o-development-homes-filters;
    overflow: auto;
    background-color: $color-primary-blue;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 200;
    display: none !important;
    padding: 23px 20px;

    &.o-development-listings-filters--visible {
        display: block !important;
    }

    @include breakpoint(large) {
        position: static;
        z-index: 1;
        background-color: transparent;
        padding: 10px 20px;
        display: flex !important;

        @media screen and (max-height: 690px) {
            display: block !important;
        }
    }

    h3 {
        color: #fff;
        text-transform: uppercase;
        margin-bottom: 10px;
        font-size: $text-size-xx-large;
        font-weight: $text-weight-light;
        padding-right: 30px;

        @include breakpoint(large) {
            color: $color-primary-blue;
            padding-right: 0;
            font-weight: $text-weight-regular;
            font-size: $text-size-large;
        }

        @include breakpoint(xlarge) {
            margin-bottom: 15px;
        }
    }

    .c-map-pin-legend {
        display: none;

        @include breakpoint(large) {
            display: block;
            width: 90%;
            margin: 0 auto 15px auto;
            padding-bottom: 20px;
            border-bottom: 1px solid $color-darker-grey;
            display: flex;
            justify-content: space-between;
            color: $color-darker-grey;
        }

        &__location, &__current, &__soon {
            padding: 20px 5px 0 5px;
            font-size: .70rem;
            line-height: 1rem;
            background-repeat: no-repeat;
            background-position: top center;
        }

        &__location {
            background-image: $svg-searchLocation;
        }

        &__current {
            background-image: $svg-currentDev;
        }

        &__soon {
            background-image: $svg-coomingSoon;
        }
    }

    .c-form-item {
        margin: 20px 0 20px;

        &:nth-child(4) {
            margin-top: 30px;

            @include breakpoint(medium) {
                margin-top: 38px;
            }
        }

        @include breakpoint(medium) {
            margin: 28px 0 24px;
        }

        @include breakpoint(large) {
            margin: 10px 0 0;

            &:nth-child(4) {
                margin-top: 10px;
            }
        }

        @include breakpoint(xlarge) {
            margin-top: 20px;
        }

        label {
            color: #fff;
            font-size: $text-size-xx-small;
            opacity: 0.6;

            @include breakpoint(medium) {
                margin-bottom: 2px;
            }

            @include breakpoint(large) {
                margin-bottom: 5px;
                color: $color-primary-blue;
                // font-size: $text-size-xxx-small;
                opacity: 1;
            }

            @include breakpoint(xlarge) {
                font-size: $text-size-x-small;
            }
        }
    }

    .c-text-input {
        input {
            background-color: $color-primary-blue;
            color: #fff;
            border-bottom: 1px solid #fff;
            padding: 10px 0;
            font-size: $text-size-small;

            @include placeholder {
                color: white;
                font-size: $text-size-small;
            }

            @include breakpoint(large) {
                background-color: #fff;
                color: $color-primary-blue;
                border: none;
                padding: 13px 10px;
                font-size: $text-size-x-small;

                @include placeholder {
                    color: rgba($color-primary-blue, 0.5);
                    font-size: $text-size-x-small;
                }
            }

            @include breakpoint(xlarge) {
                font-size: $text-size-x-small;
            }
        }

        &--search {
            @include breakpoint(large down) {
                &::after {
                    background-image: $svg-icon-search;
                    opacity: 0.5;
                    background-size: 20px 20px;
                    background-position: right bottom 12px;
                }
            }
        }
    }

    .c-range {
        label {
            font-size: $text-size-xx-small !important;
            margin-bottom: 2px !important;

            @include breakpoint(large) {
                margin-bottom: 5px !important;
            }

            @include breakpoint(xlarge) {
                font-size: $text-size-x-small !important;
            }
        }

        .c-dropdown {
            // margin: 0;
            select {
                font-size: $text-size-small;
                padding: 10px 0;

                @include breakpoint(large) {
                    font-size: $text-size-x-small;
                    padding: 13px 10px;
                }
            }
        }
    }

    .c-dropdown {
        position: relative;

        &::after {
            content: ' ';
            position: absolute;
            top: 22px;
            right: 10px;
            width: 16px;
            height: 10px;
            background-image: $svg-arrow-down;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100% 100%;

            @include breakpoint(large) {
                background-image: $svg-arrow-down-blue;
            }
        }

        select {
            background-color: $color-primary-blue;
            color: #fff;
            border-bottom: 1px solid #fff;
            font-size: $text-size-small;
            padding: 10px 0;

            @include breakpoint(large) {
                background-color: #fff;
                color: $color-primary-blue;
                border: none;
                padding: 13px 10px;
                color: rgba($color-primary-blue, 0.5);
                font-size: $text-size-x-small;
            }
        }
    }

    .development-listings__link {
        color: #fff;

        font: {
            size: $text-size-xx-small;
            weight: $text-weight-light;
        }

        margin-top: 10px;
        text-decoration: underline;
        padding: 0;

        @include breakpoint(large) {
            color: $color-purple;
            margin-top: 0;
        }

        @include breakpoint(xlarge) {
            font-size: $text-size-x-small;
        }

        &::before {
            content: '';
            height: 20px;
            width: 20px;

            background: {
                image: $svg-icon-location-white;
                size: auto 100%;
                repeat: no-repeat;
            }

            display: inline-block;
            vertical-align: middle;

            @include breakpoint(large) {
                background: {
                    image: $svg-icon-location;
                }

                &:hover {
                    color: $color-primary-blue;

                    &::before {
                        background: {
                            image: $svg-icon-location-transparent-blue;
                        }
                    }
                }
            }
        }

        &--hidden {
            display: none;
        }
    }

    .primary-button {
        padding: 14px 10px;
        font-size: $text-size-x-small;
        display: block;
        width: 100%;
        margin-top: 50px;

        @include breakpoint(large) {
            font-size: $text-size-xx-small;
            margin-top: 20px;
        }

        @include breakpoint(xlarge) {
            font-size: $text-size-x-small;
            margin-top: 35px;
        }

        &:after {
            transform: rotate(10deg);
            height: 750%;
            top: -400% !important;
        }

        &--disabled {
            pointer-events: none;
            opacity: 0.5;
        }
    }

    .o-development-homes-filters__header-mobile__close {
        top: 26px !important;
        right: 20px !important;

        @include breakpoint(large) {
            display: none;
        }
    }
}
