﻿.c-overview {
    @include grid-container-custom;
    @include xy-grid;
    @extend %u-section-separation;

    &::before, &::after {
        width: 100%;
        content: '';
        display: table;
        margin: 0 0 50px;
    }

    .c-overview__features {
        margin-bottom: 16px;

        @include breakpoint(msmall) {
            margin-bottom: 32px;
        }
    }

    .c-overview__content-container {
        @extend %three-quarter-content;
        @extend %c-rich-text-block;
        @extend %c-promotion-block__content--copy;
        padding: 0;
        color: $color-primary-blue;

        h1 {
            font-weight: 300;
        }

        .c-button {
            @extend %secondary-button;
            display: none;
            margin-top: 30px;

            &--visible {
                display: block;
            }
        }

        @include breakpoint(large) {
            padding-right: 100px;
        }
    }

    .c-overview__contact-details {
        display: block;
        margin-bottom: 32px;
        
        @include breakpoint(large) {
            display: none;
        }

        address {
            line-height: 18px;
            font-size: $text-size-x-small;
            display: block;
            font-weight: 400;
            margin-bottom: 8px;
            
            strong {
                display: block;
                margin: 0;
            }
        }
        
        .bedrooms {
            display: block;
            color: #082d60;
            margin-bottom: 8px;
        }
        
        .hours {
            display: block;
            text-transform: uppercase;
            color: #525252;
            font-family: Lato;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 8px;
            opacity: 0.7;
        }
        
        .links {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;

            a {
                &:first-child {
                    border-bottom: 1px solid #842752;
                    text-transform: uppercase;
                }
                
                &.whatthreewords {
                    border-bottom: 1px solid #525252;
                    color: #525252;

                    &::before {
                        content: '///';
                        padding-right: 6px;
                    }
                }

                &:hover {
                    cursor: pointer;
                    text-decoration: none;
                }
            }
        }
    }

    .c-overview__text-container {
        max-height: 350px;
        overflow: hidden;
        position: relative;
        line-height: $text-line-height-small;

        h1 {
            line-height: $text-line-height-xxx-large;
            font-size: $text-size-xxx-large;
            font-weight: 600;
        }

        h2 {
            line-height: $text-line-height-small;
            font-size: $text-size-small;
        }

        h3 {
            line-height: $text-line-height-small;
            font-size: $text-size-regular;
        }

        p {
            line-height: $text-line-height-x-small;
            font-size: $text-size-x-small;
        }

        @include breakpoint(lsmall) {
            max-height: 400px;
        }


        @include breakpoint(medium) {
            max-height: 400px;
        }

        &--overflown {
            &::after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 40px;
                background: linear-gradient(0deg, rgba(255,255,255, 1), rgba(255,255,255, 0));
            }
        }


        &--open {
            max-height: none;
        }

        ul {
            li {
                margin-bottom: 14px;
            }
        }
    }

    .c-overview__info-blocks {
      display: flex;
      flex-direction: column;
      padding-bottom: 10px;

      @include breakpoint(large) {
        flex-direction: row;
        padding-bottom: 20px;
        flex-wrap: wrap;

        &.two-col .info-block {
            max-width: calc(50% - 20px);
        }

      }
    }

    .c-overview__coming-soon {
      color: $color-purple;
      font-size: 16px;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      margin-top: -8px;

      &__border {
        border-bottom: 1px solid #969696;
        width: 56px;
        margin-top: 17px;
        margin-bottom: 15px;
      }

      @include breakpoint(large) {
        font-size: 20px;

        &__border {
          border-bottom: 1px solid #969696;
          width: 92px;
          margin-top: 30px;
        }
      }
    }
}
