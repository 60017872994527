.nav-button,
.nav-button--secondary {
  height: 40px;
  padding: 10px 40px;
  color: #fff;
  background: #0c2c64;
  font-family: inherit;
  width: 100%;
  margin: 5px 0;

  &.disabled {
    opacity: 0.6;
    cursor: default;
  }
}

.nav-button--secondary {
  background: #fff;
  color: #0c2c64;
  border: 2px solid #0c2c64;
}

@include breakpoint(large) {
  .nav-button,
  .nav-button--secondary {
    width: auto;
    margin:  0 10px 0 0;
  }
}