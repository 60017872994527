.o-secondary-hero-carousel {

    @extend %hero-carousel;
    width: 100vw;
    left: calc(-50vw + 50%);
    position: relative;
    margin-bottom: 60px;

    &.tall-hero {
        max-height: calc(100vh - 450px);
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
        left: 0;

        @include breakpoint(medium) {
            padding-left: 0;
            padding-right: 0;
        }

        .inner {
            height: 100%;
            overflow: hidden;
            max-height: calc(100vh - 450px);
        }

        .c-carousel-slides {
            max-height: calc(100vh - 100px);
            overflow: hidden;
            width: 100%;
            height: 100%;
        }
    }
    
    &__header {

        h2 {
            @extend %c-heading--large;
            font-weight: 400;
            text-align: center;
        }
    }
}
