.o-2-col-media-copy-block {
   width: 100vw;
   left: calc(-50vw + 50%);
   position: relative;

   .grid-container {
       margin-left: 0 !important;
       margin-right: 0 !important;
   }

    &.bg-grey {
        background-color: $color-light-grey;
    }

    .o-layout-2col.grid-container {

        .c-layout__column {
           
            img {
                width: 100%;
                height: 100%;
                max-width: 100%;
                -o-object-fit: cover;
                object-fit: cover;
            }
        }
    }
}
.c-copy-block {
    @extend %c-rich-text-block;
    @extend %c-article-block__content;

    &.centered {
        @include breakpoint(large) {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .c-copy-block__overview {
        @extend %c-heading--big;
        text-align: center;
        line-height: normal;
        margin-top: 24px;

        @include breakpoint(large) {
            text-align: left;
            margin-top: 0px;
        }
    }

    .c-copy-block__title {
        @extend %c-heading--large;
        text-align: center;
        line-height: normal;
        font-size: $text-size-x-small;
        margin-top: 11px;

        @include breakpoint(large) {
            text-align: left;
            margin-top: 24px;
            font-size: $text-size-xxxx-large;
        }
    }

    .c-copy-block__links {
        margin-top: 20px;
        text-align: center;

        @include breakpoint(large) {
            margin-top: 40px;
            text-align: left;
        }

        a {
            display: block;
            margin-bottom: 30px;
            width: 250px;

            @include breakpoint(large) {
                margin-right: 20px;
                display: inline-block;
            }
        }
    }

    .c-copy-block__content {
        text-align: center;

        @include breakpoint(large) {
            text-align: left;
        }
    }
}
.c-media-block {
    width: 100%;

    @extend %video-iframe;
    .c-video__iframe-container{
        position:static;
    }
}