﻿.c-header-search {
    cursor: pointer;
    color: rgba(#ffffff, 0.4);

    @include breakpoint(small) {
        background: none;
        width: 100%;
        overflow-y: visible;        
    }

    @include breakpoint(msmall) {
        padding: 0 11%;
        min-width: 80px;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        overflow: initial;
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        left: 0;
        height: 100%;
    }


    @include breakpoint(500px) {
        padding: 0 9%;
    }

    @include breakpoint(lsmall) {
        padding: 0 9% 0 0;
    }

    @include breakpoint(medium) {
        position: static;
        width: 37px;
        min-width: initial;
        padding: 0;
        background-repeat: no-repeat;
        background-position: center bottom -10px;
        transition: background-position 0.3s, color 0.3s;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAAAXNSR0IArs4c6QAAAF1JREFUKBWtzFsOwCAIRFFcBOu1XS+LaLWhJj5GaJVkws/NIXKciBx5jtROFLvSzzPRMCMViE1zMjOEIQiw14boEDSwKdqBTgyiFfgRG6IF/Il16AMuYhUaNmEFvQGhWjvOgYOMlAAAAABJRU5ErkJggg==");
        line-height: 80px;
        height: 80px;
    }

    @include breakpoint(large) {
        width: auto;
    }
}

.c-header-search--active {
    color: #ffffff;
    background-position: center bottom 0px;
}


.c-header-search__link {
    height: 40px;
    line-height: 40px;
    padding: 0;
    cursor: pointer;
    @include search-icon;

    @include breakpoint(small) {
        background-position: right center;
        width: 28px;
        margin: 0 auto;
        display: none;
        position: absolute;
        right: 100px;
        top: 0;
    }

    @include breakpoint(msmall) {
        display: block;
        height: 80px;
    }

    @include breakpoint(lsmall) {
        width: calc(100% - 80px);

        span {
            margin-top: 40px;
            transform: translateY(-50%);
            right: 0;
            position: absolute;
        }
    }


    @include breakpoint(medium) {
        background-position: right 7px center;
        width: 35px;
        margin: initial;
        position: static;
        height: 80px;

        span {
            position: static;
            transform: none;
            margin-top: 0;
            height: 100%;
        }
    }

    @include breakpoint(large) {
        background-position: 2px center;
        width: 100%;
        height: auto;
    }
}


.c-header-search__link-text {
    background: $color-primary-blue;
    height: 40px;
    color: inherit;
    font-family: $primary-font;
    margin-left: 30px;
    text-align: left;

    @include body-fontsizes;

    @include map-to-sizes(padding-left, (xxlarge: 12px, xlarge: 12px, large: 7px, medium: 5px ));


    @include breakpoint(lsmall down) {
        display: none;
        font-size: $text-size-x-small !important;
    }

    @include breakpoint(lsmall) {
        margin-left: 0;
        margin-right: 40px;
        display: block;
    }

    @include breakpoint(medium) {
        display: none;
        margin-right: 0;
    }

    @include breakpoint(large) {
        display: block;
        margin-left: 30px;
    }
}


.o-header-search__container {
    @include subnav-container;

    @include breakpoint(small) {
        top: 0;
        bottom: 0;
        padding: 0;
        background-color: $color-medium-grey;
        display: block;

        > .grid-container {
            margin: 0;
        }
    }

    @include breakpoint(msmall) {
        display: none;
    }

    @include breakpoint(medium) {

        @include subnav-container;

        > .grid-container {
            margin: 0 5%;
        }

    }
}

.o-header-search__container--active {
    display: block;

    @include breakpoint(msmall) {
       overflow-y: auto;
       overflow-x: hidden;
       height: calc(100vh - 80px);

       > .grid-container {
       //    overflow-x: hidden;
           overflow-y: visible;
       }

    }

    @include breakpoint(medium) {
        z-index: 1;
        overflow: visible;
        height: auto;
    }
}


//Styles for search form
.o-header-search__form-container {
    position: relative;

    .c-search__form {
        height: 60px;
        font-size: 0;

        @include breakpoint(small) {
            padding: 20px 3%;
            height: 100px;
            border-bottom: $mobile-menuitem-border;
        }

        @include breakpoint(msmall) {
            padding: 30px 5%;
            height: 120px;
        }

        @include breakpoint(medium) {
            padding: 0;
            height: 60px;
            border-bottom: none;
        }
    }

    .c-search__input {
        height: 100%;
        width: calc(100% - 60px);
        border: none;
        vertical-align: top;
        font-family: $primary-font;
        font-weight: 300;
        color: $color-primary-blue;
        padding: 0px 15px;

        @include body-fontsizes;

        @include placeholder {
            color: rgba($color-primary-blue, 0.5);
        }

        @include breakpoint(msmall down) {
            padding: 0px 5px;
        }

        @include breakpoint(lsmall down) {
            width: calc(100% - 60px);
            font-size: $text-size-xx-small !important;
            padding: 0px 7px;
        }

        @include breakpoint(420px) {
            font-size: $text-size-x-small !important;
        }

        @include breakpoint(500px) {
            width: calc(100% - 100px);
            font-size: $text-size-small !important;
        }

        @include breakpoint(medium) {
            width: calc(100% - 60px);
            font-size: rem(17px) !important;
        }

        @include breakpoint(xlarge) {
            font-size: $text-size-small !important;
        }
    }

    .c-search__button {
        height: 60px;
        width: 60px;
        vertical-align: top;
        @include search-icon;
        background-position: center;
        @extend %primary-button;
        
        &[disabled] {
            opacity: 1;
            background-color: $color-purple;
        }

        @include breakpoint(lsmall down) {
            width: 60px;
        }

        @include breakpoint(500px) {
            width: 100px;
        }

        @include breakpoint(medium) {
            width: 60px;
        }
    }

    .o-search__link-container {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;

        @include breakpoint(lsmall down) {
            margin-top: 0;
        }
    }



    .c-search__by-location {
        @include search-link;
        
        @include breakpoint(small) {
            background-image: $svg-icon-location, $svg-icon-mobile-menu-arrow;
        }

        @include breakpoint(medium) {
            padding-right: 10px;
            padding-left: 30px;
            background-image: $svg-icon-location;
        }
    }


    .c-search__list-all {
        @include search-link;

        @include breakpoint(small) {
            background-image: $svg-icon-list, $svg-icon-mobile-menu-arrow;
        }

        @include breakpoint(medium) {
            padding-left: 35px;
            background-image: $svg-house-red;
            background-size: 24px;
        }
    }

    .c-search__autocomplete {
        // @include grid-container-custom;
        padding: 0;
        position: absolute;
        text-align: left;
        display: none;

        &.u-visible{
            display: block;
            overflow-y: scroll;
            max-height: 600px;
            @include breakpoint(small)
            {
              max-height: 400px;
            }
        }

        @include breakpoint(small) {
            top: 60px + 20px;
            right: 0;
            left: 0;
            padding: 0 3%;
            z-index: 2;
            max-height: 400px;
            overflow: auto;
        }

        @include breakpoint(msmall) {
            top: 60px + 30px;
            padding: 0 5%;
            max-height: 450px;
        }

        @include breakpoint(medium) {
            right: 0;
            left: 0;
            top: 60px;
            padding: 0;
            max-height: initial;
        }

        .c-search__places {

            li {
                @extend %search-result;
                background-image: $svg-icon-location;
                background-position: 24px center;
            }

            @include breakpoint(medium) {
            }
        }

        .c-search__developments {

            li {
                @extend %search-result;
                background-image: $svg-house-red;
                background-position: 20px center;
                background-size: 24px;
            }

            @include breakpoint(medium) {
            }
        }
    }
}