.o-media-block {
    margin-left: 0;
    margin-right: 0;
    padding: 20px 0;

    @include breakpoint(medium) {
        margin-left: 5%;
        margin-right: 5%;
        padding: 20px 0;
    }

    .c-layout__column {
        @include breakpoint(large) {
            @include xy-cell(6);
            @include xy-cell-offset(3);
        }
    }

    &__item {
        position: relative;
        @extend %video-iframe;

        img {
            width: 100%;

            &.object-fit {
                -o-object-fit: cover;
                object-fit: cover;
            }
        }

        .c-video__iframe-container {
            .c-video__iframe-thumbnail {
                img {
                    min-height: auto;
                }
            }
        }
    }

    &.fullwidth {
        margin-left: 0;
        margin-right: 0;
        padding: 0;

        .c-layout__column {
            width: 100%;
            margin: 0;

            picture {
                display: block;
                width: 100%;
                height: auto;

                img {
                    display: block;
                }
            }
        }
    }
}
