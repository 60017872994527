.o-development-homes {
    @include clearfix;
    @extend %u-section-separation--border;

    .o-development-homes__cards {
        position: relative;
        margin-top: 35px;
        padding: 20px 0 0;
    }

    .c-layout__column {
        width: 100%;

        @include breakpoint(large) {
            @include xy-cell(9, $gutter-type: none);
        }
    }

    &__card {
        width: 100%;

        @include breakpoint(large) {
            @include xy-cell(4);
        }
        justify-content: space-between;
        flex-direction: column;
        display: flex;
    }

    &--hidden {
        display: none;
    }
}