.o-departments-listing__results-header {
    @extend %c-rich-text-block;
    @extend %c-article-block__content;

   margin-bottom:0;
   & *{
       margin-bottom:0;
   }
    .c-column-layout__title {
        @extend %c-heading--large;
        font-weight: $text-weight-light;
    }

    p {
        @extend %c-bodytext--regular;
    }
}
