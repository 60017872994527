// May need removing as new one is used
.o-site-plan {
    padding: 40px 0;
    
    @include breakpoint(medium) {
        max-width: 55vw;
    }

    @include clearfix;

    @include breakpoint(medium) {
        padding: 0 5%;
    }

    @include breakpoint(medium) {
        padding: 0;
    }

    .c-map-key,
    .o-zoom-controls,
    .c-range {
        margin-bottom: 1px;
        opacity: 0;
        overflow: hidden;
        width: 0;
        transition: opacity 0.25s ease-in;
        display: none;


        @include breakpoint(large) {
            display: block;
        }

    }

    &--loaded .c-map-key,
    &--loaded .c-range,
    &--loaded .o-zoom-controls {
        height: auto;
        margin-bottom: 0;
        opacity: 1;
        width: auto;
    }

    .c-layout__column {
        &:first-child,
        &:last-child {
            display: none;
        }

        &--half {
            &:last-child {
                display: flex;
            }
        }
    }

    @include breakpoint(medium) {
        .c-layout__column {
            display: flex;
            float: left;
            margin-left: 0.3125rem;
            margin-right: 0.3125rem;

            &:first-child {
                display: flex;
                position: absolute;
            }

            &:last-child {
                display: flex;
                width: calc(35% - 0.625rem);
            }

            &--half {
                margin-top: 50px;
                width: calc(65% - 0.625rem);

                &:last-child {
                    width: 100%;
                }
            }
        }
    }

    @include breakpoint(medium) {
        .c-layout__column {
            margin-left: 0.625rem;
            margin-right: 0.625rem;

            &:last-child {
                width: calc(35% - 1.25rem);
            }

            &--half {
                margin-top: 0;
                width: calc(65% - 1.25rem);

                &:last-child {
                    width: 100%;
                }
            }
        }
    }

    @include breakpoint(large) {
        .c-layout__column {
            &:first-child {
                min-height: 40px;
                position: static;
            }

            &:last-child {
                width: calc(25%  - 1.25rem);
            }

            &--half {
                width: calc(50% - 1.25rem);

                &:last-child {
                    width: calc(50%  - 1.25rem);
                }
            }
        }
    }
}

.o-map {
    padding: {
        left: 5%;
        right: 5%;
    }
    text-align: center;
    width: 100%;

    .c-button span {
        min-width: 260px;
    }

    @include breakpoint(medium) {
        padding: 0;
    }

    @include breakpoint(medium) {
        .c-button span {
            min-width: 200px;
        }
    }
}

.c-map-copy {
    @extend %c-rich-text-block;
    padding: 0px 10px 20px;

    h2 {
        @extend %c-heading--large;
        font-weight: $text-weight-light;
    }

    h3 {
        @extend %c-heading--medium;
        font: {
            size: $text-size-x-small;
            weight: $text-weight-light;
        }
        letter-spacing: 0.5px;
        line-height: $text-line-height-x-small;
        margin: {
            left: auto;
            right: auto;
        }
        max-width: 370px;

        @include breakpoint(medium) {
            font-size: $text-size-large;
            line-height: $text-line-height-large;
            max-width: 550px;
        }
    }

    .c-link {
        font-weight: $text-weight-light;
    }
}

.c-map-copy__instruction {
    display: none;

    @include breakpoint(medium) {
        display: block;
    }
}

.c-map-cta {
    @extend %c-rich-text-block;
    padding-top: 20px;
    text-align: center;

    @include breakpoint(large) {
        width: 100%;
    }
}

.c-map-wrapper {
    .c-layout__column:nth-last-child(2) & {
        @include breakpoint(medium) {
            padding: 50px 10px 50px 0;
            transform: translateX(0%);
            width: 152.8%;
        }

        @include breakpoint(medium) {
            margin-left: -5px;
            padding: 50px 10px;
        }

        @include breakpoint(large) {
            padding: 75px 10px;
            width: 153.1%;

            @media print, screen and (max-height: 1080px) {
                padding: 50px 10px;
            }
        }
    }
    
    @include breakpoint(medium) {
        box-sizing: content-box;
        overflow: hidden;

        .o-map--single & {
            padding: 0 10px; 
        }
    }

    &,
    .c-layout__column:nth-last-child(2) & {


        @include breakpoint(xlarge) {
            padding: 40px 10px;

            @media print, screen and (max-height: 1080px) {
                padding: 20px 10px;
            }
        }
    }
}

.c-map-container {
    overflow: visible !important;

    @include breakpoint(large) {
        margin: 0 auto;

        .c-layout__column--half:nth-last-child(2) & {
            margin: 0;
        }
    }

    @include breakpoint(xlarge) {
        margin: 0;
    }

    svg {
        height: 100%;
        transition: none!important;
        width: 100%;

        // Mobile breakpoint required to remove the transform
        @include breakpoint(medium down) {
            transform: none!important;
        }

        @include breakpoint(medium) {
            min-height: 350px;
        }

        @include breakpoint(large) {
            min-height: 470px;
        }

        @include breakpoint(xlarge) {
            min-height: 500px;
        }
    }

    p {
        display: none;

        @include breakpoint(medium) {
            bottom: 100%;
            display: block;
            font-weight: $text-weight-light;
            left: 0;
            position: absolute;
            text-align: center;
            width: 100%;
        }
    }
}

.o-map--single {
    #HOUSES {
        path {
            fill: #d1d3d4;
        }


        .plot {
            //This is for plots on the svg where the path is not nested but just below the #Houses layer
            &--available {
                fill: #842752 !important;
            }

            &--not-relased, // Spelling mistake in the demo feed.
            &--not-yet-released,
            &--not-released-yet,
            &--not-released {
                fill: #009afb !important;
            }

            &--coming-soon {
                fill: #ffbc2b !important;
            }

            &--unavailable,
            &--reserved,
            &--sold {
                fill: #d1d3d4 !important;
            }
            //End of new section

            &--available path {
                fill: #842752;
            }

            &--not-relased path, // Spelling mistake in the demo feed.
            &--not-yet-released path,
            &--not-released-yet path,
            &--not-released path {
                fill: #009afb;
            }

            &--coming-soon path {
                fill: #ffbc2b;
            }

            &--unavailable path,
            &--reserved path,
            &--sold path {
                fill: #d1d3d4;
            }
        }
    }
}

@include breakpoint(large) {
    #HOUSES {
        path {
            fill: #d1d3d4;
        }

        .plot {
            cursor: pointer;

            path {
                transition: fill 0.25s ease-in-out;
            }
            //This is for plots on the svg where the path is not nested but just below the #Houses layer
            &--available {
                fill: #842752 !important;

                &:hover {
                    fill: lighten(#842752, 15%);
                }
            }

            &--not-relased, // Spelling mistake in the demo feed.
            &--not-yet-released,
            &--not-released-yet,
            &--not-released {
                fill: #009afb !important;

                &:hover {
                    fill: lighten(#009afb, 15%);
                }
            }

            &--coming-soon {
                fill: #ffbc2b !important;

                &:hover {
                    fill: lighten(#ffbc2b, 15%);
                }
            }

            &--unavailable,
            &--reserved,
            &--sold {
                fill: #d1d3d4 !important;
                cursor: revert;
            }

            .plot.plot--only {
                &.plot--available {
                    &:hover {
                        fill: #842752;
                    }
                }

                &.plot--not-relased, // Spelling mistake in the demo feed.
                &.plot--not-yet-released,
                &.plot--not-released-yet,
                &.plot--not-released {
                    fill: #009afb;

                    &:hover {
                        fill: lighten(#009afb, 15%);
                    }
                }

                &.plot--coming-soon {
                    fill: #ffbc2b;

                    &:hover {
                        fill: lighten(#ffbc2b, 15%);
                    }
                }
            }
            //End of new section

            &--available path {
                fill: #842752;

                &:hover {
                    fill: lighten(#842752, 15%);
                }
            }

            &--not-relased path, // Spelling mistake in the demo feed.
            &--not-yet-released path,
            &--not-released-yet path,
            &--not-released path {
                fill: #009afb;

                &:hover {
                    fill: lighten(#009afb, 15%);
                }
            }

            &--coming-soon path {
                fill: #ffbc2b;

                &:hover {
                    fill: lighten(#ffbc2b, 15%);
                }
            }

            &--unavailable path,
            &--reserved path,
            &--sold path {
                fill: #d1d3d4;
            }
        }

        .plot.plot--only {
            &.plot--available path {
                &:hover {
                    fill: #842752;
                }
            }

            &.plot--not-relased path, // Spelling mistake in the demo feed.
            &.plot--not-yet-released path,
            &.plot--not-released-yet path,
            &.plot--not-released path {
                fill: #009afb;

                &:hover {
                    fill: lighten(#009afb, 15%);
                }
            }

            &.plot--coming-soon path {
                fill: #ffbc2b;

                &:hover {
                    fill: lighten(#ffbc2b, 15%);
                }
            }
        }
    }
}