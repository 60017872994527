.o-development-homes-filters.o-filters {
    display: block;

    .o-development-homes-filters__inner {
        background-color: $color-primary-blue;
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: initial;
        height: 100vh;
        z-index: 15;
        padding: 23px 20px;
        overflow-y: auto;
        @extend %c-rich-text-block;

        @include breakpoint(medium down) {
            width: 100vw;
        }

        @include breakpoint(medium) {
            padding: 40px 30px;
        }

        @include breakpoint(large) {
            background-color: $color-medium-grey;
            display: block;
            position: relative;
            top: initial;
            left: initial;
            width: initial;
            height: initial;
            z-index: initial;
            padding: 0;
        }
        .c-button span {
            width: 100%;
            margin-top: $sizing-small;
            @include breakpoint(large) {
                display: none;
            }
        }

        &--visible {
            display: block;
        }

    }
    .o-development-homes-filters__header-mobile {
        position: relative;
        @include breakpoint(large) {
            display: none
        }
        h3 {
            @extend %c-heading--large;
            font-size: $text-size-regular;
            line-height: 26px;
            color: white;
            padding-right: 30px;

            @include breakpoint(medium){
                font-size: $text-size-large;
                line-height: 32px
            }

        }
        p {
            @extend %c-heading--medium;
            font-size: $text-size-x-small;
            color: white;
            margin: 10px 0 20px;

            @include breakpoint(medium){
                font-size: $text-size-small;
                margin: 20px 0 25px;
            }
        }
        &__close {
            position: absolute;
            width: 25px;
            height: 25px;
            background-image: $svg-close-icon;
            background-size: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            top: -15px;
            right: -10px;

            @include breakpoint(medium) {
                width: 30px;
                height: 30px;
                top: -20px;
                right: -15px;
            }

        }
    }
    .o-development-homes-filters__header-desktop {
        @extend %c-promotion-block__content;
        @extend %c-rich-text-block;
        display: none;
        @include breakpoint(large) {
            display: block;
        }
        h3 {
            @extend %c-heading--medium;

            
            @include breakpoint(large){
                font-size:  $text-size-small;
                font-weight: 400;
                text-transform: lowercase;
                line-height: 32px;
                margin: 0 0 10px;
            }

            @include breakpoint(xlarge){
                font-size:  $text-size-large;
            }
        }
        p {
            margin-bottom: 5px;
            @include breakpoint(large){ 
                margin: 0;
                font-size: $text-size-xx-small;
                line-height: 21px
            }

            @include breakpoint(xlarge){
                font-size:  $text-size-x-small;
            }

        }

        span {
            @include breakpoint(large){ 
                padding: 0 !important;
            }
        }

    }
    .o-development-homes-filters__radio-buttons {
        label {
            position: relative;
            display: block;
            margin: 15px 0;
            padding-left: 30px;
            text-align: left;
            color: white;
            font-weight: $text-weight-light;
            cursor: pointer;
            &:last-of-type {
                margin-bottom: 20px;
                @include breakpoint(large){
                    margin-bottom: 15px;
                }
            }

            @include breakpoint(large) {
                margin: 20px 0;
            }

            @include breakpoint(large) {
                margin: 12px 0;
                color: $color-primary-blue;
                font-size: $text-size-xx-small;
            }
            @include breakpoint(xlarge) {
                &:first-of-type {
                    margin-top: 10px;
                }
                font-size: $text-size-x-small;
            }
            &::before {
                content: ' ';
                position: absolute;
                left: 0;
                top: 0;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: transparent;
                background-size: 100% 100%;
                border: 1px solid white;
                @include breakpoint(large) {
                    background-color: white;
                }
            }
            &::after {
                content: ' ';
                position: absolute;
                left: 5px;
                top: 5px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: white;
                background-size: 100% 100%;
                opacity: 0.2;
                transition: 0.25s all ease-in-out;
                @include breakpoint(large) {
                    background-color: $color-primary-blue;
                }
            }
        }
        input[type="radio"] {
            position: absolute;
            visibility: hidden;
            z-index: -1;
            &:checked {
                &+label {
                    &::after {
                        background-color: $color-purple;
                        opacity: 1;
                    }
                }
            }
        }
    }

    .c-range {

        margin: 20px 0 20px;        

        @include breakpoint(medium) {
            margin: 28px 0 24px;
        }

        @include breakpoint(large) {
            margin: 10px 0 0;
        }

        @include breakpoint(xlarge) {
            margin: 20px 0 0;
        }
        
        

        label {
            @extend %c-heading--small;
            text-align: left;
            font-size: $text-size-xxx-small;
            color: white;
            opacity: 0.6;
            margin-bottom: 2px;

            @include breakpoint(medium){
                font-size: $text-size-xx-small;
                margin-bottom: 8px;
            }

            @include breakpoint(large) {
                color: $color-primary-blue;
                opacity: 1;
                margin-bottom: 5px;
            }
            
            @include breakpoint(xlarge) {
                font-size: $text-size-x-small;
            }

        }
        .c-dropdown {
            position: relative;
            &::after {
                content: ' ';
                position: absolute;
                top: 22px;
                right: 10px;
                width: 16px;
                height: 10px;
                background-image: $svg-arrow-down;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 100% 100%;
                @include breakpoint(large) {
                    background-image: $svg-arrow-down-blue;
                }
            }
        }
        select {
            appearance: none;
            width: 100%;
            margin: 0;
            padding: 10px 0;
            border: none;
            border-bottom: 1px solid white;
            border-radius: 0;
            background-color: $color-primary-blue;
            font-size: $text-size-x-small;
            @extend %c-heading;
            @extend %c-bodytext--regular;
            font-weight: $text-weight-light;
            color: white;

            @include breakpoint(medium) {
                padding: 15px 0;
                font-size: $text-size-small;
            }

            @include breakpoint(large) {
                background: #fff;
                border-radius: 0;
                border: none;
                color: rgba(8, 45, 96, 0.5);
                cursor: pointer;
                display: block;
                font-weight: 300;
                line-height: 24px;
                padding: 13px 10px;
                width: 100%;
                font-size: $text-size-xx-small;
            }


            @include breakpoint(xlarge) {
                background-color: white;
                border: 0;
                font-size: $text-size-x-small;
            }
            &:focus {
                outline: none;
            }
        }
    }

    .c-button {
        @extend %primary-button;
        display: block;
        color: white;
        overflow: hidden;
        background-color: transparent;
        border: 0;
        margin-top: 30px;

        @include breakpoint(medium){
            margin-top: 40px;
        }

        @include breakpoint(large){
          display: none;
        }
        

        span {
            background-color: $color-purple;
            color: #fff !important;
            margin-top: 0 !important;
            border: 0 !important; 
        }

        &:after {
            transform: rotate(10deg);
            height: 750%;
            top: -400% !important;
        }

        &:hover:after {
            top: -400% !important;
            transition-duration: 1s, 1s, 0.25s !important;
        }
    }
}