.o-filters {
    @extend %c-rich-text-block;
    @extend %sidebar-item;
    display: none;

    @include breakpoint(large) {
        background: #ebebeb;
        position: relative;
        text-align: center;
        width: 100%;
    }

    h3 {
        @extend %c-heading--regular;
        font: {
            size: $text-size-small;
            weight: $text-weight-regular;
        }
        text-transform: lowercase;
        line-height: $text-line-height-large;
        margin: 0 0 10px;

        @include breakpoint(xlarge) {
            font-size: $text-size-large;
        }
    }

    p {
        line-height: 21px;
        margin: 0;

        font-size: $text-size-xx-small; 

        @include breakpoint(xlarge) {
            font-size: $text-size-x-small;
        }
    }
    
    .c-link {
        span {
            padding: 0;

            font-size: $text-size-xx-small; 
            
            @include breakpoint(xlarge) {
                font-size: $text-size-x-small;
            }
        }
    }

    .c-range {    
        margin-top: 10px;
        label {
            font: {
                size: $text-size-xx-small;
            }
        }
            
        @include breakpoint(xlarge) {            
            margin-top: 20px;
            label {
                font-size: $text-size-x-small;
            }
        }
    }    

    .c-dropdown {
    
        select {
            font: {
                size: $text-size-xx-small;
            }
    
            @include breakpoint(xlarge) {
                font-size: $text-size-x-small;
            }
        }
    }
    

}

.c-filters-wrap {
    overflow: hidden;
    transition: max-height 0.45s ease-in-out;
    max-height: 90vh;
}

.c-map-key {
    text-align: left;
}

.c-map-key__item {
    color: #082d60;
    //margin: 10px 0;
    font: {
        size: $text-size-xx-small;
        weight: $text-weight-light;
    }
    line-height: $text-line-height-large;
    vertical-align: middle;

    @include breakpoint(xlarge) {
        font-size: $text-size-x-small;
    }

    &::before {
        border-radius: 100%;
        content: '';
        display: inline-block;
        height: 18px;
        margin-right: 10px;
        position: relative;
        top: 4px;
        width: 18px;
        @include breakpoint(xlarge) {
            height: 20px;
            width: 20px;
        }
    }

    &--red::before {
        background: #842752;
    }

    &--amber::before {
        background: #ffbc2b;
    }

    &--blue::before {
        background: #009afb;
    }

    &--grey::before {
        background: #d1d3d4;
    }
}