﻿.u-experience-editor {

    .o-testimonial-carousel {
        &:before {
            display: none;
        }

        .c-carousel-slides {

            .c-carousel-slides__slide {
                display: none !important;

                &:first-child {
                    display: flex !important;
                }
            }
        }
    }
}
