.development-info-block {
  background:#082d60;
  text-align: center;
  color: #fff;
  padding: 20px;

  &__image {
    display: none;
    object-fit: cover;
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 5px;
  }

  &__address {
    font-size: 12px;
    padding-bottom: 7px;
  }

  &__number {
    font-size: 18px;
    padding-bottom: 3px;
    color: #fff;

    &__icon {
      background-image: $svg-phone-icon-solid-white;
      background-repeat: no-repeat;
      background-position: center center;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 2px;
      height: 15px;
      width: 15px;
      background-size: contain;
    }

    a,
    a:hover,
    a:focus,
    &:hover {
      color: #fff;
    }
  }

  &__message {
    margin: 9px 0;
    background: #042450;
    padding: 8px 20px;
    font-size: 12px;
    font-weight: 300;
  }

  &__available-homes {
    font-size: 12px;
    padding-top: 10px;

    &__title {
      font-weight: 600;
      padding-bottom: 5px;
    }

    li {
      font-weight: 300;
      padding-bottom: 5px;
    }
  }
    
  @include breakpoint(large) {
    padding: 28px 40px;
    text-align: left;
  
    &__image {
      display: block;
      width: 100%;
      height: 300px;
    }
  
    &__title {
      font-size: 30px;
    }
  
    &__address {
      font-size: 14px;
      padding-bottom: 12px;
    }
  
    &__number {
      font-size: 22px;
      padding-bottom: 10px;
  
      &__icon {
        height: 19px;
        width: 19px;
        margin-bottom: 3px;
      }
    }
  
    &__message {
      font-size: 14px;
      text-align: center;
    }
  
    &__available-homes {
      font-size: 14px;
      padding-top: 15px;
    }
  }


  @include breakpoint(xlarge) {
    padding: 28px 80px;

    &__title {
      font-size: 32px;
    }
  
    &__address {
      font-size: 16px;
    }
  
    &__number {
      font-size: 25px;
    }

    &__image {
      height: 462px;
    }

    &__available-homes {
      font-size: 16px;
    }
  }
}

.development-info-block-container {
  max-width: none;
  display: flex;
  flex-direction: column;

  @include breakpoint(large) {
    max-width: 400px;
  }
  
  @include breakpoint(xlarge) {
    max-width: 600px;
  }

  &.confirmation {
    .development-info-block__image {
      display: block;
      width: 100%;
      height: 200px;
    }

    @include breakpoint(large) {
      height: 386px;
      max-width: 90rem;
      margin: auto;
      flex-direction: row;

      .development-info-block {
        display: flex;
        flex-direction: column;
        padding: 60px 40px;
        width: 400px;
      }

      .development-info-block__image {
        display: block;
        width: auto;
        height: 100%;
        flex-grow: 1;
      }

      .development-info-block__address {
        display: flex;
        flex-direction: column;
        line-height: 27px;
        flex-grow: 1;
      }
    }

    @include breakpoint(xlarge) {
      .development-info-block {
         width: 500px;
      }
    }
  }
}