.o-development-homes {
    .o-development-homes__cards {
        position: relative;
        margin-top: 35px;
        padding: 20px 0 0;
    }

    .o-development-homes__google-copy {
        color: $color-primary-blue;
        font-size: $text-size-xxx-small;
        margin: 0 auto;
        width: 300px;
        text-align: center;
        margin-bottom: 15px;
    }

    .o-development-homes__cards-container {
        position: relative;
        min-height: 495px;

        &--no-results {
            &::after {
                content: 'No results to show';
                color: $color-primary-blue;
                text-align: center;
                position: absolute;
                left: 0;
                right: 0;
                top: calc(50%);
                transform: translateY(-50%);
                font-size: $text-size-small;
            }
        }

        &--refine {
            &::before {
                content: 'Please refine your search and try again.';
                color: $color-purple;
                text-transform: uppercase;
                position: relative;
                top: 15px;
                font-size: $text-size-small;

                @include breakpoint(medium) {
                    top: -5px;
                }
            }
        }
    }

    .o-development-homes__loader {
        position: absolute;
        left: 50%;
        right: 0;
        bottom: 0;
        top: 50%;
        z-index: 1;
        transform: translate(-50%, -50%);
        text-align: center;

        &--hidden {
            display: none;

            .o-development-homes__loader-icon {
                animation: none;
            }
        }

        &-icon {
            animation: spin 1s linear infinite;
            background-image: $svg-loader;
            background-repeat: no-repeat;
            background-position: center center;
            height: 70px;
            width: 70px;
            display: inline-block
        }

        &-text {
            margin-top: 10px;
            color: $color-primary-blue;
        }
    }

    .o-development-homes__show-more {
        display: none;
        margin: auto;
        @extend %c-button;
        width: 202px;
        padding: {
            left: 0;
            right: 0;
        }

        &--visible {
            display: block;
        }

        &--loading {
            animation: spin 1s linear infinite;
            background-image: $svg-loader;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;

            span {
                opacity: 0;
            }
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        50% {
            transform: rotate(180deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    &__arrow {
        width: 14px;
        height: 14px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        display: block;
        cursor: pointer;
        transition: transform 0.35s ease-in-out;
        position: absolute;
        top: 8px;

        &--prev {
            background-image: $svg-pagination-arrow-left;
            left: 0;
        }

        &--next {
            background-image: $svg-pagination-arrow-right;
            left: 200px;
        }

        &--disabled {
            opacity: 0.3;
            cursor: default;
        }

        &:not(&--disabled):hover {
            transform: scale(1.3);
        }
    }

    &__pagination {
        position: relative;
        text-align: center;

        @include breakpoint(large) {
            text-align: left;
            margin-bottom: 0;
        }

        li {
            width: 30px;
            height: 30px;
            margin: 0 5px;
            border: 1px solid $color-darker-grey;
            border-radius: 50%;
            text-align: center;
            display: inline-block;
            vertical-align: middle;
            transition: border-color 0.35s;
            position: relative;
            cursor: pointer;

            &.u-active, &:hover {
                position: relative;

                border: {
                    width: 2px;
                    color: $color-primary-blue;
                }
            }

            &.u-active {
                cursor: default;

                border: {
                    color: $color-primary-blue;
                }

                button {
                    cursor: default;
                }
            }


            button {
                padding: 0;
                font-size: $text-size-small;
                line-height: 25px;
                color: $color-primary-blue;
                height: 100%;
            }
        }

        .o-development-homes__arrow, ul {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            top: auto;
            left: auto !important; // To ignore 'left' added in JS to the next arrow
        }

        .o-development-homes__pagination-paging--collapsed {
            li:not(:first-of-type):not(:last-of-type) {
                display: none;

                &.u-active {
                    display: inline-block;
                    margin: 0 12px;

                    &::after {
                        content: '...';
                        display: inline-block;
                        position: absolute;
                        right: -16px;
                        bottom: 0;
                    }

                    &::before {
                        content: '...';
                        display: inline-block;
                        position: absolute;
                        left: -16px;
                        bottom: 0;
                    }
                }
            }

            li:first-of-type {

                &.u-active + li {
                    display: inline-block;
                    margin-right: 16px;

                    &::after {
                        content: '...';
                        display: inline-block;
                        position: absolute;
                        right: -16px;
                        bottom: 0;
                    }

                    & ~ :last-of-type {
                        margin-left: 3px;
                    }
                }
            }

            li:nth-last-of-type(2) {

                &.u-visible {
                    display: inline-block;
                    margin-left: 16px;

                    &::before {
                        content: '...';
                        display: inline-block;
                        position: absolute;
                        left: -16px;
                        bottom: 0;
                    }
                }
            }
        }

        // overriding some styles for the PlotShowcase component
        &-center {
            text-align: center;
        }
    }

    // overriding some styles for the PlotShowcase component
    &__full-width.c-layout__column {
        width: 100%;
    }
    
    // overriding some styles for the PlotShowcase component
    &__full-width {
        width: 100%;
    }

    &--empty {
        .o-development-homes__pagination {
            display: none;
        }
    }
}
