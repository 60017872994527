﻿.c-header {
    .c-logo {
        font-size: 0;
        height: 50%;
        display: flex;
        align-items: center;
        z-index: 11;

        width: calc(50% - 1.25rem);
        margin-right: .625rem;
        margin-left: .625rem;

        @include breakpoint(lsmall) {
            @include xy-cell(4);
        }

        @include breakpoint(medium) {
            @include xy-cell(2);
        }

        @include breakpoint(medium only) {
            width: 18% !important;
        }


        img {
            max-height: 100%;
        }
    }
}