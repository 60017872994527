﻿.c-nav {
    text-align: right;
    width: 100%;
    z-index: 10;
    position: fixed;

    @include breakpoint(small) {
        order: -1;
        position: absolute;
        top: 60px;
        bottom: 0;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: $color-medium-grey;
        padding-bottom: 60px;
        transform: translate3d(0, 0, 0);
        transition: transform 0.4s;
        display: none;
        left: 0;
        height: calc(100vh - 60px);

        &--active {
            display: block;
        }
    }

    @include breakpoint(msmall) {
        order: -1;
        justify-content: flex-end;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        overflow: visible;
        background: none;
        padding-bottom: 0;
        transition: none;
        transform: none;
        top: 0;
        height: 100%;
    }


    @include breakpoint(medium) {
        order: 0;
        position: static;
     //   overflow: visible;
    }

    @include breakpoint(large) {
        justify-content: space-between;
    }

    &--offleft {
        transform: translate3d(-100%, 0, 0);
    }
}

.c-nav__nav-items-container {
    cursor: pointer;

    > ul {
        height: 100%;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    @include breakpoint(small) {
        background-color: $color-medium-grey;
        text-align: left;
        overflow: visible;
    }


    @include breakpoint(msmall) {
        display: none;
        position: absolute;
        top: 80px;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: scroll;
    //    overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        transition: transform 0.5s;
        transform: translate3d(0, 0, 0);
    }


    @include breakpoint(medium) {
        position: static;
        height: 80px;
        padding: inherit;
        margin: inherit;
        background: none;
        transition: none;
        display: block;
        transform: none;
        overflow: hidden;
    }
}

.c-nav__nav-items-container--active {
    display: block;
    overflow-y: auto;
    height: calc(100vh - 80px);
}

.c-nav__nav-items-container--offleft {
    transform: translate3d(-100%, 0, 0);
}

.c-nav__nav-item {
    display: inline-block;
    padding: 0px 5px;
    background-repeat: no-repeat;
    height: 60px;

    a {
        color: #fff;
        text-decoration: none;
        display: block;

        &:focus {
            outline: 1px solid red;
        }
    }



    @include breakpoint(small) {
        width: 100%;
        padding: 0 calc(3% + 20px) 0 3%;
        overflow: hidden;
        border-bottom: $mobile-menuitem-border;
        transition: none;
        @include mobile-menuitem-arrow;

        > a {
            line-height: 60px;
        }


        a {
            color: $color-primary-blue;
            font-size: $text-size-xx-small !important;
        }
    }


    @include breakpoint(msmall) {
        height: 80px;
        padding: 0 calc(5% + 20px) 0 5%;

        > a {
            line-height: 80px;
        }


        a {
            font-size: $text-size-x-small !important;
        }
    }

    @include breakpoint(500px) {
        a {
            font-size: $text-size-regular !important;
        }
    }

    @include breakpoint(medium) {
        width: auto;
        border-bottom: 0;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAAAXNSR0IArs4c6QAAAF1JREFUKBWtzFsOwCAIRFFcBOu1XS+LaLWhJj5GaJVkws/NIXKciBx5jtROFLvSzzPRMCMViE1zMjOEIQiw14boEDSwKdqBTgyiFfgRG6IF/Il16AMuYhUaNmEFvQGhWjvOgYOMlAAAAABJRU5ErkJggg==");
        background-position: center bottom -10px;
        transition: background-position 0.3s;
        padding: inherit;
        @include map-to-sizes(padding, ( xxlarge: 0px 12px, xlarge: 0px 12px, large: 0px 7px, medium: 0px 5px ));

        a {
            @include body-fontsizes;
            color: #ffffff;
            font-size: inherit;
        }

        &:last-child {
            padding-right: 0;
        }

        &.u-no-subnav {
            background-image: none;
        }
    }
}

.c-nav__nav-item--active {

    @include breakpoint(medium) {
        background-position: center bottom 0px;
    }
}

.o-nav__subnav-container {
    display: none;
    transform: translate3d(0, 0, 0);

    @include body-fontsizes;

    @include breakpoint(small) {
        position: absolute;
        left: 100%;
        width: 100%;
        top: 0px;
        bottom: 0;
        padding: 0 0 60px 0;
        background-color: $color-medium-grey;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        z-index: 10;

        > .grid-container {
            margin: 0;

            > div {
                width: 100%;
                margin: 0;
            }
        }
    }

    @include breakpoint(msmall) {
        padding-bottom: 0;
    }

    @include breakpoint(medium) {
        @include subnav-container;
        overflow: hidden;
        z-index: 1;


        > .grid-container {
            margin: 0 5%;
        }
    }


    .c-subnav-promo {
        display: inline-block;
        color: $color-primary-blue;
        height: 100%;

        @include breakpoint(small) {
            display: none;
        }

        @include breakpoint(medium) {
            display: block;
            @include xy-cell(3);
        }

        @include breakpoint(large) {
            @include xy-cell(4);
        }
    }
}

    .o-nav__subnav-container--active {
        display: block;
    }

    .c-nav__subnav {
        display: inline-block;
        float: right;
    }

    .c-nav__subnav-column {
        transform: translate3d(0, 0, 0);
        display: inline-block;
        text-align: left;
        height: 100%;
        min-height: 70px !important;
        vertical-align: top;
        overflow-wrap: break-word;
        word-wrap: break-word;

        @include breakpoint(msmall){
            padding-bottom: 100px;
        }

        @include breakpoint(medium){
            padding-bottom: 0;            
        }

        @include breakpoint(xlarge) {
            &:first-of-type {
                padding-left: xy-cell-size(1);
                box-sizing: content-box;
            }

            &:first-of-type::before {
                position: absolute;
                top: -30px;
                bottom: -30px;
                left: 0;
                content: "";
                border-left: solid 1px rgba($color-blue-dust, 0.2);
            }
        }
    }

    .c-nav-subnav-back-btn {
        transform: translate3d(0, 0, 0);

        @extend .c-nav__subnav-link;
        width: 100%;
        background-position: 5% !important;
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjEzcHgiIGhlaWdodD0iMjJweCIgdmlld0JveD0iMCAwIDEzIDIyIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA0NyAoNDUzOTYpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPmFycm93PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IjQuLU5hdmlnYXRpb24tQ29tcG9uZW50cy1ANzY4cHgiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJOYXZpZ2F0aW9uLUdsb2JhbC1PcGVuLXN0YXRlLTItQDc2OHB4IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzcuMDAwMDAwLCAtMTEyLjAwMDAwMCkiIGZpbGw9IiMwODJENjAiPgogICAgICAgICAgICA8ZyBpZD0ibmF2aWdhdGlvbiI+CiAgICAgICAgICAgICAgICA8ZyBpZD0iYXJyb3ciIHRyYW5zZm9ybT0idHJhbnNsYXRlKDM3LjAwMDAwMCwgMTExLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYuNTAwMDAwLCAxMS41MDAwMDApIHJvdGF0ZSgtMTgwLjAwMDAwMCkgdHJhbnNsYXRlKC02LjUwMDAwMCwgLTExLjUwMDAwMCkgIiBpZD0iUmVjdGFuZ2xlLTE2Ij4KICAgICAgICAgICAgICAgICAgICAgICAgPHJlY3QgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNi41MDAwMDAsIDYuNTAwMDAwKSByb3RhdGUoLTMxNS4wMDAwMDApIHRyYW5zbGF0ZSgtNi41MDAwMDAsIC02LjUwMDAwMCkgIiB4PSItMSIgeT0iNS41IiB3aWR0aD0iMTUiIGhlaWdodD0iMiIgcng9IjEiPjwvcmVjdD4KICAgICAgICAgICAgICAgICAgICAgICAgPHJlY3QgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNi41MDAwMDAsIDE2LjI1MDAwMCkgcm90YXRlKC00NS4wMDAwMDApIHRyYW5zbGF0ZSgtNi41MDAwMDAsIC0xNi4yNTAwMDApICIgeD0iLTEiIHk9IjE1LjI1IiB3aWR0aD0iMTUiIGhlaWdodD0iMiIgcng9IjEiPjwvcmVjdD4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==") !important;

        > a {
            font-weight: normal !important;

            @include breakpoint(small) {
                padding-left: 25px;
            }

            @include breakpoint(lsmall) {
                padding-left: 35px;
            }
        }

        @include breakpoint(small) {
            background-position: 3% !important;
        }

        @include breakpoint(msmall) {
            background-position: 5% !important;
        }

        @include breakpoint(medium) {
            display: none;
        }
    }

.c-nav__subnav-link {
    transform: translate3d(0, 0, 0);

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }

    a {
        @extend %u-hyperlink;
        color: $color-primary-blue;
        font-weight: 300;
    }

    @include breakpoint (small) {
        height: 60px;
        padding: 0 calc(3% + 20px) 0 3%;
        border-bottom: $mobile-menuitem-border;
        @include mobile-menuitem-arrow;

        a {
            line-height: 60px;
        }
    }

    @include breakpoint (msmall) {
        height: 80px;
        padding: 0 calc(5% + 20px) 0 5%;

        a {
            line-height: 80px;
        }
    }


    @include breakpoint (medium) {
        padding: 7px 7px;
        height: auto;
        border-bottom: none;
        background-image: none;

        a {
            line-height: inherit;
        }
    }
}

.c-breadcrumb {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #ebebeb;
    width: 100%;
    padding: 0;
    margin: 0;
    padding: 1em;
    overflow: auto;
}

.c-breadcrumb-list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: white;
    margin: 0;
    padding: 0;
    padding-left: 225px;
    font-size: 15px;

    @media screen and (min-width: 1860px) {
        padding-left: 225px;
    }

    @media screen and (max-width: 1859px) {
        padding-left: 200px;
    }

    @media screen and (max-width: 1742px) {
        padding-left: 150px;
    }

    @media screen and (max-width: 1640px) {
        padding-left: 100px;
    }

    @media screen and (max-width: 1560px) {
        padding-left: 50px;
    }

    @media screen and (max-width: 1024px) {
      padding-left: 25px;
    }

    @media screen and (max-width: 768px) {
      padding-right: 15px;
      padding-left: 0px;
    }
}

.c-breadcrumb-list__items {
    color: #082d60;
    list-style: none;
    text-decoration: none;
    white-space: nowrap;

    @include breakpoint(small) {
         a {
            font-size: $text-size-xx-small !important;
         }
    }
}
