.community {
  background: #f0f0f0;
  color: #082d60;
  padding: 30px 0;

  &__statement {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    max-width: 686px;
    margin: 0 auto;
    padding: 0 30px;
    padding-bottom: 12px;

    &__value {
      color: $color-purple;
    }
  }

  &__intro-copy {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    max-width: 686px;
    margin: 0 auto;
    padding: 0 30px;
  }

  &__improvements {
    display: flex;
    flex-direction: row;
    margin: auto;
    overflow: hidden;

    .improvement {
      padding: 30px;
      margin: 0 auto auto auto;

      &__block {
        margin: auto;
        width: 120px;

        &__icon {
          border-radius: 50%;
          background: white;
          width: 102px;
          height: 102px;
          position: relative;
          margin: auto;

          img {
            width: 38px;
            height: 38px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        &__text {
          text-align: center;
          padding-top: 14px;
          line-height: 27px;;

          strong {
            font-weight: 600;
          }
        }
      }
    }

    &.draggable {
      justify-content: center;
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      justify-content: end;

      &::-webkit-scrollbar {
        display: none;
      }

      .improvement:first-of-type {
          padding-left: 50px;
      }

      .improvement:last-of-type {
        padding-right: 50px;
      }
    }
  }

  @include breakpoint(medium) {
    padding: 108px 0;

    &__statement {
      font-size: 32px;
    }
  
    &__intro-copy {
      font-size: 16px;
      padding-bottom: 45px;
    }
  
    &__improvements {
      flex-wrap: wrap;
      justify-content: center;
      max-width: 1040px;
      padding: 0 50px;
  
      .improvement {
        padding: 30px 27px;

        &:first-of-type,
        &:last-of-type {
          padding: 30px 27px;
        }

        &.three-per-row {
          width: 33%;
        }
  
        &.two-per-row {
          width: 50%;
        }

        &__block {
          width: 142px;

          &__icon {
            width: 142px;
            height: 142px;

            img {
              width: 54px;
              height: 54px;
            }
          }
        }
      }

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        justify-content: flex-start;
      }
    }
  }
}