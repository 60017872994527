.alert {
    padding: 20px 0;
    text-align: center;
    border: 1px dashed;

    &.alert-info {
        color: $color-light-blue;
    }

    &.alert-success {
        color: $color-purple;
    }

    &.alert-warning {
        text-transform: uppercase;
        color: $color-warm-yellow;
    }

    &.alert-danger {
        text-transform: uppercase;
        color: $color-red;
    }
}
