﻿.three-quarter-carousel {
    @extend %hero-carousel;
    @extend %u-section-separation;

    &::before {
        display: none;
    }

    margin-left: 0 !important;
    margin-right: 0 !important;
    height: 100%;
    max-height: calc(100vh - 90px);
    position: relative;

    @include breakpoint(large) {
        margin-left: 5% !important;
        margin-right: 5% !important;
        max-width: none !important;
    }

    @include breakpoint(xxlarge) {
        margin-left: auto !important;
        margin-right: auto !important;
        width: $global-width;
    }


    @include xy-grid;

    .c-carousel-slides {
        @extend %three-quarter-content;
        max-height: calc(100vh - 90px);
    }

}
