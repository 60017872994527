.c-vacancies-sidebar {
    &--secondary {
        position: fixed;
        bottom: -80px;
        left: 3%;
        right: 3%;
        padding: 0;
        z-index: 9;
        background: transparent;
        transition: bottom 0.4s;
        transition-delay: 0.1s;
        transform: translate3d(0, 0, 0);
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        background-color: $color-purple;

        @include breakpoint(msmall) {
            left: 5%;
            right: 5%;
        }

        &--visible {
            bottom: 20px;
        }

        .vacancies-filters {
            @extend .primary-cta;
            @extend %primary-button;
            text-decoration: none;
            height: 50px;
            width: 100%;
            color: #fff;
            line-height: 50px;
            padding: 0 5px;
            font-size: $text-size-x-small;
            text-align: center;
            display: block;
            flex-shrink: 0;
            transform: translateX(0%);
            transition: transform 0.4s;

            &:after {
                transform: rotate(10deg);
                height: 750%;
                top: -400% !important;
            }

            &:hover:after {
                top: -400% !important;
                transition-duration: 1s, 1s, 0.25s !important;
            }

            &--hidden {
                transform: translateX(-100%);
            }
            

            &::before {
                content: '';
                background-image: $svg-filters;
                background-repeat: no-repeat;
                background-position: center center;
                height: 100%;
                width: 21px;
                margin-right: 10px;
                display: inline-block;
                vertical-align: top;
            }

            &--blue {
                background-color: $color-primary-blue;
            }

            &--visible {
                transform: translateX(-100%);
            }
        }
    }
}
