﻿.o-footer {
  .c-footer__upper {
      .c-logo {
          font-size: 0;
          text-align: center;
          width: 100%;
          display: block;
          margin-top: 30px;
          margin-bottom: 30px;
          border-bottom: rgba($color-blue-dust, 0.3) 1px solid;
          padding-bottom: 30px;


          @include breakpoint(medium) {
              @include xy-cell(3);
              text-align: left;
              border-bottom: none;
              padding-bottom: 0;
              position: relative;
          }


          img {
              max-height: 100%;
              @include xy-cell(8);

              @include breakpoint(msmall) {
                  @include xy-cell(6);
              }

              @include breakpoint(lsmall) {
                  @include xy-cell(5);
              }

              @include breakpoint(medium) {
                  @include xy-grid-collapse();
                  width: auto;
                  max-height: 100%;
                  max-width: 100%;
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
              }
          }
      }
  }

  .c-footer__lower {
      .c-logo {

      }
  }
}
