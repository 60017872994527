﻿
.c-offer-item--fullwidth {
    margin: {
        top: 20px;
        bottom: 20px;
    };

    .grid-x.grid-margin-x {
        margin: 0;
    }

    .c-offer-item__img {
        @include xy-cell(12, $gutter-type: none);
        width: 100%;

        @include breakpoint(large) {
            margin: 0;
            @include xy-cell(4, $gutter-type: none);
            @include xy-cell-offset(1);
        }
    }

    .c-offer-item__copy {
        @include xy-cell(12, $gutter-type: none);
        width: 100%;

        @include breakpoint(large) {
            margin: 0;
            @include xy-cell(6, $gutter-type: none);
            text-align: left;
            padding: 0;
            display: flex;
            align-items: center;
        }

        .c-button--purple {
            @include breakpoint(large) {
                margin: 0 0 0 0;
            }
        }
    }

    .c-offer-item__text-wrap {
        width: 70%;
        margin: 0 auto;
    }
}
