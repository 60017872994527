﻿.o-full-width-content {

    &.bg-grey {
        background-color: $color-light-grey;
    }

    &.bg-blue {
        background-color: $color-primary-blue;

        .c-promotion-block__content {

            * {
                color: $color-white;
            }

            .c-button {

                span {
                    border-color: $color-white;
                }
            }
        }
    }
}
