.o-development-homes {
    &__border {
        @include breakpoint(xlarge) {
            width: calc(100% - 1.25rem);
            position: absolute;
            top: 0;
            left: 0.625rem;
            border-top: 1px solid rgba(8, 45, 96, 0.2);
        }
    }

    &__header {
        @extend %c-heading--large;
        width: calc(100% - 60px); // Filter button on small - medium screens
        font-weight: $text-weight-light;
    }

    &__sub-header, .o-development-homes__none-found {
        @extend %c-heading--medium;

        span {
            color: $color-purple;
        }

        margin-bottom: 40px;

        &--hidden {
            display: none;
        }
    }

    .o-development-homes__none-found {
        display: none;
        padding-bottom: 200px;

        &--visible {
            display: block;
        }
    }

    &__card {
        @extend %c-rich-text-block;
        text-align: center;
        margin-bottom: $sizing-regular;

        &--disabled {
            opacity: 0.5;
            cursor: default;
            pointer-events: none;
        }

        &__media {
            width: 100%;
            overflow: hidden;
            padding-bottom: 83%;
            position: relative;

            img {
                position: absolute;
                display: block;
                height: 100%;
                top: 0;
                left: 0;
                width: auto;
                max-width: none;
                margin-left: 50%;
                transform: translateX(-50%);
            }
        }

        &__header {
            @extend %c-heading--regular;
            margin-top: 20px;
            line-height: 1.3;
        }

        &__divider {
            @extend %u-section-border;
            width: 250px;
            margin: 20px auto;

            @include breakpoint(medium) {
                width: auto;
                margin: 20px $sizing-small;
            }
        }

        &__distance {
            &::before {
                content: $svg-icon-location-blue;
                display: inline-block;
                vertical-align: middle;
                padding-right: 8px;
            }
        }

        &__go-to-map {
            font-family: "Lato", serif;
            display: none;
            color: $color-primary-blue;
            margin: 0 auto 20px;
            text-decoration: underline;
            font-weight: 400;
            font-size: 1rem;
            display: none;

            @include breakpoint(large) {
                display: block;
            }
        }

        .c-button span {
            width: 250px;

            @include breakpoint(medium) {
                width: auto;
            }

            @media print, screen and (min-width: 1024px) and (max-width: 1230px) {
                min-width: 160px;
            }
        }

        &__body {
            @extend %c-promotion-block__content;
        }

        @media print, screen and (min-width: 64em) {
            width: calc(25% - 1.25rem);
        }

        &.skeleton {
            opacity: 0.5;
            cursor: default;
            pointer-events: none;
            animation: pulse 1.5s infinite ease-in-out;

            &:nth-child(odd) {
                animation-delay: 0.1s;
            }

            &:nth-child(even) {
                animation-delay: 0.3s;
            }

            .o-development-homes__card__media {
                background-color: $color-light-grey;
                animation: pulse 1.5s infinite ease-in-out 0.1s;
            }

            .o-development-homes__card__header {
                background-color: $color-light-grey;
                animation: pulse 1.5s infinite ease-in-out 0.2s;
            }

            .o-development-homes__card__divider {
                background-color: $color-light-grey;
                animation: pulse 1.5s infinite ease-in-out 0.3s;
            }

            .o-development-homes__card__body {
                span {
                    width: 100%;
                    display: inline-block;
                    background-color: $color-light-grey;
                    animation: pulse 1.5s infinite ease-in-out 0.4s;

                    &:first-child {
                        transform: translateY(-30%);
                    }

                    &:last-child {
                        transform: translateY(10%);
                    }
                }
            }

            .c-button {
                span {
                    background-color: $color-light-grey;
                    border: 2px solid $color-dark-grey;
                    animation: pulse 1.5s infinite ease-in-out 0.5s;
                }
            }
        }
    }

    &__letter-list {
        font-family: "Lato", serif;
        column-count: 1;
        column-gap: 50px;
        padding-left: 15px;

        @include breakpoint(medium) {
            column-count: 2;
        }
    }

    &__list-item {
        margin-bottom: 25px;

        &__header {
            font-size: 1.3rem;
            line-height: 1.5rem;
            margin-bottom: 7px;
            color: $color-dark-grey;
            font-weight: 300;
        }

        &__body {
            a {
                font-size: 1rem;
                color: $color-primary-blue;
                font-weight: 300;
                text-decoration: none;

                span {
                    font-weight: 400;
                }
            }
        }
    }
}

@keyframes pulse {
    0%, 100% {
        opacity: 0.5;
    }
    50% {
        opacity: 0.9;
    }
}
