﻿.c-disclaimer {
    @extend %c-rich-text-block;
    text-align: center;

    &::before {
        content: '';
        margin-bottom: 50px;
        display: table;
    }
    &::after {
        content: '';
        margin-bottom: 50px;
        display: table;
    }

    h1 {
        @extend %c-heading--large,;
    }

    h2 {
        @extend %c-heading--large;
        font-weight: $text-weight-light;
    }

    h3 {
        @extend %c-heading--regular;
    }

}