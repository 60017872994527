﻿.u-experience-editor {
   .c-sidebar {
       position: absolute;
       z-index: 10;
       top: 417px;
       transform: none !important;
       opacity: 1;

       .c-sidebar__content {
           display: block;
           padding: 100px 20px !important;
       }
   }
}
