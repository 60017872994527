﻿.modal {
    display: none;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow-y: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    &.show {
        display: table;
    }

    &__wrapper {
        position: absolute;
        overflow-y: auto;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        width: 100%;
        min-height: 100%;
        height: 100%;

        @include breakpoint(medium) {
            position: relative;
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 300px;
        width: 100%;
        min-height: 100%;
        padding: 60px 20px 20px;
        background-color: white;
        border: 1px solid black;
        text-align: left;
        animation: blowUpModal .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

        @include breakpoint(lsmall) {
            padding: 20px;
        }

        @include breakpoint(medium) {
            display: inline-block;
            min-height: auto;
            width: 800px;
        }

        &-img {
            max-width: 50%;
        }
    }

    &__body {
        &-title {
            text-align: center;
            font-size: $text-size-xxxx-large;
            font-weight: 300;
            line-height: 46px;
            margin: 0 0 20px 0;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        @include breakpoint(lsmall) {
            flex-direction: row;
        }
    }

    &-overlay {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.7);
        position: absolute;
        top: 0;
        left: 0;
    }

    &-btn {
        &__close {
            position: absolute;
            top: 20px;
            right: 20px;
            width: 32px;
            height: 32px;

            &:before,
            &:after {
                position: absolute;
                top: 0;
                right: 14px;
                content: ' ';
                height: 33px;
                width: 2px;
                background-color: #082d60;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

@keyframes blowUpModal {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}
