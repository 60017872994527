﻿.c-news-item {
    @extend %c-rich-text-block;
    text-align: center;
    background-color: $color-light-grey;
    padding-bottom: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;

    @include breakpoint(large) {
        padding-bottom: 50px;
    }
    // Styling for copy based slides.
    .c-news-item__copy {
        padding: 30px;
        display: flex;
        flex-direction: column;

        @include breakpoint(large) {
            padding: 50px 50px 30px;
        }

        @extend %c-rich-text-block;

        h3 {
            @extend %c-heading--regular;
            line-height: $text-line-height-small;
        }

        h2 {
            @extend %c-heading--medium;
        }

        p {
            &:last-child {
                margin-bottom: 0;
            }

            &:first-child {
                margin-top: 0;
            }
        }
    }
    // Using a JS snippet to provide images embedded into slide as a background image to deal with lack of imagefit support for IE.
    .c-news-item__image.u-compat-object-fit {
        background-size: cover;
        background-position: center center;

        img, picture { // hide image if object fit is not supported - opacity to 0 for the link area
            opacity: 0;
        }
    }

    .c-news-item__content {
        overflow: hidden;
        position: relative;
        text-align: center;
        // JS solution negates need for object-fit here.
        img {
            width: 100%;
            height: 100%;
            max-width: 100%;
            object-fit: cover;
        }
    }

    .c-news-item {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .c-news-item__copy {
        flex-grow: 1;
        text-align: center;

        @include breakpoint(large) {
            margin-top: 0;
        }
    }

    .c-news-item__text-wrap {
        margin-bottom: 40px;
        flex-grow: 1;
    }

    .c-button {
        height: 40px;
        margin-bottom: 10px;
    }
}
