﻿html,
body {
    height: 100%;
    width: 100%;
    font-size: 16px;
    -webkit-overflow-scrolling: touch;

    @include breakpoint (medium) {
        position: initial;
    }
}

body {
    font-family: $primary-font !important;
    padding-top: 60px;

    @include breakpoint(msmall) {
        padding-top: 80px;
    }

    &.modal-open {
      overflow: hidden;
    }
}

button {
    &:active {
        outline: 0;
    }
}

.grid-container {
    @extend .grid-container;
    margin-left: 3%;
    margin-right: 3%;

    @include breakpoint(msmall) {
        margin-left: 5%;
        margin-right: 5%;
    }

    @include breakpoint(medium) {
        margin-left: 5% !important;
        margin-right: 5% !important;
        max-width: none !important;
    }

    @include breakpoint(xxlarge) {
        margin-left: auto !important;
        margin-right: auto !important;
        width: $global-width;
    }
}

.grid-container--padding {
    padding-left: 3%;
    padding-right: 3%;

    @include breakpoint(msmall) {
        padding-left: 5%;
        padding-right: 5%;
    }

    @include breakpoint(medium) {
        padding-left: 5%;
        padding-right: 5%;
    }
}

.full-width {
    max-width: 100%;
}

main {
    width: 100%;
    max-width: $global-width;
    margin: 0 auto;
    position: relative;
    display: block;
}