﻿.o-footer {
    .c-social-block__copy {
        display: block;
        vertical-align: middle;
        margin-bottom: 20px;
        @include body-fontsizes;

        @include breakpoint(medium) {
            display: inline-block;
            margin-bottom: 0;
            @include xy-cell(auto);
        }
    }

    .c-social-block__icon-wrapper {
        display: inline-block;
        font-size: 0 !important;
    }

    .c-social__icon {
        height: 50px;
        width: 50px;
        display: inline-block;
        background-color: $color-primary-blue;
        vertical-align: middle;
        margin-right: 20px;
        font-size: 0 !important;
        background-position: center;
        background-repeat: no-repeat;

        &:last-of-type {
            margin-right: 0px;
        }

        &--facebook {
            background-image: $svg-icon-facebook;
        }

        &--youtube {
            background-image: $svg-icon-youtube;
        }

        &--google {
            background-image: $svg-icon-googleplus;
        }

        &--linkedin {
            background-image: $svg-icon-linkedin;
        }

        &--instagram {
            background-image: $svg-icon-instagram;
            border: white 2px solid;
        }
    }
}
