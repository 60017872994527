.o-testimonial-carousel {
    overflow-x: hidden;

    &.grid-container {
        padding-left: 3% !important;
        padding-right: 3% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;

        @include breakpoint(msmall) {
            padding-left: 5% !important;
            padding-right: 5% !important;
        }
    }

    @extend %u-section-separation;

    .c-carousel-slides__slide {
        // Important required to override dynamically added inline style.
        display: flex !important;
        flex-direction: column;
        height: 100%;

        @include breakpoint(xlarge) {
            flex-direction: row;
            height: auto;
        }
        // Mobile requires image to always be at the top of the slide view, so ensure that occurs here.
        &:first-child .c-carousel-slides__content--copy {
            order: 1;
        }

        @include breakpoint(xlarge) {
            // Reset previous mobile order swap here.
            &:first-child .c-carousel-slides__content--copy {
                order: 0;
            }
        }

        > .c-carousel-slides__content {

            @include breakpoint(xlarge) {
                flex: 1 0 50%;
                max-width: 50%;
            }
        }
    }

    .c-carousel-pager {
        position: relative;
        display: block !important;
        width: 100%;
        text-align: center;

        li {
            margin: auto;
        }
    }
}
