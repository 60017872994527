﻿.c-sidebar {
    @include xy-grid;
    background-color: $color-medium-grey;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 3%;
    padding-right: 3%;
    opacity: 1;

    @include breakpoint(msmall) {
        padding-left: 5%;
        padding-right: 5%;
    }

    @include breakpoint(large) {
        background-color: transparent;
        width: 100%;
        position: fixed;
        top: 80px;
        padding-left: 0;
        padding-right: 0;
        max-width: none !important;
        z-index: 9;
        opacity: 0;
        top: 8.5%;
    }

    .c-sidebar__content {
        width: 100%;
        padding: 30px 0;

        &--no-padding {
            padding: 0;
        }

        @include breakpoint(large) {
            width: 22.5%;
            background-color: $color-medium-grey;
            right: 0;
            padding: 0 0 0 0 !important;
            position: absolute;
            overflow: auto;
            max-height: calc(100vh - 85px);
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            align-content: flex-start;
            overflow: hidden;
        }

        @include breakpoint(xxlarge) {
            width: $sidebar-width;
        }
    }

    // coming soon changes
    &--visible {

        @include breakpoint(large) {
            opacity: 1;
            transition: opacity 0.4s ease-out;
            right: 5%;
            z-index: 2;
        }

        @include breakpoint(xxlarge) {
            right: 50%;
            left: 50%;
            width: $global-width;
            transform: translate(-50%, 0);
        }

        @media (max-width: 1023px) {
          top: auto !important;
        }
    }
    &.coming-soon {
      padding: 0;

      .c-sidebar__content {
        background-color: #082d60;

        .c-development-details {
          flex-grow: 1;
          color: #fff;
          text-align: center;
          padding: 13px;

          h3 {
            margin-bottom: 18px;
          }

          .spacer {
            display: block;
          }

          .c-development-details__title {
            font-size: 24px;
            margin-bottom: 12px;
            font-weight: bold;
          }
  
          .c-development-details__subtitle {
            font-size: 12px;
            line-height: 33px;

            &:not(:empty):not(:last-child):after {
              content: ", ";
              margin-right: 2px;
              margin-left: 0px;
            }
          }
        }

        .c-button {
          font-size: 14px;
          height: 39px;
          line-height: 38px;
          max-width: 400px;
          margin: 0 auto 12px auto;

          &.primary-cta--sidebar {
            background: #fff;
            color: #082d60;
          }

          &.secondary-cta--sidebar {
            border: 2px solid #fff;
          }
        }
      }


      .c-development-details__phone-no {
        padding-top: 6px;
        margin-bottom: 0;

        .phone-no-text {
          color: #fff;
          font-size: 18px;

          a,
          a:hover,
          a:focus,
          &:hover {
            color: #fff;
          }
        }

        .c-phone-icon {
          background-image: $svg-phone-icon-solid-white;
          margin: 0 5px 4px 0;
        }
      }

      .c-development-details__directions,
      .c-development-details__tagline,
      .c-development-details__opening-times,
      .c-development-details__opening-info {
        display: none;
      }

      @include breakpoint(large) {
        transition: all 0.3s;
        position: absolute;
        top: 0px;

        .c-sidebar__content {
          height: 44.85vw;

          .c-development-details { 
            text-align: left;
            justify-content: space-between;
            padding: 32px;

            .spacer {
              height: 10px;
            }

            .c-development-details__title {
              font-size: 30px;
            }
    
            .c-development-details__subtitle {
              font-size: 20px;
              display: block;

              &:not(:empty):not(:last-child):after {
                content: ", ";
                margin-right: 2px;
              }
            }
          }

          .c-development-details__phone-no {
            margin-bottom: 30px;
            span {
              font-size: 20px;
            }
      
            .c-phone-icon {
              margin: 0 8px 8px 0;
            }
          }

          .c-button {
            margin: 0 auto 20px auto;
          }
        }
      }

      @include breakpoint(xxlarge) {
        width: $global-width;
        transform: translate(-50%, 0);
        left: 50%;

        .c-sidebar__content {
          height: 717px;

          .c-development-details { 
            padding: 42px;
          }

          .c-development-details__phone-no {
            span {
              font-size: 24px;
            }
          }
        }
      }
    }
  // coming soon changes end
}
