﻿.c-news-section {
    @extend %c-rich-text-block;
    @extend %u-section-separation--border;
    overflow: visible !important;
    .c-layout__column {
        margin: 20px 0;
        @include breakpoint(medium) {
            @include xy-cell(12);
        }

        @include breakpoint(large) {
            @include xy-cell(4);
        }
    }

    h2 {
      @extend %c-heading--large;
      font-weight: $text-weight-light;
      text-align: center;
      @include breakpoint(large){
          margin-bottom: 50px;
      }
    }

    &.no-content {
        margin-bottom: -70px;

        @include breakpoint(medium){
            margin-bottom: -110px;
        }
    }
}
