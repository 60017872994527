.o-tabs-navigation {
    position: relative;
    top: 0;
    left: 0;
    padding: 0;

    @include breakpoint(medium) {
        padding-left: 5%;
        padding-right: 5%;
    }

    @extend %tabs;

    .tabs-container {
        width: 100%;
        display: none;
        border: none;
        text-align: left;

        @include breakpoint(medium) {
            display: block;
            border: none;
            border-bottom: 1px solid $color-dark-grey;
            text-align: center;
        }
    }

    .tabs-title {
        display: block;
        width: 100%;

        @include breakpoint(medium) {
            display: inline-block;
            width: auto;
            float: none;
        }

        &:focus, &:focus a, & a:focus {
            outline: none;
            outline-color:transparent;
        }

        a {
            @extend %u-hyperlink;
            background: $color-medium-grey;
            border-bottom: 1px solid $color-dark-grey;
            height: 60px;
            line-height: normal;
            color: $color-primary-blue;
            text-decoration: none;
            padding-left: 5%;
            padding-right: 5%;
            font-size: $text-size-x-small;
            font-weight: 300;

            @include breakpoint(medium) {
                background: $color-white;
                border-bottom: none;
                font-size: $text-size-xx-small;
                padding: 0 12px;
                line-height: 60px;
                font-weight: 400;
            }

            @include breakpoint(large) {
                background: $color-white;
                border-bottom: none;
                font-size: $text-size-x-small;
                padding: 0 10px;
                line-height: 60px;
                font-weight: 400;
            }
        }

        &.is-active, &:hover {
            a {
                color: $color-primary-blue;
                text-decoration: none;
                background: $color-medium-grey;

                @include breakpoint(medium) {
                    color: $color-purple;
                    text-decoration: none;
                    background: $color-white;
                }
            }
        }
    }

    .tabs-content {
        @include tabs-content;
        border: none;
    }

    .tabs-panel {
        @include tabs-panel;
        padding: 0;
    }

    .tabs-mobile-dropdown, .tabs-mobile-dropdown:visited, .tabs-mobile-dropdown:hover {
        display: block;
        height: 60px;
        border: 1px solid $color-darker-grey;
        font-size: $text-size-x-small;
        color: $color-primary-blue;
        text-align: left;
        font-family: $primary-font;
        line-height: 60px;
        text-decoration: none;
        padding: 0 5%;

        @include breakpoint(medium) {
            display: none;
        }

        &:after {
            content: '';
            background-image: $svg-arrow-down-blue;
            background-repeat: no-repeat;
            background-position: right center;
            margin-right: 15px;
            height: 100%;
            float: right;
            width: 18px;
            transition: transform 0.2s;
        }
    }

    &.open {
        .tabs-container {
            width: 100%;
            display: block;
            position: absolute;
            top: 60px;
            left: 0;
            z-index: 3;

            @include breakpoint(medium) {
                position: static;
            }
        }

        .tabs-mobile-dropdown {
            &:after {
                transform: rotate(180deg);
            }
        }
    }
}
