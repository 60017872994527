.detail-carousel {
  position: relative;
  display: flex;
  flex-direction: column-reverse;

  @include breakpoint(large) {
    flex-direction: column;
  }

  .detail-carousel__banner {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: $global-width;
    overflow: hidden;
    height: 100%;
    display: none;
    pointer-events: none;

    div {
      width: auto;
      padding: 0 100px;
      width: 100%;
    }

    @include breakpoint(large) {
      width: 90%;
      flex-direction: column;
      display: block;

      div  {
        transform: rotate(-45deg) translate(-36%, -22vw);
      }
    }

    @include breakpoint(xxlarge) {
        margin: auto;
        width: $global-width;

        div  {
          transform: rotate(-45deg) translate(-475px, -350px);
        }
    }
  }

  .detail-carousel__viewed-plot-count {
    animation: viewPlotFadeOut 2s ease-out 5s forwards;
    background: $color-primary-blue;
    color: white;
    padding: 10px 20px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;

    @include breakpoint(large) {
      animation: none;
      margin: 0 5%;
    }

    @include breakpoint(xxlarge) {
      margin: 0 auto;
    }
  }


  @keyframes viewPlotFadeOut {
    from {
      opacity: 1;
      display: block;
    }
    to {
      opacity: 0;
      display: none;
    }
  }
}