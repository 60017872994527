﻿.o-layout-2col {
    position: relative;
    overflow: hidden;
    @extend %u-section-separation;

    .c-layout__column {
        display: flex;
        position: relative;
        margin: 10px 0;
        @include xy-cell(12, $gutter-type: none);
        width: 100%;

        @include breakpoint(medium) {
            margin: 0;

            @include xy-cell(6);
            float: left;
        }

        @include target-ie {
            display: block;
        }
    }
            
    &:not(&--thin) {
        @include grid-container-custom;
    }

    &--thin {        
        display: flex;
        flex-wrap: wrap;
        .c-layout__column {
            margin: {
                top: 20px;
                bottom:20px;
            };
            @include breakpoint(medium) {    
                @include xy-cell(12, $gutter-type: none);
                width: 100%;
                margin: {
                    left: 0;
                    right: 0;
                };
            }

            @include breakpoint(large) {
                @include xy-cell(5);

                &:first-child {
                    @include xy-cell-offset(1);
                }
            }
        }
    }
}
