.o-local-area {
  display: flex;
  flex-flow: column;

  .c-button span {
      min-width: 260px;
  }

  div {
    margin: 0 auto;
  }

  .c-local-area__amenity {
    height: 0;
    overflow: hidden;
    transition: opacity 0.5s ease;
    opacity: 0;

    &.reveal {
      height: auto;
      overflow: hidden;
      opacity: 1;
    }

    &--desktop {
      display: none;
    }
    &--mobile {
      display: block;
    }
  }


  &__loading {
    animation: spin 1s linear infinite;
    background-image: $svg-loader;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    height: 100px;
    margin: 50px;
  }

  @include breakpoint(768px) {
    padding: 0 0 10px 0;
  }

  @include breakpoint(large) {
    flex-flow: row-reverse;

    .c-local-area__amenity {

      &--desktop {
        display: block;
      }
      &--mobile {
        display: none;
      }
    }
  }
}

.c-local-area-map-wrap {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.45s ease-in-out;
    width: 100%;

    &--active {
      height: auto;
      opacity: 1;
    }
}

.c-local-area-copy {
    @extend %c-rich-text-block;
    text-align: center;

    h2 {
        @extend %c-heading--large;
        font-weight: $text-weight-light;
    }

    h3 {
        @extend %c-bodytext--regular;
        font-weight: $text-weight-light;
        margin: 0 auto;
        max-width: 200px;
        margin-bottom: 40px;

        @include breakpoint(768px) {
            max-width: 100%;
        }
    }

    &--bottom {
        padding: 0 5%;        
    }

    @include breakpoint(medium) {

        &--bottom {
            padding: 0;
        }
    }
}

.c-local-area-copy--controls {
    margin: 0 auto;
    max-width: 320px;
    
    .c-button {
        padding-bottom: 20px;
    
        @include breakpoint(large) {
            padding-bottom: 0;
        }
    }
}

.title-section {
  h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  h3 {
    margin-bottom: 9px;
  }

  @include breakpoint(large) {
    display: none;
  }
}
