﻿.c-development-homes-map {
    background: #ddd;
    opacity: 0;
    position: relative;
    display: none;
    width: 100%;
    @include aspect-ratio(910, 880);

    @include breakpoint(large) {
        width: 75%;
    }

    @include breakpoint(xxlarge) {
        width: calc(100% - 360px);
    }

    &--active {
        @include breakpoint(medium) {
            display: block;
            opacity: 1;
            height: 650px;
            padding-top: 0;
        }
    }

    &__content {
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
    }
}

.gm-style-iw.development-homes-map__gm {
    top: 15px !important;
    left: 0 !important;
    z-index: -1;
    background-color: $color-white;
    box-shadow: 0 1px 6px rgba(178, 178, 178, 0.6);

    & > div {
        margin-bottom: -3px;
    }

    & > div, & > div > div {
        overflow: hidden !important;
    }

    & + button {
        width: 42px !important;
        height: 42px !important;
        opacity: 1 !important;
        right: 30px !important;
        top: 15px !important;
        background-color: #082d60 !important;
        background-image: $svg-close-icon !important;
        background-repeat: no-repeat !important;
        background-size: 50% !important;
        background-position: center center !important;

        img {
            display: none !important;
        }
    }
}

.pin-info {
    display: flex;
    font-family: "Lato", serif;
    font-size: 16px;
    color: $color-primary-blue;
    width: 437px;

    &__left {
        width: 35%;
        min-width: 100px;
        position: relative;

        @include breakpoint(medium) {
            width: 50%;
        }
    }

    &__right {
        width: 65%;
        padding: 0 15px;
        font-weight: 300;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include breakpoint(medium) {
            width: 50%;
        }
    }

    &__name {
        font-size: .970rem;
        font-weight: 400;
        margin-bottom: 2px;
    }

    &__distance {
        color: $color-blue-dust;
        font-weight: 400;
        margin-bottom: 11px;
    }

    &__address {
        font-size: .850rem;
        line-height: 1.25rem;
    }

    &__tagline {
        font-size: .850rem;
        line-height: 1.25rem;

        span {
            font-weight: 400;
        }
    }


    &__link {
        background-color: $color-primary-blue;
        font-family: "Lato", serif;
        color: $color-white;
        height: 40px;
        width: 100%;
        line-height: 20px;
        text-align: center;
        display: block;
        font-size: .875rem;
        text-decoration: none;
        padding: 0 10px;
        position: absolute;
        bottom: 0;
        left: 0;

        @include breakpoint(medium) {
            font-size: .95rem;
            height: 50px;
            line-height: 50px;
        }
    }
}

.pin-info-image-box {
    width: 100%;
    overflow: hidden;
    height: 175px;
    position: relative;

    &__image {
        position: absolute;
        display: block;
        height: 100%;
        top: 0;
        left: 0;
        width: auto;
        max-width: none;
        margin-left: 50%;
        transform: translateX(-50%);
    }
}
